<template>

<head>

    <meta charset="utf-8" />
    <title>Desk Birdy | Parking Quick Stats</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
        type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->

    <!-- owl.carousel css -->
    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

</head>

<body data-sidebar="dark">

    <!-- Begin page -->
    <div id="layout-wrapper">


        <NavigationBar/>





        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
         <div id="content" class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <div class="row">

                        <div class="col-lg-12 px-sm-30px">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-sm-12 align-self-center text-capitalize">
                                            <div class="text-lg-left mt-4 mt-lg-0">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-3 col-lg-3 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span
                                                                        class="avatar-title rounded-circle bg-warning font-size-20">
                                                                    <i class="mdi mdi-car-multiple text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">Saccos's/Sub Groups</p>
                                                            <h5 class="mb-0">{{saccoName}}</h5>

                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-3 col-lg-3 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span
                                                                        class="avatar-title rounded-circle bg-info font-size-20">
                                                                    <i
                                                                            class="mdi-file-document-edit-outline mdi text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2 text-capitalize">
                                                                Registered Vehicles</p>
                                                            <h5 class="mb-0">{{registeredVehicles}}</h5>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-3 col-lg-3 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span class="avatar-title rounded-circle font-size-20">
                                                                    <i class="bx bxs-ship text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">Compliant Vehicles</p>
                                                            <h5 class="mb-0">{{compliantVehicles}}</h5>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-md-3 col-lg-3 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span
                                                                        class="avatar-title rounded-circle bg-danger font-size-20">
                                                                    <i class="mdi mdi-car-off text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">Uncompliant Vehicles
                                                            </p>
                                                            <h5 class="mb-0">{{uncompliantVehicles}}</h5>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <!-- end row -->
                                </div>
                            </div>
                        </div>

                        <div class="col-12 px-sm-30px">
                            <div class="card">
                                <div class="card-header bg-white border-bottom d-flex justify-content-between">
                                    <div>
                                        <h4 class="card-title">Select Vehicles To Be Included in the Invoice</h4>
                                    </div>
                                    <div class="d-none">
                                        <button type="button" class="btn btn-primary waves-effect waves-light">
                                            <i class="mdi mdi-upload font-size-16 align-middle me-2"></i> Upload Excel Sheet
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body d-flex justify-content-between align-items-center">
                                    <div class=" d-flex">
                                        <div class="alert alert-success" role="alert">
                                            <p class="mb-0 pb-0">Selected Vehicles</p>
                                            <h4 class="alert-heading">{{selectedVehicles.length}} Vehicles</h4>
                                        </div>



                                        <div class="alert alert-success mx-3" role="alert">
                                            <p class="mb-0 pb-0">Accumulated Penalty</p>
                                            <h4 class="alert-heading">KES 0.00</h4>
                                        </div>

                                        <div class="alert alert-success" role="alert">
                                            <p class="mb-0 pb-0">Seasonal Parking Value</p>
                                            <h4 class="alert-heading">KES {{totalAmount}}</h4>
                                        </div>

                                    </div>
                                    <div class="text-right">
                                        <button v-if="getRight('CREATE SACCO INVOICE')" @click="generateBill()" type="button" class="btn btn-primary waves-effect btn-label waves-light" data-toggle="modal" data-target="#payment-modal"><i class="bx bx-smile label-icon"></i> Create Invoice</button>
                                    </div>
                                </div>

                               <!-- <div> {{billItem}}</div><br>
                                <div> {{feesAndChargeItems}}</div><br>
                                <div> {{selectedVehicles}}</div><br>-->

                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table" id="datatable-buttons">
                                            <thead class="table-light">
                                                <tr>

                                                    <th scope="col" style="width: 70px;">
                                                        <div class="the-mail-checkbox pr-4">
                                                            <label for="selectAll" class="d-none">Select All</label>
                                                            <input v-model="selectAll" class="form-check-input mt-0 pt-0 form-check-dark" type="checkbox" id="selectAll">
                                                        </div>
                                                    </th>
                                                    <th scope="col">Vehicle</th>
                                                    <th scope="col">Phone</th>
                                                    <th scope="col" class="text-right">Category</th>
                                                    <th scope="col" class="text-right">Parking Fee</th>
                                                    <th scope="col" class="text-right">Sacco Name</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                <tr v-for="(item,index) in vehicles" :key="index">

                                                    <td>
                                                        <div class="d-flex  align-items-center">
                                                            <div class="the-mail-checkbox pr-4">
                                                                <input v-model="item.id" @change="item.id ? selectVehicle(item) : removeVehicle(item)" class="form-check-input mt-0 pt-0 form-check-dark" type="checkbox" id="formCheck1">
                                                            </div>

                                                        </div>
                                                    </td>
                                                    <td class="text-uppercase">
                                                        {{item.numberPlate}}
                                                    </td>
                                                    <td>
                                                        {{item.phoneNumber}}
                                                    </td>
                                                    <td class="text-right text-uppercase">{{item.category}}</td>
                                                    <td class="text-right text-uppercase">KES {{item.amount}} (x {{item.quantity}})</td>
                                                    <td class="text-right text-uppercase">{{item.saccoName}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->

            <!-- payment modal -->
            <div class="modal fade" id="payment-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header d-none">
                            <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                            <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
                            </button>

                        </div>
                        <div class="modal-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                                <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal" data-dismiss="modal" aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
                                </button>
                            </div>

                            <div class="payment-panel-parent">

                                <div v-if="paymentPanel" class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
                                    <div class="success-image mb-4 pb-4 pt-4">
                                        <img src="assets/images/bills.svg" height="200" alt="">
                                    </div>
                                    <h4 class="text-black fw-bold">{{message}}</h4>
                                    <p class="text-muted mb-4">The bill has been created Successfully, you can print the bill or opt to receive payment for this bill.</p>
                                    <div class="d-flex w-100 pb-2">
                                        <button @click="showPaymentMethod()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                                            <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                                            Receive Payment
                                        </button>
                                        <a @click="printBill()" target="_blank" data-dismiss="" type="button" class="btn btn-outline-secondary waves-effect dismin-modal">Print Bill</a>
                                    </div>
                                </div>

                                <div v-if="paymentMethod" class="send-method payment-panel ">
                                    <label for="" class="mb-0 pb-0">Payment Method</label>
                                    <p><small class="text-muted">How would you like to send this money?</small></p>

                                    <div>
                                        <div class="accordion" id="accordionExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button fw-medium" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <div class="flex-shrink-0 me-3">
                                                            <img class="rounded-circle" src="assets/images/users/mpesa.jpg" alt="Mobile Money" height="65">
                                                        </div>
                                                        <div class="d-flex flex-column">
                                                            <p class="m-0 p-0 text-uppercase fw-semibold">MPESA</p>
                                                            <p class="mb-0 p-0"> <small>A payment request will be sent directly to the customer</small></p>
                                                        </div>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <div>
                                                            <p>
                                                                A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to the MPESA number you enter below.
                                                            </p>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="">MPESA Number</label>
                                                            <div class="form-floating mb-3">
                                                                <input v-model="phoneNumber" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                                                <label for="floatingnameInput">Phone No.</label>
                                                            </div>

                                                            <button @click="showTransactionSummary('MPESA')" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                                                                <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                                                                Send Payment Request
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button fw-medium collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <div class="flex-shrink-0 me-3">
                                                            <img class="rounded-circle" src="assets/images/users/money.jpg" alt="Mobile Money" height="65">
                                                        </div>
                                                        <div class="d-flex flex-column">
                                                            <p class="m-0 p-0 text-uppercase fw-semibold">bank Receipting</p>
                                                            <p class="mb-0 p-0"> <small>Enter the receipt number here to confirm payment for this transaction.</small></p>

                                                        </div>
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <div class="form-group">
                                                            <label for="">Enter The Bank's Receipt Number</label>
                                                            <div class="form-floating mb-3">
                                                                <input v-model="paymentData.bankReceiptNo" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                                                <label for="floatingnameInput">Bank Ref No.</label>
                                                            </div>

                                                            <button @click="showTransactionSummary('BANK')" class="btn w-100 btn-warning mt-3 btn-bank">
                                                                Continue
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div v-if="transactionSummary" class="transaction-summary payment-panel">
                                    <label for="">Transaction Breakdown</label>
                                    <div class="border p-4 rounded ">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="text-muted ">
                                                    BillO NO.
                                                </div>
                                            </div>

                                            <div class="col-lg-6 align-self-end">
                                                <div class=" text-right text-black">
                                                    {{billDetails.billNo}}
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <div class="text-muted mt-2">
                                                    Bill For
                                                </div>
                                            </div>

                                            <div class="col-lg-6 align-self-end">
                                                <div class=" text-right text-black">
                                                    {{billDetails.incomeTypeDescription}}
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <div class="text-muted mt-2">
                                                    Payment Method
                                                </div>
                                            </div>

                                            <div class="col-lg-6 align-self-end">
                                                <div class=" text-right text-black">
                                                    {{paymentData.paymentMode}}
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <div class="text-muted mt-2">
                                                    Transaction Amount
                                                </div>
                                            </div>

                                            <div class="col-lg-6 align-self-end">
                                                <div class=" text-right text-black">
                                                    KES {{billDetails.billBalance}}
                                                </div>
                                            </div>


                                            <div class="col-12 text-black"><hr class="mb-0 pb-0"></div>

                                            <div class="col-lg-6 text-uppercase">
                                                <div class="text-muted mt-2 font-21">
                                                    Total
                                                </div>
                                            </div>

                                            <div class="col-lg-6 align-self-end text-uppercase">
                                                <div class=" text-right text-black font-21 fw-bold">
                                                    KES {{billDetails.billBalance}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mpesa-confirmation ">
                                            <div v-if="paymentData.paymentMode=='MPESA'">
                                                <p class="text-muted mt-2">A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to <strong class="text-black"> phone number ({{ phoneNumber }})</strong> soon after you click the <strong class="text-black text-capitalize">Send Request</strong> button bellow.
                                                    <br>
                                                    <br>
                                                    Remember to <strong class="text-black">Check your phone</strong> to confirm payment by entering your Mpesa pin.</p>
                                            </div>

                                            <div>{{message}}  </div>
                                            <br>
                                            <button @click="makePayment()" class="btn btn-primary btn-flex flex-grow-1 waves-effect btn-send waves-light text-center w-100">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <div class="stk-timer-container d-none justify-content-center align-items-center">
                                                        <span class="mdi mdi-timer-outline font-16px"></span>
                                                        <span class="stk-timer px-2"></span>
                                                    </div>
                                                    <div class="justify-content-center align-items-center d-flex">
                                                        <span class="px-2">Send Request</span>
                                                        <div class="flip-x"><i class="mdi mdi-reply ms-3 font-16px"></i></div>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>


                                    </div>
                                </div>
                                <div v-if="transactionCompleted" class="text-center d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
                                    <div class="success-image mb-4">
                                        <img src="assets/images/payment-confirmation-images/sent.svg" height="200" alt="">
                                    </div>
                                    <h4 class="text-blink-primary fw-bold">Transaction Complete</h4>
                                    <p class="text-muted mb-4"><strong class="text-black">KES {{receiptDetails.receiptAmount}}</strong> has been received for <strong class="text-black">Bill No. {{receiptDetails.billNo}}</strong></p>
                                    <button @click="printReceipt()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                                        <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                                        Print Receipt
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer d-flex d-none bill-modal-footer">
                            <button href="javascript: void(0);" disabled class="btn btn-outline-light waves-effect waves-light payment-prev"> <i class="mdi mdi-arrow-left ms-1"></i>Previouse</button>
                            <button href="javascript: void(0);" class="btn btn-primary waves-effect waves-light payment-next">Next <i class="mdi mdi-arrow-right ms-1"></i></button>
                            <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center d-none">
                                <div class="d-flex justify-content-center align-items-center"> <span>Send Money</span> <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div></div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end of payment modal -->



            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Invoice Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12">
                                <address>
                                    <strong>Billed To:</strong><br>
                                    Kelvin Njuguna<br>
                                    email@mail.com, 0704 549 859<br>
                                    Hse No. 410, 90 Degrees By Tsavo
                                    <br><br>
                                    1 Mar 2022, 10:20 AM
                                </address>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Invoice Details ( <span
                                            class="text-primary fw-medium">No. 1237</span> )</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead>
                                            <tr>
                                                <th style="width: 70px;">No.</th>
                                                <th>Item</th>
                                                <th class="text-end">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>Rent Deposit</td>
                                                <td class="text-end">KES 24,500</td>
                                            </tr>

                                            <tr>
                                                <td>02</td>
                                                <td>Monthly Rent</td>
                                                <td class="text-end">KES 12,000</td>
                                            </tr>

                                            <tr>
                                                <td>03</td>
                                                <td>TAX (18%)</td>
                                                <td class="text-end">KES 250</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Total</td>
                                                <td class="text-end fw-bold">KES 36,750</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Paid</td>
                                                <td class="text-end  fw-bold">KES 0.00</td>
                                            </tr>

                                            <tr>
                                                <td colspan="2" class="border-0 text-end">
                                                    <strong>Balance</strong>
                                                </td>
                                                <td class="border-0 text-end">
                                                    <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                            <tr data-id="1">
                                                <td>
                                                    <label for="" class="">Payment Method</label>
                                                    <select class="form-control selectpicker w-100 payment-method"
                                                        data-style="btn-secondary w-100" data-live-search="true"
                                                        title="Select payment Method">
                                                        <option value="Mpesa">MPESA</option>
                                                        <option value="Cash">CASH</option>
                                                    </select>
                                                </td>
                                                <td class="px-3 ">
                                                    <div class="phone-num d-none">
                                                        <label for="">Phone No.</label>
                                                        <input type="text " class="form-control w-100 d-flex "
                                                            placeholder="Phone No." spellcheck="false"
                                                            data-ms-editor="true">
                                                    </div>

                                                </td>
                                                <td class="px-3">
                                                    <label for="">Amount To Be Paid</label>
                                                    <input type="text " class="form-control w-100 d-flex"
                                                        placeholder="KES" spellcheck="false" data-ms-editor="true">

                                                </td>
                                                <td class="text-right float-right">
                                                    <div class="d-flex flex-column">
                                                        <label class="opacity-0">Something</label>
                                                        <a href="#"
                                                            class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                                                    </div>
                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a href="javascript:window.print()"
                                    class="btn btn-success waves-effect waves-light me-1"><i
                                        class="mdi mdi-printer font-16px"></i></a>
                                <a href="javascript: void(0);"
                                    class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">

    </div>
    <div class="col-sm-6 ">

    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>


</body>
</template>

<script>
    import NavigationBar from "@/components/Navigation";
    import {biller, execute, getRight, parking} from "@/api";
    import router from "@/router";
    export default {
        name: "ParkingFleetPayment",
        components: {NavigationBar},
        data() {
            return{
                uncompliantVehicles:0,
                compliantVehicles:0,
                registeredVehicles:0,
                sacco: {
                    id: null,
                    saccoID: null,
                    saccoName: null,
                    physicalAddress: null,
                    fullNames: null,
                    role:null,
                    phoneNumber: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardID: null,
                    wardName: null
                },
                totalAmount:0,
                feesAndChargeItems:[{
                    feeId: null,
                    feeDescription: null,
                    unitOfMeasure: null,
                    unitFeeAmount: null,
                    accountNo: null,
                    incomeTypeId: null,
                    feeType: null,
                    accountDesc:null ,
                    costCenterNo:null,
                    zone: null,
                    typeDescription: null,
                    customer: null
                }],
                billItem:[],
                billDetails:{
                    id:'',
                    billNo:'',
                    incomeTypeID:'',
                    incomeTypeDescription:'',
                    costCenterNo:'',
                    accountNo:'',
                    feeID:'',
                    detailAmount:'',
                    receiptAmount:'',
                    billBalance:'',
                    wardID:'',
                    subCountyID:'',
                    status:'',
                    dateCreated:''
                },
                billInfo:[],
                paymentData: {
                    bankReceiptNo: null,
                    accNo: null,
                    payBillNo: null,
                    amount: null,
                    phoneNumber: null,
                    success: false,
                    paymentMode:null,
                  sessionNames:null,
                  sessionIdNo:null,
                  sessionPhoneNumber:null
                },
                alert:{
                    success: false,
                    info: false,
                    failed:false
                },
                vehicles:[
                    {
                        id: null,
                        saccoID: null,
                        saccoName: null,
                        numberPlate: null,
                        category: null,
                        owner: null,
                        penalty: null,
                        phoneNumber: null,
                        feeID: null,
                        amount: null,
                        quantity: null
                    }
                ],
                selectedVehicles:[
                    {
                        id: null,
                        saccoID: null,
                        saccoName: null,
                        numberPlate: null,
                        category: null,
                        owner: null,
                        penalty: null,
                        phoneNumber: null,
                        feeID: null,
                        amount: null,
                        quantity: null
                    }
                ],
                message:null,
                selectAll: false,
                transactionCompleted: false,
                transactionSummary: false,
                paymentPanel: true,
                paymentMethod: false,

            }
        },
        watch: {
            selectAll(newValue) {
                if (newValue) {
                    this.selectAllVehicles();
                }else {
                    this.removeAllVehicles();
                }
                }
                },

        mounted() {
            this.saccoName =  sessionStorage.getItem("saccoName")
            this.registeredVehicles = sessionStorage.getItem("total")
            this.compliantVehicles = sessionStorage.getItem("compliant")
            this.uncompliantVehicles =  sessionStorage.getItem("unCompliant")

            this.getSaccoVehicles()
        },

        methods: {
            selectAllVehicles() {
                this.totalAmount =0
                this.selectedVehicles.splice(0)
                this.billItem.splice(0)
            // Loop through the vehicles array
                for (let item of this.vehicles) {
           // Assign the item's id to the item's saccoID
                    item.id = item.saccoID;
          // Push the item to the selectedVehicles array
                    this.selectedVehicles.push(item);
                }

                this.feesAndChargeItems.splice(0)
                this.generateFeeAndChargesItem()
            },
            removeAllVehicles() {
                this.totalAmount =0
                this.feesAndChargeItems.splice(0)
                this.billItem.splice(0)
          // Loop through the vehicles array
                for (let item of this.vehicles) {
           // Assign the item's id to null
                    item.id = null;
           // Find the index of the item in the selectedVehicles array
                    let index = this.selectedVehicles.findIndex(
                        (vehicle) => vehicle.id === item.id
                    );
            // If the index is found, splice the item from the selectedVehicles array
                    if (index !== -1) {
                        this.selectedVehicles.splice(index, 1);
                    }
                }
                this.generateFeeAndChargesItem()
            },
            selectVehicle(vehicle){
                this.totalAmount =0
                this.feesAndChargeItems.splice(0)
                this.billItem.splice(0)
                this.selectedVehicles.push(vehicle)
                this.generateFeeAndChargesItem()
            },
            removeVehicle(item) {
                this.totalAmount =0
                this.feesAndChargeItems.splice(0)
                this.billItem.splice(0)
              // Find the index of the item in the selectedVehicles array
                let index = this.selectedVehicles.findIndex(
                    (vehicle) => vehicle.id === item.id
                );
                 // If the index is found, splice the item from the selectedVehicles array
                if (index !== -1) {
                    this.selectedVehicles.splice(index, 1);
                }
                this.generateFeeAndChargesItem()
            },
            getRight(type){
                return getRight(type)
            },
            generateFeeAndChargesItem() {
           // Loop through the selectedVehicles array
                for (let item of this.selectedVehicles) {
             // Create a new object with the mapped values.
                    let newItem = {
                        feeId: item.feeID, // Map feeID to feeId
                        unitFeeAmount: item.amount, // Map amount to unitFeeAmount
                        customer: item.numberPlate, // Map numberPlate to customer
                        quantity: item.quantity
                    };
                  // Push the new object to the feesAndChargeItems array
                    this.feesAndChargeItems.push(newItem);
                }
                this.addToBill()
            },
            addToBill(){
                for (const { typeDescription,feeId, feeDescription, unitFeeAmount, accountNo, costCenterNo, incomeTypeId,customer,quantity} of this.feesAndChargeItems) {
                    this.totalAmount += parseFloat(unitFeeAmount);
                    this.billItem.push({
                        typeDescription,
                        feeDescription,
                        unitFeeAmount: unitFeeAmount,
                        accountNo,
                        costCenterNo,
                        incomeTypeId,
                        revenueStreamItem: this.sacco.saccoName,
                        feeId,
                        amount:  unitFeeAmount,
                        customer: customer,
                        zone: sessionStorage.getItem("zone"),
                        subCountyID:this.sacco.subCountyID,
                        subCountyName:this.sacco.subCountyName,
                        wardID: this.sacco.wardID,
                        wardName: this.sacco.wardName,
                        idNo: sessionStorage.getItem("sessionIdNo"),
                        phoneNumber: sessionStorage.getItem("sessionPhoneNumber"),
                        names: sessionStorage.getItem("sessionNames"),
                        customerPhoneNumber: this.sacco.phoneNumber,
                        description: this.sacco.saccoName,
                        quantity: quantity
                    });
                }
            },

            generateBill(){

                this.paymentMethod = false;
                this.paymentPanel = true;
                this.transactionSummary = false ;
                this.transactionCompleted = false
                this.message ="Generating Bill...please wait... "
                const data = new FormData();
                data.append("function", "generateBill3");
                data.append("billItem", JSON.stringify(this.billItem));
                execute(data,biller)
                    .then((res) =>{
                        this.message =res.data.message
                        if (res.data.success) {
                            this.billDetails = res.data.data.bill.billDetails
                            this.billInfo = res.data.data.bill.billInfo
                            this.paymentData.amount = res.data.data.bill.billDetails.billBalance
                            this.paymentData.accNo = res.data.data.bill.billDetails.billNo
                            this.paymentData.payBillNo = res.data.data.billGenerated.payBillNo

                           this.paymentData.sessionNames =sessionStorage.getItem("sessionNames")
                           this.paymentData.sessionIdNo =sessionStorage.getItem("sessionIdNo")
                           this.paymentData.sessionPhoneNumber = sessionStorage.getItem("sessionPhoneNumber")



                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            printBill() {
                localStorage['params'] = JSON.stringify({
                    billNo: this.billDetails.billNo
                })
                const routeData = this.$router.resolve({name: 'bill'});
                window.open(routeData.href, '_blank');
            },
            printReceipt() {
                if (this.receiptDetails.receiptNo == null) {
                    alert("Try again later");
                } else {
                    //  this.$refs.closeReceived.click();
                    localStorage['params'] = JSON.stringify({
                        receiptNo: this.receiptDetails.receiptNo
                    })
                    const routeData = this.$router.resolve({name: 'receipt'});
                    window.open(routeData.href, '_blank');
                }
            },
            showPaymentMethod(){
                this.paymentMethod = true;
                this.paymentPanel = false;
                this.transactionSummary = false;
                this.transactionCompleted = false
            },
            showTransactionSummary(paymentMode){

                this.paymentData.paymentMode = paymentMode

                if(paymentMode === "MPESA") {
                    if (this.phoneNumber == null) {
                        alert("Enter phone number");
                        return;
                    }
                }
                if(paymentMode === "BANK") {
                    if (this.paymentData.bankReceiptNo == null) {
                        alert("Enter Bank Receipt No");
                        return;
                    }
                }

                this.paymentMethod = false;
                this.paymentPanel = false;
                this.transactionSummary = true;
                this.transactionCompleted = false
            },
            makePayment() {
                if(this.paymentData.paymentMode === "MPESA") {
                    if (this.phoneNumber == null) {
                        alert("Enter phone number");
                        return;
                    }
                }
                if(this.paymentData.paymentMode === "BANK") {
                    if (this.paymentData.bankReceiptNo == null) {
                        alert("Enter Bank Receipt No");
                        return;
                    }
                }
                this.message = "Payment Request sent";
                this.paymentData.phoneNumber = this.phoneNumber

                const data = new FormData();
                data.append("function", "makePayment");
                data.append("paymentData", JSON.stringify(this.paymentData));


                execute(data, biller)
                    .then((res) => {
                        if (res.data.success) {
                            this.paymentMethod = false;
                            this.paymentPanel = false;
                            //start checking payment
                            this.timer = setInterval(() => {

                                this.checkPayment();
                            }, 3000);
                        } else {
                            this.rate = null;
                            alert(res.data.message);
                        }
                    })
                    .catch((e) => {
                        alert(e.message);
                    });
            },
            checkPayment() {
                this.randomNumber = Math.floor(Math.random() * 3) + 1
                if(this.randomNumber === 1){
                    this.message = "Waiting for payment.";
                }
                if(this.randomNumber === 2){
                    this.message = "Waiting for payment..";
                }
                if(this.randomNumber ===3){
                    this.message = "Waiting for payment...";
                }
                const data = new FormData();
                data.append("function", "checkPayment");
                data.append("accountRef", this.paymentData.accNo);

                execute(data, biller)
                    .then((res) => {
                        if (res.data.success) {
                            this.message = null;
                            clearInterval(this.timer);
                            this.receipt = true;
                            this.paymentData.success = false;
                            this.transaction = res.data.data.transaction;
                            this.receiptDetails = res.data.data.receipt.receiptDetails
                            //Close the modal
                            this.transactionSummary = false
                            this.transactionCompleted = true
                        }
                    })
                    .catch((e) => {
                        alert(e.message);
                    });
            },
            getSaccoVehicles(){
                this.feesAndChargeItems.splice(0)
                this.selectedVehicles.splice(0)
                const data = new FormData();
                data.append("function", "getSaccoVehicles");
                data.append("saccoID",  sessionStorage.getItem("saccoID"));
                execute(data,parking)
                    .then((res) =>{
                        if (res.data.success) {
                            this.sacco = res.data.data.sacco;
                            this.vehicles =res.data.data.vehicles
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            formatPrice(value) {
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'KES',
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            },
            gotTo(route){
                router.push(route)
            },
        }
    }
</script>

<style scoped>

</style>