<template>

  <head>

    <meta charset="utf-8" />
    <title>Hotline Transporters Sacco | All invoices</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

  </head>

  <body data-sidebar="dark">

  <!-- Begin page -->
  <div id="layout-wrapper">


    <NavigationBar/>




    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div  class="content-minimize">

      <div class="page-content">
        <div class="container-fluid">

          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">Housing and Stall rentals</h4>

                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                    <li class="breadcrumb-item"><a href="housing-quick-stat.html">Housing</a></li>
                    <li class="breadcrumb-item"><a href="#">Rentals Register</a></li>
                  </ol>
                </div>

              </div>
            </div>
          </div>
          <!-- end page title -->

          <!-- tool bar -->
          <ToolBarHousing/>
          <!-- end page title -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header bg-white  w-100 border-bottom d-flex justify-content-between align-items-center">
                  <h4 class="card-title text-uppercase">
                    Rents Register
                  </h4>
                  <div class="d-flex">

                    <a @click="gotTo('housing-new')" href="javascript: void(0);" type="button" class="btn btn-success text-uppercase dropdown-toggle option-selector me-3">
                      <i class="bx bx-plus font-size-16"></i> <span class="pl-1 d-md-inline">Add Property</span>
                    </a>

                    <button data-bs-toggle="modal" data-bs-target=".add-block-modal" type="button" class="btn btn-secondary text-uppercase dropdown-toggle option-selector me-3">
                      <i class="bx bx-list-plus font-size-16"></i> <span class="pl-1 d-md-inline">Add Unit Category</span>
                    </button>



                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-3 col-sm-6">
                      <div class="card shadow-none border">
                        <div class="card-body p-3">
                          <div class="">

                            <div class="avatar-xs me-3 mb-3">
                              <div class="avatar-title bg-transparent rounded">
                                <i class="mdi mdi-cash-remove font-size-24 text-danger"></i>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div class="overflow-hidden me-auto">
                                <h5 class="font-size-14 text-truncate mb-1"><a href="javascript: void(0);" class="text-body">Outstanding Arrears</a></h5>
                                <p class="text-muted text-truncate mb-0">{{numberFormat(totalArreas)}}</p>
                              </div>
                              <div class="align-self-end ms-2">
                                <p class="text-muted mb-0">{{numberFormat(totalUnit)}} UNITS</p>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="col-xl-3 col-sm-6">
                      <div class="card shadow-none border">
                        <div class="card-body p-3">
                          <div class="">

                            <div class="avatar-xs me-3 mb-3">
                              <div class="avatar-title bg-transparent rounded">
                                <i class="mdi mdi-account-cash-outline font-size-24 text-success"></i>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div class="overflow-hidden me-auto">
                                <h5 class="font-size-14 text-truncate mb-1"><a href="javascript: void(0);" class="text-body">This Month's Collections</a></h5>
                                <p class="text-muted text-truncate mb-0">{{numberFormat(totalReceiptAmount)}}</p>
                              </div>
                              <div class="align-self-end ms-2">
                                <p class="text-muted mb-0">{{numberFormat(totalRecords)}} UNITS</p>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="col-xl-3 col-sm-6">
                      <div class="card shadow-none border">
                        <div class="card-body p-3">
                          <div class="">

                            <div class="avatar-xs me-3 mb-3">
                              <div class="avatar-title bg-transparent rounded">
                                <i class="mdi mdi-home-group font-size-24 text-dark"></i>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div class="overflow-hidden me-auto">
                                <h5 class="font-size-14 text-truncate mb-1"><a href="javascript: void(0);" class="text-body">Number Of Units</a></h5>
                                <p class="text-muted text-truncate mb-0">{{numberFormat(totalUnit)}} Units</p>
                              </div>
                              <div class="align-self-end ms-2 d-none">
                                <p class="text-muted mb-0">34 UNITS</p>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="col-xl-3 col-sm-6">
                      <div class="card shadow-none border">
                        <div class="card-body p-3">
                          <div class="">

                            <div class="avatar-xs me-3 mb-3">
                              <div class="avatar-title bg-transparent rounded">
                                <i class="mdi mdi-home-export-outline font-size-24 text-warning"></i>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div class="overflow-hidden me-auto">
                                <h5 class="font-size-14 text-truncate mb-1"><a href="javascript: void(0);" class="text-body">Vacant Units</a></h5>
                                <p class="text-muted text-truncate mb-0">{{numberFormat(totalVaccant)}} Units</p>
                              </div>
                              <div class="align-self-end ms-2 d-none">
                                <p class="text-muted mb-0">34 UNITS</p>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>


                  </div>
                </div>
                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                  <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                    <div class="flex-grow-1">
                      <div class="search-box mb-0 me-3">
                        <div class="position-relative">
                          <input v-model="search" type="text" class="form-control bg-light border-light rounded" placeholder="Search..." fdprocessedid="husj3l">
                          <i class="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex">
<!--                      <button  data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                        <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                      </button>   -->

                      <button  @click="downloadProperties()" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                        <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">{{download}}</span>
                      </button>

                    </div>


                  </div>
                  <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                    <button type="button"  data-bs-toggle="modal" data-bs-target=".download-modal" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="table-responsive overflow-visible">

                    <table class="table align-middle table-hover  contacts-table table-striped " id="">
                      <thead class="table-light">
                      <tr class="table-dark">
                        <th>#</th>
                        <th scope="col">Market/Estate</th>
                        <th>No. Of Rentals</th>
                        <th>Occupied</th>
                        <th>Vacant</th>
                        <th scope="col" class="text-right">Arrears</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr :key="index" :value="item" v-for="(item, index) in properties">
                        <td class="text-capitalize text-nowrap"><strong>{{index+1}}.</strong></td>
                        <td class="text-capitalize text-nowrap">
                          <a @click="gotTo('housing-detail',item.propertyID)" href="javascript: void(0);">{{item.property}}</a>
                        </td>
                        <td class="text-capitalize text-nowrap">{{numberFormat(item.noOfUnits)}}</td>
                        <td class="text-capitalize text-nowrap">{{numberFormat(item.noOfOccupiedUnits)}}</td>
                        <td class="text-capitalize text-nowrap">{{numberFormat(item.noOfEmptyUnits)}}</td>
                        <td class="text-capitalize text-nowrap text-right">{{numberFormat(item.totalArrears)}}</td>
                        <td>
                          <div class="d-flex justify-content-end">
                            <a @click="gotTo('housing-detail',item.propertyID)" type="button" href="javascript: void(0);" class="btn btn-primary btn-sm waves-effect waves-light text-nowrap me-3">View Details</a>
                            <div class="dropdown">
                              <div class="dropdown-menu dropdown-menu-end ">
                                <a class="dropdown-item " data-bs-toggle="modal" data-bs-target=".receipting-modal" href="# "><i class="font-size-15 mdi mdi-eye me-3 "></i>View</a>
                                <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>
                                <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-email me-3 "></i>Send Email Reminder</a>
                                <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat me-3 "></i>Send SMS Reminder</a>

                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                      <tfoot class="table-dark">
                      <tr>

                        <th colspan="2">
                          {{numberFormat(properties.length)}}
                        </th>
                        <th>
                          {{numberFormat(totalUnit)}}
                        </th>
                        <th>{{numberFormat(totalOccupied)}}</th>
                        <th>
                          {{numberFormat(totalVaccant)}}
                        </th>

                        <td class="text-nowrap text-right " colspan="">
                          <span class="fw-semibold ">{{numberFormat(totalArreas)}}</span>
                        </td>
                        <td></td>

                      </tr>

                      </tfoot>
                    </table>

                    <div v-if="loading" class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center">
                      <div class="p-5 py-0 pt-3">
                        <div class="p-5">
                          <div class="spinner-border text-info m-1" role="status">
                            <span class="sr-only text-uppercase">Loading...</span>
                          </div>
                        </div>
                      </div>
                      <h4 class="text-uppercase">Loading Data</h4>
                      <div class="col-sm-12 col-md-8 col-lg-5">
                        <p>We are fetching the data for you. It will be ready soon. Please wait and have fun. Thank you for your interest.</p>

                      </div>
                    </div>

                    <div v-if="properties.length<1 & loading === false" class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center">
                      <div class="p-5 py-0 pt-3">
                        <img :src="'./assets/images/no-results.png'" class="img mb-4"  alt="No search results"/>
                      </div>
                      <h4>No Results To Show</h4>
                      <p>Sorry, we could not find any results that match your search or filter criteria. Please try using different keywords or filters.</p>
                    </div>

                  </div>

                </div>

                <div class="card-footer bg-white">
                  <nav aria-label="Page navigation example "
                       class="d-flex align-items-center justify-content-between">

                    <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                      <select class="form-select w-auto font-13px " v-model="pageSize">
                        <option value="10">10 Rows</option>
                        <option value="25">25 Rows</option>
                        <option value="50">50 Rows</option>
                        <option value="100">100 Rows</option>
                        <option value="150">150 Rows</option>
                        <option value="200">200 Rows</option>
                        <option value="5000">5000 Rows</option>
                        <option value="10000">10000 Rows</option>
                        <option value="100000000000">All Rows</option>
                      </select>
                    </div>

                    <ul class="pagination">
                      <!-- add a class of disabled and an attribute of disabled when the current page is the first page -->
                      <li class="page-item" :class="{ disabled: currentPage === 1 }">
                        <a class="page-link" tabindex="-1" @click="prevPage" :disabled="currentPage === 1">Previous</a>
                      </li>
                      <!-- replace the v-for directive with the pagination function call -->
                      <li v-for="page in pagination(currentPage, totalPages, 3)" :key="page" class="page-item" :class="{ active: currentPage === page }">
                        <!-- check if the page is an ellipsis and display it as plain text -->
                        <span v-if="page === '...'" class="page-link">{{ page }}</span>
                        <!-- otherwise, display it as a clickable link -->
                        <a v-else class="page-link" @click="goToPage(page)">{{ page }}</a>
                      </li>
                      <!-- add a class of disabled and an attribute of disabled when the current page is the last page -->
                      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                        <a class="page-link"  @click="nextPage" :disabled="currentPage === totalPages">Next</a>
                      </li>
                    </ul>


                  </nav>
                </div>

              </div>
            </div>
            <!-- end col -->
          </div>

          <!-- end row -->
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->

      <!-- receipting modal -->
      <div class="modal fade download-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">Download Report</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <address>
                    <strong class="text-capitalize">Report Title</strong><br>
                    Revenue Collections By Subcounties in Homabay County
                  </address>
                </div>

                <div class="col-12">
                  <label><strong>Report Date Range</strong></label>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-xl-6 col-sm-6">
                      <div class="mb-3">
                        <label class="form-label">Date From</label>
                        <input class="form-control" placeholder="Start date" type="date">
                      </div>
                    </div>

                    <div class="col-xl-6 col-sm-6">
                      <div class="mb-3">
                        <label class="form-label">Date To</label>
                        <input class="form-control" placeholder="Start date" type="date">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <label><strong>Select File Type</strong></label>
                  <select class="form-select">
                    <option>Select Document Type</option>
                    <option>PDF file</option>
                    <option>Excel File</option>
                  </select>
                </div>

                <div class="col-12">
                  <button  type="button" class="btn btn-primary dropdown-toggle option-selector text-capitalize w-100 mt-5">
                    <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                  </button>
                </div>

              </div>

            </div>

          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of receipting modal -->

      <!-- invoice details -->
      <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">Invoice Details</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="col-12">
                <address>
                  <strong>Billed To:</strong><br>
                  Kelvin Njuguna<br>
                  email@mail.com, 0704 549 859<br>
                  Hse No. 410, 90 Degrees By Tsavo
                  <br><br>
                  1 Mar 2022, 10:20 AM
                </address>
              </div>
              <div class="col-12">
                <div class="py-2 mt-3">
                  <h3 class="font-size-15 fw-bold">Invoice Details ( <span class="text-primary fw-medium">No. 1237</span> )</h3>
                </div>
              </div>
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table-nowrap">
                    <thead>
                    <tr>
                      <th style="width: 70px;">No.</th>
                      <th>Item</th>
                      <th class="text-end">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>01</td>
                      <td>Rent Deposit</td>
                      <td class="text-end">KES 24,500</td>
                    </tr>

                    <tr>
                      <td>02</td>
                      <td>Monthly Rent</td>
                      <td class="text-end">KES 12,000</td>
                    </tr>

                    <tr>
                      <td>03</td>
                      <td>TAX (18%)</td>
                      <td class="text-end">KES 250</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-end">Total</td>
                      <td class="text-end fw-bold">KES 36,750</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-end">Paid</td>
                      <td class="text-end  fw-bold">KES 0.00</td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-0 text-end">
                        <strong>Balance</strong></td>
                      <td class="border-0 text-end">
                        <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="col-12 d-none">
                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                  <table class="w-100">
                    <tbody>
                    <tr data-id="1">
                      <td>
                        <label for="" class="">Payment Method</label>
                        <select class="form-control selectpicker w-100 payment-method" data-style="btn-secondary w-100" data-live-search="true" title="Select payment Method">
                          <option value="Mpesa">MPESA</option>
                          <option value="Cash">CASH</option>
                        </select>
                      </td>
                      <td class="px-3 ">
                        <div class="phone-num d-none">
                          <label for="">Phone No.</label>
                          <input type="text " class="form-control w-100 d-flex " placeholder="Phone No." spellcheck="false" data-ms-editor="true">
                        </div>

                      </td>
                      <td class="px-3">
                        <label for="">Amount To Be Paid</label>
                        <input type="text " class="form-control w-100 d-flex" placeholder="KES" spellcheck="false" data-ms-editor="true">

                      </td>
                      <td class="text-right float-right">
                        <div class="d-flex flex-column">
                          <label class="opacity-0">Something</label>
                          <a href="#" class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                        </div>
                      </td>


                    </tr>
                    </tbody>
                  </table>

                </div>
              </div>
              <div class="float-end">
                <a href="javascript:window.print()" class="btn btn-success waves-effect waves-light me-1"><i class="mdi mdi-printer font-16px"></i></a>
                <a href="javascript: void(0);" class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
              </div>
            </div>

          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- invoice details -->

      <!-- uploading an excel -->
      <div class="modal fade upload-plot-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Upload Plots</h5>

              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="" class="text-uppercase mb-3">Upload Excel Sheet</label>
                  </div>
                </div>
                <div class="col-12">
                  <div>
                    <form action="#" class="dropzone">
                      <div class="fallback">
                        <input name="file" type="file" multiple="multiple">
                      </div>
                      <div class="dz-message needsclick">
                        <div class="mb-3">
                          <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        </div>

                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">

              <a href="PrintDocs/Trade-Permit/permit.html" target="_blank" type="button"
                 class="btn btn-primary waves-effect waves-light">
                <i class="bx bx-check font-size-16 align-middle me-2"></i> Submit
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of uploading excel -->


      <!-- uploading an excel -->
      <div class="modal fade upload-plot-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Upload Propertise</h5>

              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="" class="text-uppercase mb-3">Upload Excel Sheet</label>
                  </div>
                </div>
                <div class="col-12">
                  <div>
                    <form action="#" class="dropzone">
                      <div class="fallback">
                        <input name="file" type="file" multiple="multiple">
                      </div>
                      <div class="dz-message needsclick">
                        <div class="mb-3">
                          <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        </div>

                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">

              <a href="PrintDocs/Trade-Permit/permit.html" target="_blank" type="button"
                 class="btn btn-primary waves-effect waves-light">
                <i class="bx bx-check font-size-16 align-middle me-2"></i> Submit
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of uploading excel -->

      <!-- adding category -->
      <div class="modal fade add-block-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Add A category</h5>

              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <p class="text-capitalize">
                    Enter the category Name for the category you want added
                  </p>
                  <div>
                    <label class="form-label text-uppercase">Enter Category name</label>
                    <input v-model="category" class="form-control" type="text" placeholder="Name of Category" >
                  </div>

                  <div class="mt-3">
                    <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table dataTable no-footer table-sm" id="">
                      <thead class="table-light">
                      <tr>

                        <th>#</th>
                        <th scope="col">Category</th>
                        <th scope="col">Number of Units</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr :key="index" :value="item" v-for="(item, index) in unitCategories">

                        <td>
                          {{index+1}}.
                        </td>
                        <td class="text-uppercase" @click="deleteUnitCategory(item.category)">
                          <a  href="javascript: void(0);" > {{item.category}} </a>
                        </td>
                        <td>
                          {{item.count}}
                        </td>


                      </tr>

                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
            <div class="modal-footer">

              <a @click="addUnitCategory()" type="button"
                 class="btn btn-primary waves-effect waves-light">
                <i class="bx bx-check font-size-16 align-middle me-2"></i> Submit
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- Adding category -->



      <footer class="footer ">
        <div class="container-fluid ">
          <div class="row ">

          </div>
        </div>
      </footer>
    </div>
    <!-- end main content-->

  </div>
  <!-- END layout-wrapper -->

  <!-- Right bar overlay-->
  <div class="rightbar-overlay "></div>


  </body>


</template>


<script>
import { execute, executeDownload, getRight, rent} from "@/api";
import ToolBarHousing from "@/components/housing/ToolBarHousing.vue";
import NavigationBar from "@/components/Navigation.vue";

export default {
  components: {ToolBarHousing, NavigationBar},
  name: "HousingRegister",
  data() {
    return {
      category:'',
      loading: true,
      // set the initial current page as 1
      currentPage: 1,
      // set the total number of pages as 10 (you can change this according to your data source)
      totalPages: 0,
      // set the number of items per page as 5 (you can change this according to your preference)
      pageSize: 10,
      summary: {
        count: 0,
        total: 0
      },

      download:'Download Report',
      search:'',
      totalRecords:0,
      totalReceiptAmount:0,
      totalUnit:0,
      totalOccupied:0,
      totalVaccant:0,
      totalArreas:0,

      properties: [
        {
          propertyID: null,
          property:  null,
          noOfOccupiedUnits:  null,
          noOfEmptyUnits:  null,
          noOfUnits: null,
          totalArrears:  null
        }
      ],
      unitCategories:[{
        id: null,
        category: null,
        count:null
      }]
    }
  },
  mounted() {
    this.getProperties()
    this.getUnitCategory()
  },
  watch:{
    //pagination code
    // define a watcher for the pageSize property
    pageSize(newPageSize) {
      this.getProperties()
      // get the total number of items from your data source (you can change this according to your logic)
      const totalItems = this.summary.count;
      // calculate the new total pages by dividing the total items by the new page size and rounding up
      const newTotalPages = Math.ceil(totalItems / newPageSize);
      // update the totalPages data property with the new value
      this.totalPages = newTotalPages;
      // reset the current page to 1
      this.currentPage = 1;

    },
    
    search: function () {
      this.getProperties()
    }
  },
  methods:{
    //pagination code
    pagination(currentPage, totalPages, maxVisibleButtons) {
      //initialize an empty array for the pages
      let pages = [];
      //calculate the start and end indices of the visible buttons
      let start = currentPage - Math.floor(maxVisibleButtons / 2);
      let end = currentPage + Math.floor(maxVisibleButtons / 2);
      //adjust the start and end indices if they are out of bounds
      if (start < 1) {
        start = 1;
        end = start + maxVisibleButtons - 1;
        if (end > totalPages) {
          end = totalPages;
        }
      }
      if (end > totalPages) {
        end = totalPages;
        start = end - maxVisibleButtons + 1;
        if (start < 1) {
          start = 1;
        }
      }
      //loop through the start and end indices and push the pages to the array
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      //add an ellipsis at the beginning if there are more pages before the start index
      if (start > 1) {
        pages.unshift("...");
      }
      //add an ellipsis at the end if there are more pages after the end index
      if (end < totalPages) {
        pages.push("...");
      }
// return the array of pages
      return pages;
    },
    // define a method to go to the previous page
    prevPage() {
      // check if the current page is not the first page
      if (this.currentPage > 1) {
        // decrement the current page by 1
        this.currentPage--;
        this.getProperties()
      }
    },
    // define a method to go to the next page
    nextPage() {
      // check if the current page is not the last page
      if (this.currentPage < this.totalPages) {
        // increment the current page by 1
        this.currentPage++;
        this.getProperties()
      }
    },
    // define a method to go to a specific page
    goToPage(page) {
      // check if the page is valid and not equal to the current page
      if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
        // set the current page to the given page
        this.currentPage = page;
        this.getProperties()
      }
    },
    
    getRight(type){
      return getRight(type)
    },
    numberFormat(num) {
      const britishNumberFormatter = new Intl.NumberFormat("en-GB");
      return britishNumberFormatter.format(num)
    },
    getProperties(){
      this.loading = true
      this.properties.splice(0)
      const data = new FormData();
      data.append("function", "getProperties");
      data.append("search", this.search);
      data.append("page", this.currentPage);
      data.append("rows_per_page", this.pageSize);
      execute(data,rent)
          .then((res) =>{
            this.loading = false
            if (res.data.success) {
              this.properties = res.data.data.properties
              this.totalReceiptAmount = res.data.data.summary.totalReceiptAmount;
              this.totalRecords = res.data.data.summary.totalRecords;



              this.totalUnit = this.properties.reduce((acc, cur) => {
                let value = Number(cur.noOfUnits);
                return acc + value;
              }, 0);

              this.totalOccupied = this.properties.reduce((acc, cur) => {
                let value = Number(cur.noOfOccupiedUnits);
                return acc + value;
              }, 0);

              this.totalVaccant = this.properties.reduce((acc, cur) => {
                let value = Number(cur.noOfEmptyUnits);
                return acc + value;
              }, 0);

              this.totalArreas = this.properties.reduce((acc, cur) => {
                let value = Number(cur.totalArrears);
                return acc + value;
              }, 0);

              this.summary.count = res.data.data.summary.totalRecords
              this.summary.total = res.data.data.summary.totalReceiptAmount
              this.totalPages =  Math.ceil(this.summary.count / this.pageSize);


            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    gotTo(route,propertyID){
      sessionStorage.setItem("propertyID",propertyID)
      this.$router.push(route);
    },
    downloadProperties(){
      this.download ="Please wait.."
      const data = new FormData();
      data.append("function", "downloadProperties");
      executeDownload(data,rent).then(response => {
        this.download ="Download Report"
        const link = document.createElement('a');
        link.href = URL.createObjectURL(response.data);
        link.download = "Rent.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    getUnitCategory(){
      this.unitCategories.splice(0)
      const data = new FormData();
      data.append("function", "getUnitCategory");
      execute(data,rent)
          .then((res) =>{
            if (res.data.success) {
              this.unitCategories =res.data.data.unitCategories
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    addUnitCategory() {
      // Ask for confirmation
      if (!confirm("Are you sure you want to add this category?")) {
        return; // Do nothing if the user cancels
      }

      // If confirmation is given, proceed with adding the category
      const data = new FormData();
      data.append("function", "addUnitCategory");
      data.append("category", this.category);
      execute(data, rent)
          .then((res) => {
            if (res.data.success) {
              this.getUnitCategory();
            } else {
              this.message = res.data.message;
            }
          })
          .catch((e) => {
            this.message = e.message;
          });
    },
    deleteUnitCategory(category) {
      // Ask for confirmation
      if (!confirm("Are you sure you want to remove this category?")) {
        return; // Do nothing if the user cancels
      }

      // If confirmation is given, proceed with adding the category
      const data = new FormData();
      data.append("function", "deleteUnitCategory");
      data.append("category", category);
      execute(data, rent)
          .then((res) => {
            if (res.data.success) {
              this.getUnitCategory();
            } else {
              this.message = res.data.message;
            }
          })
          .catch((e) => {
            this.message = e.message;
          });
    }

  }
}
</script>

<style scoped>

</style>