<template>
<head>

    <meta charset="utf-8" />
    <title>Desk Birdy | Parking Quick Stats</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/liabs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
        type="text/css" />

         <!-- // Add the new slick-theme.css if you want the default styling -->
     <link rel="stylesheet" href="assets/libs/slick/slick.css"/>
     <link rel="stylesheet" href="assets/libs/slick/slick-theme.css"/>

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->

    <!-- owl.carousel css -->
    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

</head>

<body data-sidebar="dark">


    <!-- Begin page -->
    <div id="layout-wrapper">

        <NavigationBar/>



        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row ">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">{{saccoName}} Sacco Details</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Parking</a></li>
                                        <li class="breadcrumb-item"><a href="parking-fleet.html">Fleet manager</a></li>
                                        <li class="breadcrumb-item"><a href="#">{{saccoName}} Sacco Details</a></li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                    <!-- tool bar -->
                    <ToolBar/>

                    <!-- end of toolbar -->
                    <div class="row">

                        <div class="col-12 px-sm-30px">
                            <div class="card">
                                <div class="card-header bg-white border-bottom d-flex justify-content-between">
                                    <div>
                                        <h4 class="card-title text-capitalize">registered Vehicles</h4>
                                    </div>
                                    <div>
                                      <!--  <button  data-toggle="modal" data-target=".add-car-modal" type="button" class="btn btn-primary waves-effect waves-light">
                                            <i class="bx bx-plus font-size-16 align-middle me-2"></i> Add Vehicles
                                        </button>-->
                                    </div>
                                </div>

                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                        <div class="flex-grow-1">
                                            <div class="search-box mb-0 me-3">
                                                <div class="position-relative">
                                                    <input v-model="search" type="text" class="form-control bg-light border-light rounded" placeholder="Search..."
                                                        fdprocessedid="husj3l" spellcheck="false" data-ms-editor="true">
                                                    <i class="bx bx-search-alt search-icon"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex">
                                            <select v-model="complianceStatus" class="form-select w-auto font-13px mx-3">
                                                <option value="">All Vehicles</option>
                                                <option value="COMPLIANT">Compliant</option>
                                                <option value="NON-COMPLIANT">Non Compliant</option>

                                            </select>


                                            <button @click="downloadSaccoVehiclesStatus()" data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                                <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">{{download}}</span>
                                            </button>

                                        </div>

                                    </div>

                                    <!--<div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                                        <button @click="printBill" type="button" data-bs-toggle="modal" data-bs-target=".download-modal"
                                            class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i>
                                            Print Selected Invoices</button>
                                    </div>-->

                                </div>


                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table" id="datatable-buttons">
                                            <thead class="table-dark">
                                                <tr>
                                                    <th>#</th>
                                                    <th scope="col">Vehicle</th>
                                                    <th scope="col">Category</th>
                                                   <!-- <th scope="col" class="">Last Payment Date</th>-->
                                                    <th scope="col" class="">Expiry</th>
                                                    <th scope="col" class="">Current Status</th>
                                                    <th scope="col" class="text-right">Parking Fee</th>
                                                    <th scope="col" class="text-right">Outstanding Areas</th>
                                                    <th scope="col" class="text-right">Total Payable</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr :key="index" :value="item" v-for="(item, index) in vehicles">
                                                    <th>{{index+1.}}</th>
                                                    <td class="text-uppercase">
                                                        {{item.numberPlate}}
                                                    </td>
                                                    <td>
                                                        {{item.category}}
                                                    </td>
                                                   <!-- <td>
                                                        10 Jan 2023 10:56 AM <span class="text-uppercase fw-bold">kes 200</span>
                                                    </td>-->

                                                    <td>
                                                        {{item.endDate}}
                                                    </td>
                                                    <td>
                                                        <span v-if="item.compliance_status ==='uncompliant'" class="badge rounded-pill bg-danger text-uppercase">{{item.compliance_status}}</span>
                                                        <span v-if="item.compliance_status ==='compliant'" class="badge rounded-pill bg-success text-uppercase">{{item.compliance_status}}</span>
                                                    </td>
                                                    <td class="text-right text-uppercase fw-bold">KES {{item.amount}} (x {{item.quantity}})</td>
                                                    <td class="text-right text-uppercase ">KES {{item.totalBillBalance}}</td>
                                                    <td class="text-right text-uppercase">KES {{item.totalBillBalance}}</td>
                                                    <td class="text-right">
                                                        <div class="d-flex text-right flex-end justify-content-end">
                                                            <button  role="button" data-toggle="modal" data-target=".waiver-modal" aria-haspopup="true" class="btn btn-sm btn-primary waves-effect btn-label waves-light text-uppercase me-3"><i class="mdi mdi-shield-check label-icon"></i> Arrears Weiver</button>

                                                            <div class="dropdown">


                                                                <button  role="button" data-bs-toggle="dropdown" aria-haspopup="true" class="btn btn-sm btn-warning text-black waves-effect btn-label waves-light text-uppercase"><i class="mdi mdi-arrow-down-drop-circle label-icon"></i> More</button>

                                                                <div class="dropdown-menu dropdown-menu-end">

                                                                    <a @click="selectedVehicle(item)" class="dropdown-item" href="#" data-toggle="modal" data-target=".details-modal"><i class="font-size-15 mdi mdi-eye-plus-outline me-3"></i>Vehicle Details</a>
                                                                    <a @click="gotTo('parking-fleet-details-statements',item.numberPlate)" class="dropdown-item" href="javascript: void(0);" ><i class="font-size-15 mdi mdi-microsoft-excel me-3"></i>Statements</a>
                                                                    <a @click="selectedVehicle(item)" class="dropdown-item" href="#" data-toggle="modal" data-target=".send-sms-modal"><i class="font-size-15 mdi mdi-chat-processing me-3"></i>Send Reminder</a>
                                                                    <a @click="getMonths(item)" class="dropdown-item" href="#" data-toggle="modal" data-target=".add-arrears-modal"><i class="font-size-15 mdi mdi-cash-plus me-3"></i>Mannualy Add Arrears</a>
                                                                    <a @click="selectedVehicle(item)" class="dropdown-item" href="#" data-toggle="modal" data-target=".move-car-modal"><i class="font-size-15 mdi mdi-arrow-left-thick  me-3 "></i>Move Vehicle</a>
                                                                    <a @click="selectedVehicle(item)" class="dropdown-item" href="#" data-toggle="modal" data-target=".add-car-modal" ><i class="font-size-15  mdi-delete-forever mdi me-3 text-danger"></i>Delete Vehicle</a>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
           <!-- payment modal -->
         <div class="modal fade" id="payment-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
            <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header d-none">
                    <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                    <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
                    </button>

                </div>
                <div class="modal-body">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                    <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal" data-dismiss="modal" aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
                    </button>
                    </div>

                    <div class="payment-panel-parent">

                        <div class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success d-non payment-panel">
                            <div class="success-image mb-4 pb-4 pt-4">
                                <img src="assets/images/bills.svg" height="200" alt="">
                            </div>
                            <h4 class="text-black fw-bold">Bill Successfully Created</h4>
                            <p class="text-muted mb-4">The bill has been created Successfully, you can print the bill or opt to receive payment for this bill.</p>
                            <div class="d-flex w-100 pb-2">
                                <button  type="button" class="btn btn-primary btn-block  me-3 flex-grow-1 print-bill-btn">
                                    <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                                    Receive Payment
                                </button>
                                <a href="PrintDocs/Bill/bill.html" target="_blank" data-dismiss="" type="button" class="btn btn-outline-secondary waves-effect dismin-modal">Print Bill</a>
                            </div>
                        </div>

                        <div class="send-method payment-panel d-none ">
                            <label for="" class="mb-0 pb-0">Payment Method</label>
                            <p><small class="text-muted">How would you like to send this money?</small></p>

                            <div>
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button fw-medium" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <div class="flex-shrink-0 me-3">
                                                    <img class="rounded-circle" src="assets/images/users/mpesa.jpg" alt="Mobile Money" height="65">
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <p class="m-0 p-0 text-uppercase fw-semibold">MPESA</p>
                                                    <p class="mb-0 p-0"> <small>A payment request will be sent directly to the customer</small></p>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div>
                                                    <p>
                                                        A payment request of <strong class="text-black">KES 1,500</strong> will be sent to the MPESA number you enter below.
                                                    </p>
                                                </div>
                                                <div class="form-group">
                                                    <label for="">MPESA Number</label>
                                                    <div class="form-floating mb-3">
                                                        <input type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                                        <label for="floatingnameInput">Phone No.</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div class="d-none transaction-summary payment-panel">
                            <label for="">Transaction Breakdown</label>
                            <div class="border p-4 rounded ">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="text-muted ">
                                            Bill NO.
                                        </div>
                                    </div>

                                    <div class="col-lg-6 align-self-end">
                                        <div class=" text-right text-black">
                                            BLL12364558T
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="text-muted mt-2">
                                            Receipt For
                                        </div>
                                    </div>

                                    <div class="col-lg-6 align-self-end">
                                        <div class=" text-right text-black">
                                            Alex Wanjala
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="text-muted mt-2">
                                            Payment Method
                                        </div>
                                    </div>

                                    <div class="col-lg-6 align-self-end">
                                        <div class=" text-right text-black">
                                            MPESA
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="text-muted mt-2">
                                            Transaction Amount
                                        </div>
                                    </div>

                                    <div class="col-lg-6 align-self-end">
                                        <div class=" text-right text-black">
                                            KES 1,500
                                        </div>
                                    </div>


                                    <div class="col-12 text-black"><hr class="mb-0 pb-0"></div>

                                    <div class="col-lg-6 text-uppercase">
                                        <div class="text-muted mt-2 font-21">
                                            Total
                                        </div>
                                    </div>

                                    <div class="col-lg-6 align-self-end text-uppercase">
                                        <div class=" text-right text-black font-21 fw-bold">
                                            KES 1,500
                                        </div>
                                    </div>
                                </div>

                                <div class="mpesa-confirmation ">
                                    <p class="text-muted mt-2">A payment request of <strong class="text-black">KES 1,500</strong> will be sent to <strong class="text-black">phone number (0704549859)</strong> soon after you click the <strong class="text-black text-capitalize">Send Request</strong> button bellow.
                                        <br>
                                        <br>
                                        Remember to <strong class="text-black">Check your phone</strong> to confirm payment by entering your Mpesa pin.</p>

                                        <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect btn-send waves-light text-center w-100">
                                            <div class="d-flex justify-content-center align-items-center">
                                                <div class="stk-timer-container d-none justify-content-center align-items-center">
                                                    <span class="mdi mdi-timer-outline font-16px"></span>
                                                    <span class="stk-timer px-2"></span>
                                                </div>
                                                <div class="justify-content-center align-items-center d-flex">
                                                    <span class="px-2">Send Request</span>
                                                    <div class="flip-x"><i class="mdi mdi-reply ms-3 font-16px"></i></div>
                                                </div>

                                            </div>
                                         </button>
                                </div>

                                <div class="my-wallet-confirmation d-none">
                                    <p class="text-muted mt-2 "><strong class="text-uppercase text-black">KES 300</strong> will be deducted from your guardian blink wallet and amount will be credited to <strong class="text-capitalize text-black">Alex's pocket money account</strong>.</p>
                                    <p class="text-muted">confirm transaction by clicking the <strong class="text-capitalize text-black">send money</strong> button.</p>

                                    <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center w-100">
                                        <div class="d-flex justify-content-center align-items-center"> <span class="mx-2">Send Money</span> <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div></div>
                                     </button>

                                </div>


                            </div>
                        </div>

                        <div class="d-none transaction-summary loader-panel">
                            <div class="p-5 py-0 pt-3 d-flex justify-content-center align-items-center text-center flex-column">
                                <div class="p-5">
                                    <div class="spinner-border text-info m-1" role="status">
                                        <span class="sr-only text-uppercase">Loading...</span>
                                    </div>
                                </div>
                                <h4 class="text-black fw-bold">Payment Being Processed</h4>
                                <div class="mb-5">Ask Client to be checking their Phone for a payment request thats currently being Processed</div>
                            </div>
                        </div>
                        <div class="text-center d-flex flex-column justify-content-around align-items-center sent-success d-none payment-panel">
                            <div class="success-image mb-4">
                                <img src="assets/images/payment-confirmation-images/sent.svg" height="200" alt="">
                            </div>
                            <h4 class="text-blink-primary fw-bold">Transaction Complete</h4>
                            <p class="text-muted mb-4"><strong class="text-black">KES 1,500.00</strong> has been received for <strong class="text-black">Bill No. YUB48997</strong></p>


                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex d-none bill-modal-footer">
                    <button href="javascript: void(0);" disabled class="btn btn-outline-light waves-effect waves-light payment-prev"> <i class="mdi mdi-arrow-left ms-1"></i> Previouse </button>
                    <button href="javascript: void(0);" class="btn btn-primary waves-effect waves-light payment-next">Next <i class="mdi mdi-arrow-right ms-1"></i></button>
                    <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center d-none">
                       <div class="d-flex justify-content-center align-items-center"> <span>Send Money</span> <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div></div>
                    </button>
                </div>
            </div>
            </div>
        </div>
        <!-- end of payment modal -->
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Invoice Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12">
                                <address>
                                    <strong>Billed To:</strong><br>
                                    Kelvin Njuguna<br>
                                    email@mail.com, 0704 549 859<br>
                                    Hse No. 410, 90 Degrees By Tsavo
                                    <br><br>
                                    1 Mar 2022, 10:20 AM
                                </address>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Invoice Details ( <span
                                            class="text-primary fw-medium">No. 1237</span> )</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead>
                                            <tr>
                                                <th style="width: 70px;">No.</th>
                                                <th>Item</th>
                                                <th class="text-end">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>Rent Deposit</td>
                                                <td class="text-end">KES 24,500</td>
                                            </tr>

                                            <tr>
                                                <td>02</td>
                                                <td>Monthly Rent</td>
                                                <td class="text-end">KES 12,000</td>
                                            </tr>

                                            <tr>
                                                <td>03</td>
                                                <td>TAX (18%)</td>
                                                <td class="text-end">KES 250</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Total</td>
                                                <td class="text-end fw-bold">KES 36,750</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Paid</td>
                                                <td class="text-end  fw-bold">KES 0.00</td>
                                            </tr>

                                            <tr>
                                                <td colspan="2" class="border-0 text-end">
                                                    <strong>Balance</strong>
                                                </td>
                                                <td class="border-0 text-end">
                                                    <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                            <tr data-id="1">
                                                <td>
                                                    <label for="" class="">Payment Method</label>
                                                    <select class="form-control selectpicker w-100 payment-method"
                                                        data-style="btn-secondary w-100" data-live-search="true"
                                                        title="Select payment Method">
                                                        <option value="Mpesa">MPESA</option>
                                                        <option value="Cash">CASH</option>
                                                    </select>
                                                </td>
                                                <td class="px-3 ">
                                                    <div class="phone-num d-none">
                                                        <label for="">Phone No.</label>
                                                        <input type="text " class="form-control w-100 d-flex "
                                                            placeholder="Phone No." spellcheck="false"
                                                            data-ms-editor="true">
                                                    </div>

                                                </td>
                                                <td class="px-3">
                                                    <label for="">Amount To Be Paid</label>
                                                    <input type="text " class="form-control w-100 d-flex"
                                                        placeholder="KES" spellcheck="false" data-ms-editor="true">

                                                </td>
                                                <td class="text-right float-right">
                                                    <div class="d-flex flex-column">
                                                        <label class="opacity-0">Something</label>
                                                        <a href="#"
                                                            class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                                                    </div>
                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a href="javascript:window.print()"
                                    class="btn btn-success waves-effect waves-light me-1"><i
                                        class="mdi mdi-printer font-16px"></i></a>
                                <a href="javascript: void(0);"
                                    class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <!-- add vehicles -->
            <div class="modal fade add-car-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
            aria-hidden="true">
            <div class="modal-dialog  modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="myLargeModalLabel">Remove This Vehicle from {{saccoName}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                   <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <p>
                                    Delete Car with registration number <strong class="text-uppercase">{{numberPlate}}</strong> from {{saccoName}}
                                </p>
                                <div>
                                    <label class="form-label">Reason For Removing The vehicle </label>
                                    <textarea name="" id="" class="form-control" placeholder="Enter Reason for deleting this vehicle"></textarea>
                                </div>
                            </div>
                        </div>
                   </div>
                    <div class="modal-footer">

                        <div class="float-end">

                            <a data-dismiss="modal" @click="removeVehicle()" href="javascript: void(0);"
                                class="btn btn-danger w-md waves-effect waves-light w-100">Delete</a>
                        </div>
                    </div>

                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
            <!-- end of adding vehicles -->


            <!-- add vehicles -->
            <div class="modal fade add-car-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Remove This Vehicle from Usiku Sacco</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <p>
                                        Delete Car with registrtaion number <strong class="text-uppercase">{{numberPlate}}</strong> from {{saccoName}}.
                                    </p>
                                    <div>
                                        <label class="form-label">Reason For Removing The vehicle </label>
                                        <textarea name="" id="" class="form-control" placeholder="Enter Reason for deleting this vehicle"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <div class="float-end">

                                <a href="javascript: void(0);"
                                   class="btn btn-danger w-md waves-effect waves-light w-100">Delete</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of adding vehicles -->

            <!-- move vehicle to another sacco -->
            <div class="modal fade move-car-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Move vehicle from {{saccoName}}</h5>
                            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <p class="text-capitalize">
                                        Move Vehicle with registration number <strong class="text-uppercase">{{numberPlate}}</strong> from {{saccoName}}.
                                    </p>
                                    <div>
                                        <label class="form-label text-uppercase">Search for Sacco</label>
                                        <input v-model="searchSacco" class="form-control" type="text" placeholder="Search for Sacco" >
                                    </div>

                                    <div class="mt-3">
                                        <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table dataTable no-footer table-sm" id="datatable-buttons">
                                            <thead class="table-light">
                                            <tr>
                                                <th></th>
                                                <th scope="col">Sacco</th>
                                                <th scope="col">Subcounty</th>

                                            </tr>
                                            </thead>
                                            <tbody>

                                            <tr :key="index" :value="item" v-for="(item, index) in saccoList">

                                                <td>
                                                    <div class="d-flex  align-items-center">
                                                        <div class="the-mail-checkbox pr-4">
                                                            <input @click="selectedSacco(item)" class="form-check-input" type="radio" name="form-sacco" >
                                                        </div>

                                                    </div>
                                                </td>
                                                <td class="text-uppercase">
                                                    {{item.saccoName}}
                                                </td>
                                                <td>
                                                   {{item.subCountyName}}
                                                </td>

                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <a @click="moveVehicle()" data-dismiss="modal" href="javascript: void(0);" class="btn btn-primary w-md waves-effect waves-light w-100">Move Vehicle</a>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>

            <!-- move vehicle to another sacco -->

            <!-- adding arrears -->
            <div class="modal fade add-arrears-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Manually add arrears to the Vehicle</h5>

                            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <p class="text-capitalize">
                                        Use this section to manually add Arrears to the vehicle with registration number <strong class="text-uppercase">{{numberPlate}}</strong> for either of the following months.
                                    </p>
                                    <div>
                                        <label class="form-label text-uppercase">Select the Month(s) the vehicle owes the county in Arrears</label>

                                    </div>

                                   <!-- {{selectedItems}}-->

                                    <div class="mt-3">
                                        <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table dataTable no-footer table-sm" id="datatable-buttons">
                                            <thead class="table-light">
                                            <tr>

                                                <th style="width: 20px;"></th>
                                                <th scope="col">Month</th>
                                                <th scope="col" class="text-right">Amount Payable</th>

                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr :key="index" :value="item" v-for="(item, index) in months">

                                                <td>
                                                    <div class="d-flex  align-items-center">
                                                        <div class="the-mail-checkbox pr-4">
                                                            <input @click="selectedMoth(item,$event)" class="form-check-input" type="checkbox" id="month-1">
                                                        </div>

                                                    </div>
                                                </td>
                                                <td class="text-uppercase">
                                                    <label for="month-1">{{item.month}}</label>
                                                </td>
                                                <td class="text-right">
                                                    KES {{item.amount}}
                                                </td>

                                            </tr>

                                            </tbody>
                                            <tfoot>
                                            <tr class="table-light">
                                                <TH></TH>
                                                <th>
                                                    {{selectedItems.length}} Months
                                                </th>
                                                <th class="text-right">
                                                    KES {{selectedTotalAmount}}
                                                </th>
                                            </tr>
                                            </tfoot>
                                        </table>

                                        <div class="alert alert-warning fw-bold mt-3" role="alert">
                                            Kindly note after submiting this cannot be outdone not unless it is weivered by an authorized official from the county
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <a @click="generateBill()" href="javascript: void(0);" data-dismiss="modal" class="btn btn-primary w-md waves-effect waves-light w-100">Submit</a>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of arrears adding -->

            <!-- sending sms reminders to car owners -->
            <div class="modal fade send-sms-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Sending payment Reminder</h5>
                            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <p class="text-capitalize">
                                        Use this section to send a payment reminder to the vehicle owner of vehicle with registration Number  <strong class="text-uppercase">{{vehicle.numberPlate}}</strong> for either of the following months.
                                    </p>
                                    <div>
                                        <label class="form-label text-uppercase">Select the Month(s) the vehicle owes the county in Arrears</label>

                                    </div>

                                    <div class="mt-3">
                                        <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table dataTable no-footer table-sm" id="datatable-buttons">
                                            <thead class="table-light">
                                            <tr>
                                                <th style="width: 20px;">#</th>
                                                <th scope="col">Month</th>
                                                <th scope="col" class="text-right">Amount Payable</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr :key="index" :value="item" v-for="(item, index) in billInfo">
                                                <td>
                                                    {{index+1}}.
                                                </td>
                                                <td class="text-uppercase">
                                                    <label for="month-1">{{item.description}}</label>
                                                </td>
                                                <td class="text-right">
                                                    KES {{numberFormat(item.billBalance)}}
                                                </td>

                                            </tr>
                                            </tbody>
                                            <tfoot>
                                            <tr class="table-light">
                                                <TH></TH>
                                                <th>
                                                    {{billInfo.length}} Months
                                                </th>
                                                <th class="text-right">
                                                    KES {{numberFormat(totalBillBalance)}}
                                                </th>
                                            </tr>
                                            </tfoot>
                                        </table>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <a @click="sendReminder()" data-dismiss="modal" href="javascript: void(0);" class="btn btn-success w-md waves-effect waves-light w-100">Submit</a>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- sending payment reminders to vehicle owners -->

            <!-- vehicle details modal -->
            <div class="modal fade details-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Vehicle details</h5>
                            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">


                                    <div class="mt-3">
                                        <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table dataTable no-footer table-sm" id="datatable-buttons">

                                            <tbody>
                                            <tr>

                                                <td>
                                                    Plate Number
                                                </td>
                                                <td class="text-uppercase">
                                                    {{vehicle.numberPlate}}
                                                </td>
                                            </tr>

                                            <tr>

                                                <td>
                                                    Vehicle Category
                                                </td>
                                                <td class="text-uppercase">
                                                   {{vehicle.category}}
                                                </td>
                                            </tr>

                                            <tr>

                                                <td>
                                                    Amount Payable Monthly
                                                </td>
                                                <td class="text-uppercase">
                                                    KES {{numberFormat(vehicle.amount)}}
                                                </td>
                                            </tr>

                                            <tr>

                                                <td>
                                                    Owner
                                                </td>
                                                <td class="text-uppercase">
                                                    <input v-model="vehicle.owner" type="text" class="from-control form-control-sm" placeholder="Enter Owner's Full Names" >
                                                </td>
                                            </tr>

                                            <tr>

                                                <td>
                                                    Owner's Phone No.
                                                </td>
                                                <td class="text-uppercase">
                                                    <input v-model="vehicle.phoneNumber" type="text" class="from-control form-control-sm" placeholder="Enter Owner's Phone" >
                                                </td>
                                            </tr>
                                            </tbody>

                                        </table>


                                    </div>

                                    <div class="alert alert-secondary fw-semibold mt-3" role="alert">
                                        Remember to submit the changes incase you do any changes
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <a @click="updateVehicle()" data-dismiss="modal" href="javascript: void(0);" class="btn btn-primary w-md waves-effect waves-light w-100 text-uppercase">Save Changes</a>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of vehicle details modal -->

            <!-- applying waivers onto a vehicle -->
            <div class="modal fade waiver-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Waiver This Vehicle</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <p class="text-capitalize">
                                        Use this section to send a payment reminder to the vehicle owner of vehicle with registration Number  <strong class="text-uppercase">KAD 123T</strong> for either of the following months.
                                    </p>
                                    <div>
                                        <label class="form-label text-uppercase">Select the Month(s) the vehicle owes the county in Arrears</label>

                                    </div>

                                    <div class="mt-3">
                                        <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table dataTable no-footer table-sm" id="datatable-buttons">

                                            <tbody>
                                            <tr>

                                                <td>
                                                    Plate Number
                                                </td>
                                                <td class="text-uppercase">
                                                    {{numberPlate}}
                                                </td>
                                            </tr>

                                            <tr>

                                                <td>
                                                    Vehicle Category
                                                </td>
                                                <td class="text-uppercase">
                                                    14 Seater Matatu
                                                </td>
                                            </tr>

                                            <tr>

                                                <td>
                                                    Amount Payable Monthly
                                                </td>
                                                <td class="text-uppercase">
                                                    KES 450
                                                </td>
                                            </tr>

                                            <tr>

                                                <td>
                                                    Owner
                                                </td>
                                                <td class="text-uppercase">
                                                    <input type="text" class="from-control form-control-sm" placeholder="Enter Owner's Full Names" >
                                                </td>
                                            </tr>

                                            <tr>

                                                <td>
                                                    Owner's Phone No.
                                                </td>
                                                <td class="text-uppercase">
                                                    <input type="text" class="from-control form-control-sm" placeholder="Enter Owner's Phone" value="0704549859">
                                                </td>
                                            </tr>
                                            </tbody>

                                        </table>


                                    </div>

                                    <div class="alert alert-secondary fw-semibold mt-3" role="alert">
                                        Remember to submit the changes incase you do any changes
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <a href="javascript: void(0);" class="btn btn-primary w-md waves-effect waves-light w-100 text-uppercase">Save Changes</a>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- applyinf waiver onto a vehicle -->


            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">

    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
             County Government
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>

    </body>

</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import {biller, execute, executeDownload, parking} from "@/api";
    import ToolBar from "@/components/parking/ToolBar";
    export default {
        name: "ParkingFleetDetails",
        components: {ToolBar, NavigationBar},
        data(){
            return{
                saccoName2:'',
                searchSacco:'',
                download:'Download Report',
                numberPlate:'',
                search:'',
                saccoName:'',
                complianceStatus:'',
                vehicle:{
                    id: "18",
                    saccoID: "4",
                    saccoName: "MINNI BAKERIES",
                    numberPlate: "KBQ134J",
                    category: "Canter Lorry",
                    owner: "",
                    phoneNumber: "",
                    feeID: "83845",
                    amount: "2200",
                    endDate: "2023-12-14 00:00:00",
                    compliance_status: "uncompliant",
                    totalBillBalance: "0"
                },
                vehicles: [
                    {
                        id: "18",
                        saccoID: "4",
                        saccoName: "MINNI BAKERIES",
                        numberPlate: "KBQ134J",
                        category: "Canter Lorry",
                        owner: "",
                        phoneNumber: "",
                        feeID: "83845",
                        amount: "2200",
                        endDate: "2023-12-14 00:00:00",
                        compliance_status: "uncompliant",
                        totalBillBalance: "0",
                        quantity:null
                    }],
                todayHighlights:[
                    {
                        title: "LOGGED VEHICLES",
                        amount: "41,695"
                    }
                    ],
                months:[
                    {
                        month: "JAN 2023",
                        amount:2000,
                    },
                    {
                        month: "FEB 2023",
                        amount:2000,
                    }
                    , {
                        month: "MARC 2023",
                        amount:2000,
                    }

                ],
                selectedItems: [],
                selectedTotalAmount:0,
                billDetails:{
                    id:'',
                    billNo:'',
                    incomeTypeID:'',
                    incomeTypeDescription:'',
                    costCenterNo:'',
                    accountNo:'',
                    feeID:'',
                    detailAmount:'',
                    receiptAmount:'',
                    billBalance:'',
                    wardID:'',
                    subCountyID:'',
                    status:'',
                    dateCreated:''
                },
                paymentData: {
                    bankReceiptNo: null,
                    accNo: null,
                    payBillNo: null,
                    amount: null,
                    phoneNumber: null,
                    success: false,
                    paymentMode:null
                },
                summary: {
                    count: 0
                },
                saccoList: [
                    {
                        id: "147",
                        saccoID: "123",
                        saccoName: "TEST SACC",
                        physicalAddress: "Nairobi West, Gandhi Ave, ",
                        fullNames: "Alex Wanjala",
                        role: "",
                        phoneNumber: "0714420943",
                        subCountyID: "12052",
                        subCountyName: "IGEMBE CENTRAL",
                        wardID: "120520258",
                        wardName: "IGEMBE EAST",
                        zone: "KIENGU SLAUGHTER HOUSE",
                        quantity:null
                    }],
                billInfo: [
                    {
                        id: "278357",
                        billNo: "BLL0107125040759",
                        feeID: "83844",
                        feeDescription: "Light Truck",
                        fiscalYear: "",
                        paymentCode: "BLL0107125040759",
                        customer: "KCN887D",
                        description: "TEST SACC December 2023",
                        periodID: "1",
                        wardID: "120520258",
                        subCountyID: "12052",
                        subCountyName: "IGEMBE CENTRAL",
                        wardName: "IGEMBE EAST",
                        createdBy: "1",
                        billTotal: "2200",
                        receiptAmount: "0",
                        billBalance: "2200",
                        status: null,
                        dateCreated: "2024-01-06 21:45:05.054831",
                        zone: "KIENGU SLAUGHTER HOUSE",
                        departmentID: "5",
                        department: "Roads, Public Works, Transport And Infrastructure",
                        idNo: "demo",
                        phoneNumber: "254704722837",
                        names: "demo",
                        customerPhoneNumber: "0704766654",
                        category: "SUPER ADMIN",
                        incomeTypePrefix: "PKN"
                    }],
                totalBillBalance:0,
                totalBillTotal:0,
                totalReceiptAmount:0,

            }
        },
        mounted() {

            $('.todays-entries').slick({
                arrows:true,
                prevArrow:'<button type="button" class="slick-prev"></button>',
                nextArrow:'<button type="button" class="slick-next"></button>',
                centerMode:true,
                slidesToShow:7,
                speed: 7000,
                autoplay: true,
                autoplaySpeed: 0,
                cssEase: 'linear',
                slidesToScroll: 1,
                pauseOnFocus: true,
                variableWidth: true
            });

            this.saccoName = sessionStorage.getItem("saccoName")
            this.$watch('complianceStatus', function (newValue) {
                this.getSaccoVehiclesStatus()
            })
            this.$watch('search', function (newValue) {
                this.getSaccoVehiclesStatus()
            })




            this.saccoName = sessionStorage.getItem("saccoName")
            this.$watch('complianceStatus', function (newValue) {
                this.getSaccoVehiclesStatus()
            })
            this.$watch('search', function (newValue) {
                this.getSaccoVehiclesStatus()
            })

            this.$watch('searchSacco', function (newValue) {
                this.getSaccoList()
            })

            this.getSaccoVehiclesStatus()
            this.getTodayHighlights()
            this.getSaccoList()
        },
        methods:{

            selectedMoth(item, event) {
                const isChecked = event.target.checked;
                if (isChecked) {
                    this.selectedItems.push(item);
                } else {
                    // Remove the item from the selectedItems array if it's unchecked
                    const indexToRemove = this.selectedItems.findIndex(selectedItem => selectedItem === item);
                    if (indexToRemove !== -1) {
                        this.selectedItems.splice(indexToRemove, 1);
                    }
                }

                    this.selectedTotalAmount = this.selectedItems.reduce((acc, cur) => {
                        let amount = Number(cur.amount);
                        if (!isNaN(amount)) {
                            return acc + amount;
                        } else {
                            return acc;
                        }
                    }, 0);
            },

            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },

            getBillInfo(){
                this.billInfo.splice(0)
                const data = new FormData();
                data.append("function", "getBillInfo");
                data.append("customer", this.numberPlate);
                execute(data,parking)
                    .then((res) =>{
                        this.loading = false
                        if (res.data.success) {
                            this.billInfo = res.data.data.billInfo

                            this.totalBillBalance = this.billInfo.reduce((acc, cur) => {
                                let amountTotal = Number(cur.billBalance);
                                if (!isNaN(amountTotal)) {
                                    return acc + amountTotal;
                                } else {
                                    return acc;
                                }
                            }, 0);
                            this.totalBillTotal = this.billInfo.reduce((acc, cur) => {
                                let amountTotal = Number(cur.billTotal);
                                if (!isNaN(amountTotal)) {
                                    return acc + amountTotal;
                                } else {
                                    return acc;
                                }
                            }, 0);
                            this.totalReceiptAmount = this.billInfo.reduce((acc, cur) => {
                                let amountTotal = Number(cur.receiptAmount);
                                if (!isNaN(amountTotal)) {
                                    return acc + amountTotal;
                                } else {
                                    return acc;
                                }
                            }, 0);


                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },

            getMonths(item){
                this.selectedTotalAmount =0
                this.selectedItems.splice(0)
                this.numberPlate = item.numberPlate
                this.months.splice(0)
                const data = new FormData();
                data.append("function", "getMonths");
                data.append("vehicle",JSON.stringify(item));
                data.append("names", sessionStorage.getItem("names"));
                data.append("phoneNumber", sessionStorage.getItem("phoneNumber"));
                data.append("idNo", sessionStorage.getItem("idNo"));
                execute(data,parking)
                    .then((res) =>{
                        if (res.data.success) {
                            this.months = res.data.data.months

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getTodayHighlights(){
                this.todayHighlights.splice(0)
                const data = new FormData();
                data.append("function", "todayHighlights");
                execute(data,parking)
                    .then((res) =>{
                        if (res.data.success) {
                            this.todayHighlights = res.data.data.todayHighlights

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getSaccoList(){
                this.saccoList.splice(0)
                const data = new FormData();
                data.append("function", "getSaccoList");
                data.append("keyword", this.searchSacco);
                execute(data,parking)
                    .then((res) =>{
                        if (res.data.success) {
                            this.saccoList = res.data.data.saccoList
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            selectedSacco(item){
                this.saccoID =item.saccoID
                this.saccoName2 =item.saccoName
            },

            updateVehicle(){
                const data = new FormData();
                data.append("function", "updateVehicle");
                data.append("vehicle", JSON.stringify(this.vehicle));
                execute(data,parking)
                    .then((res) =>{
                        if (res.data.success) {
                            this.getSaccoVehiclesStatus()
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            moveVehicle(){
                const data = new FormData();
                data.append("function", "moveVehicle");
                data.append("saccoID", this.saccoID);
                data.append("saccoName", this.saccoName2);
                data.append("numberPlate", this.numberPlate)
                execute(data,parking)
                    .then((res) =>{
                        if (res.data.success) {
                            this.getSaccoVehiclesStatus()
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            sendReminder(){
                const data = new FormData();
                data.append("function", "sendReminder");
                data.append("numberPlate", this.vehicle.numberPlate)
                data.append("totalBillBalance", this.totalBillBalance)
                data.append("saccoName", this.saccoName)
                execute(data,parking)
                    .then((res) =>{
                        if (res.data.success) {
                            alert(res.data.message)
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },

            gotTo(route,numberPlate){
                sessionStorage.setItem("numberPlate",numberPlate)
                this.$router.push(route);
            },
            getSaccoVehiclesStatus(){
                this.vehicles.splice(0)
                const data = new FormData();
                data.append("function", "getSaccoVehiclesStatus");
                data.append("compliance_status", this.complianceStatus);
                data.append("saccoID", sessionStorage.getItem('saccoID'));
                data.append("keyword", this.search);
                execute(data,parking)
                    .then((res) =>{
                        if (res.data.success) {
                            this.vehicles = res.data.data.vehicles

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            selectedVehicle(item){
                this.vehicle = item
                this.numberPlate = item.numberPlate
                this.getBillInfo()
            },
            removeVehicle(){
                this.vehicles.splice(0)
                const data = new FormData();
                data.append("function", "removeVehicle");
                data.append("numberPlate", this.numberPlate);
                data.append("saccoID", sessionStorage.getItem('saccoID'));
                execute(data,parking)
                    .then((res) =>{
                        if (res.data.success) {
                           this.getSaccoVehiclesStatus()
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            downloadSaccoVehiclesStatus(){
                this.download ="Please wait.."
                const data = new FormData();
                data.append("function", "downloadSaccoVehiclesStatus");
                data.append("compliance_status", this.complianceStatus);
                data.append("saccoID", sessionStorage.getItem('saccoID'));
                data.append("keyword", this.search);
                executeDownload(data,parking).then(response => {
                    this.download ="Download Report"

                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(response.data);
                    link.download = this.saccoName+"Report.csv";

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            },
            generateBill(){

                this.paymentMethod = false;
                this.paymentPanel = true;
                this.transactionSummary = false ;
                this.transactionCompleted = false
                this.message ="Generating Bill...please wait... "
                const data = new FormData();
                data.append("function", "generateBill3");
                data.append("billItem", JSON.stringify(this.selectedItems));
                execute(data,biller)
                    .then((res) =>{
                        this.message =res.data.message
                        if (res.data.success) {
                            this.billDetails = res.data.data.bill.billDetails
                            this.billInfo = res.data.data.bill.billInfo
                            this.printBill()
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            printBill() {
                localStorage['params'] = JSON.stringify({
                    billNo: this.billDetails.billNo
                })
                const routeData = this.$router.resolve({name: 'bill'});
                window.open(routeData.href, '_blank');
            },

        }
    }
</script>

<style scoped>

</style>