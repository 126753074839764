<template>

<head>

    <meta charset="utf-8" />
    <title>Desk Birdy | Parking Quick Stats</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
        type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->

    <!-- owl.carousel css -->
    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

</head>

<body data-sidebar="dark">

    <!-- Begin page -->
    <div id="layout-wrapper">


        <NavigationBar/>

        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
         <div id="content" class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row ">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">New Sacco/Sub Group</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Parking</a></li>
                                        <li class="breadcrumb-item"><a href="parking-fleet.html">Fleet Manager</a></li>
                                        <li class="breadcrumb-item"><a href="#">New Sacco/Sub Group</a></li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                    <!-- tool bar -->

                    <!-- end of toolbar -->
                    <div class="row">

                        <div class="col-12 px-sm-30px">
                            <div class="card">
                                <div class="card-header">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h4 class="card-title mb-0 pb-0">New sacco/Sub Group Registration</h4>
                                        <div class="d-none">
                                            <button data-toggle="modal" data-target="#new-category" class="btn btn-primary waves-effect waves-light"><i class="bx bx-list-plus font-size-16 align-middle me-2"></i> Add New Category</button>
                                        </div>
                                    </div>
                                </div>


                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 mb-3">
                                            <div class="form-group">
                                                <label for="">Sacco/Sub Group Name</label>
                                                <input v-model="sacco.saccoName" type="text" placeholder="Enter the Product Name" class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-md-3 col-sm-12 mb-3">


                                            <div class="form-group">
                                                <label for="subcounty">Sub-County</label>

                                                <select v-model="subCounty" class="form-control  bg-white" title="Select Sub County" data-live-search="true" >
                                                    <option v-for="(item, index) in subCounties" :value="item" :key="index">
                                                        {{ item.subCountyName }}
                                                    </option>

                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-3 col-sm-12 mb-3">
                                            <div class="form-group">
                                                <label for="ward">Ward</label>

                                                <select class="form-control show-tick" data-live-search="true" v-model="ward">
                                                    <option v-for="(item, index) in wards" :value="item" :key="index">
                                                        {{ item.wardName }}
                                                    </option>
                                                </select>

                                            </div>
                                        </div>

                                        <div class="col-md-3 col-sm-12 mb-3">
                                            <div class="form-group">
                                                <label for="ward">Zone</label>

                                                <select class="form-control show-tick" data-live-search="true" v-model="zone">
                                                    <option v-for="(item, index) in zones" :value="item" :key="index">
                                                        {{ item.zone }}
                                                    </option>
                                                </select>

                                            </div>
                                        </div>

                                        <div class="col-md-3 col-sm-12 mb-3">
                                            <div class="form-group">
                                                <label for="">Physical Address</label>
                                                <input v-model="sacco.physicalAddress" type="text" placeholder="Enter Address" class="form-control">
                                            </div>
                                        </div>



                                        <div class="col-12 mb-3">
                                            <div class="form-group">
                                                <hr>
                                                <label for="" class="fw-semibold text-primary text-uppercase">Contact Person's Information</label>

                                            </div>
                                        </div>

                                        <div class="col-md-3 col-sm-6 mb-3">
                                            <div class="form-group">
                                                <label for="">Full Names</label>
                                                <input v-model="sacco.fullNames" type="text" placeholder="Enter Name" class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-md-3 col-sm-6 mb-3">
                                            <div class="form-group">
                                                <label for="">Role</label>
                                                <select v-model="sacco.role" class="show-tick form-control" data-live-search="true" title="Select Role" data-style="form-select btn border" id="ward">
                                                    <option value="CEO">CEO</option>
                                                    <option value="Manager">Manager</option>
                                                    <option value="Sales Representative">Secretary</option>

                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-3 col-lg-3 mb-3">
                                            <div class="form-group">
                                                <label for="">Phone NO.</label>
                                                <input v-model="sacco.phoneNumber" type="text" placeholder="Enter Phone Number" class="form-control">
                                            </div>
                                        </div>

                                        <div class="py-1 mt-3">
                                            <label for="">Select Option</label>
                                            <select @change="selectedIncomeType($event)"  title="-- Income Type --" class="form-control show-tick" data-live-search="true" v-model="incomeType">
                                                <option v-for="(item, index) in incomeTypes" :value="JSON.stringify(item)" :key="index">
                                                    {{ item.incomeTypeDescription }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="col-12">
                                            <div class="bg-warning border-2 bg-soft p-4 mb-4 d-flex flex-column mt-3">
                                               <div class="d-flex justify-content-between align-items-center">
                                                    <h5 class="fw-semibold mb-0">Add Vehicles</h5>
                                                    <div>
                                                        <input class="form-control" type="file" id="formFile" placeholder="Upload File">
                                                    </div>
                                               </div>
                                                <p class="mb-2 d-none">Enter details of member vehicles to the new Sacco/Sub Group</p>

                                            </div>
                                        </div>



                                        <div class="col-12">
                                            <div class="">
                                                <table class="table table-editable-1 align-middle table-edits">
                                                    <thead class="table-light">
                                                    <tr class="text-uppercase table-dark">
                                                        <th>#</th>
                                                        <th>Vehicle</th>
                                                       <th class="">Quantity</th>
                                                        <th class="">Category</th>
                                                        <th class="">Owner</th>
                                                        <th>Phone No.</th>
                                                        <th></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr  v-for="(item,index) in sacco.vehicles" :key="index" >
                                                        <td style="width: 80px " class="categoryIndex ">{{index+1}}</td>

                                                        <td class="">
                                                            <input v-model="item.numberPlate" type="text " class="form-control " placeholder="Enter Plate No." spellcheck="false" data-ms-editor="true">
                                                        </td>

                                                      <td class="">
                                                        <input v-model="item.quantity" type="text " class="form-control " placeholder="Quantity(n)" spellcheck="false" data-ms-editor="true">
                                                      </td>

                                                        <td>

                                                          <select v-model="item.category" class="form-control bg-white" title="Select Traffic offence" data-live-search="true" >
                                                            <option v-for="(item2, index) in feesAndCharges" :value="item2.feeId+':'+ item2.feeDescription+':'+ item2.unitFeeAmount" :key="index">
                                                              {{ item2.feeDescription }} KES {{totalAmount(item2.unitFeeAmount,item.quantity)}}
                                                            </option>
                                                          </select>

                                                        </td>
                                                        <td class="">
                                                            <input v-model="item.owner" type="text " class="form-control " placeholder="Owner's Name" spellcheck="false" data-ms-editor="true">
                                                        </td>
                                                        <td class="">
                                                            <input v-model="item.phoneNumber" type="text " class="form-control " placeholder="Owner's Phone No." spellcheck="false" data-ms-editor="true">
                                                        </td>


                                                        <td @click="removeVehicle(index)" class="text-right cell-change d-flex align-items-center justify-content-end">
                                                            <a class="btn btn-light btn-rounded waves-effect btn-circle btn-transparent cancel-new-category-2 " title="Cancel "><i class="bx bx-x "></i></a>
                                                        </td>
                                                    </tr>

                                                    </tbody>
                                                    <tfoot>
                                                    <tr>
                                                        <td @click="addVehicle()" colspan="7" class="bg-light add-field-1 cursor-pointer">
                                                            <span class="d-flex align-items-center "><i class="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i><span class="pl-5 ">Add A Vehicle</span></span>
                                                        </td>
                                                    </tr>
                                                    </tfoot>
                                                </table>
                                            </div>


                                        </div>

                                        <div v-if="alert.success" class="alert alert-success" role="alert">
                                            {{message}}
                                        </div>
                                        <div v-if="alert.info" class="alert alert-primary" role="alert">
                                            {{message}}
                                        </div>
                                        <div v-if="alert.failed" class="alert alert-danger" role="alert">
                                            {{message}}
                                        </div>

                                        <div class="col-12 d-flex justify-content-end pt-4">
                                            <button type="button" class="btn btn-outline-dark waves-effect waves-light mr-3 d-none">
                                                <i class="bx bx-x font-size-16 align-middle me-2"></i> Clear Form
                                            </button>

                                            <button v-if="getRight('CREATE SACCO')" @click="addSacco()" type="button" class="btn btn-primary waves-effect waves-light w-100">
                                                <i class="bx bx-check-double font-size-16 align-middle me-2"></i> Add Sacco/Sub Group
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-full modal-fullscreen">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Business Details</h5>
                            <span
                                class="badge rounded-pill bg-success text-uppercase font-12px text-uppercase mx-3">Active
                                Certificate</span>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="hori-timeline mt-4">
                                        <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel"
                                            id="timeline-carousel">
                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                                                        <h5 class="mb-4">Application</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                            class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">Kelvin Omondi</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">15 Sep 2023 12:59 AM</div>
                                                        <h5 class="mb-4">Details Verification</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                            class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By Kelvin Ouma</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">20 Sep 2023 08:23 AM</div>
                                                        <h5 class="mb-4">Premices Inspection</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                            class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By Alex Kinoti</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">23 sep 2023 08:23 AM</div>
                                                        <h5 class="mb-4">Approval Of Application</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                            class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By John King</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">23 sep 2023 08:25 AM</div>
                                                        <h5 class="mb-4">issueance Of Certifcate</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                            class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By John King</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list active">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">By 31 Dec 2023 12:00 AM</div>
                                                        <h5 class="mb-4">Renewal</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                            class="bx bx-down-arrow-circle h1 text-info down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">-</a></p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-success border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-certification font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Certificate details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                    class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Serial
                                                                    No.</p>
                                                            </td>
                                                            <td class="">12345</td>

                                                        </tr>

                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Last Paid for</p>
                                                            </td>
                                                            <td class="">12 Jan 2023</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Expiry
                                                                    Date</p>

                                                            </td>
                                                            <td class="">31 Dec 2023</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Days
                                                                    to expiry</p>

                                                            </td>
                                                            <td class="">204 Days</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Printing status</p>

                                                            </td>
                                                            <td class="">Printed</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Amount
                                                                    Paid</p>

                                                            </td>
                                                            <td class="text-uppercase text-black fw-semibold">KES 25,000
                                                            </td>

                                                        </tr>




                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-briefcase-alt-2 font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Business Details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                    class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Business
                                                                    Name</p>
                                                            </td>
                                                            <td class="">Wireless Electronics</td>

                                                        </tr>

                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Certificate of Incoporation No. </p>
                                                            </td>
                                                            <td class="">1234557768</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">KRA
                                                                    Pin
                                                                    No.</p>

                                                            </td>
                                                            <td class="">12345</td>

                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">VAT
                                                                    No.
                                                                </p>

                                                            </td>
                                                            <td class="">12345</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Business
                                                                    Activity</p>

                                                            </td>
                                                            <td class="">Accomodation and Catering</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Business
                                                                    Sub category</p>

                                                            </td>
                                                            <td class="">Small Lodging House</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-warning border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bxs-user-pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Owner Details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                    class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Owner's Full Names</p>
                                                            </td>
                                                            <td class="">Mr Alex Wanjala Akinyi</td>

                                                        </tr>

                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    ID/PPT No.</p>
                                                            </td>
                                                            <td class="">1234557768</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Owner's Fax</p>

                                                            </td>
                                                            <td class="">12345</td>

                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Owner's Mobile No.</p>

                                                            </td>
                                                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Alternative Mobile No.</p>

                                                            </td>
                                                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                        </tr>



                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Postal
                                                                    Address</p>

                                                            </td>
                                                            <td class="">12-1004 Nairobi</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-phone-call pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Location and Contact information</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                    class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Business Mobile No.</p>
                                                            </td>
                                                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                        </tr>

                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Alternative Phone No</p>
                                                            </td>
                                                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                        </tr>



                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Business Email address</p>
                                                            </td>
                                                            <td class=""><a
                                                                    href="mailto:email@email.com">email@email.com</a>
                                                            </td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold"> Sub
                                                                    county</p>

                                                            </td>
                                                            <td class="">Homabay Town Sub county</td>

                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Ward
                                                                </p>

                                                            </td>
                                                            <td class=""><a href="tel:0704549859">Rodi Township Ward</a>
                                                            </td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Physical address/Street</p>

                                                            </td>
                                                            <td class=""><a href="tel:0704549859">Along Moi Avenue
                                                                    opposite cooperative Bank</a></td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Building Name</p>

                                                            </td>
                                                            <td class="">ALexis complex</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Stall/Room No.</p>

                                                            </td>
                                                            <td class="">122</td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-8">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-dark border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-map font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Map View</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div>
                                                <iframe
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46449.71551863634!2d36.67523836781685!3d-1.3353441122372378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f0cb9ff59a4a3%3A0x4073419e11826405!2sOrbital%20Africa%20%7C%20Land%20%26%20Topo%20Survey%2C%20GPS%2C%20GPR%2C%20GIS%2C%20Mapping%2C%20Drones%2C%20Training%20%26%20Geospatial%20Solutions!5e0!3m2!1sen!2ske!4v1691859398886!5m2!1sen!2ske"
                                                    width="100%" height="450" style="border:0;" allowfullscreen=""
                                                    loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">

                            <a href="PrintDocs/Trade-Permit/permit.html" target="_blank" type="button"
                                class="btn btn-primary waves-effect waves-light">
                                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Certificate
                            </a>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Invoice Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12">
                                <address>
                                    <strong>Billed To:</strong><br>
                                    Kelvin Njuguna<br>
                                    email@mail.com, 0704 549 859<br>
                                    Hse No. 410, 90 Degrees By Tsavo
                                    <br><br>
                                    1 Mar 2022, 10:20 AM
                                </address>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Invoice Details ( <span
                                            class="text-primary fw-medium">No. 1237</span> )</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead>
                                            <tr>
                                                <th style="width: 70px;">No.</th>
                                                <th>Item</th>
                                                <th class="text-end">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>Rent Deposit</td>
                                                <td class="text-end">KES 24,500</td>
                                            </tr>

                                            <tr>
                                                <td>02</td>
                                                <td>Monthly Rent</td>
                                                <td class="text-end">KES 12,000</td>
                                            </tr>

                                            <tr>
                                                <td>03</td>
                                                <td>TAX (18%)</td>
                                                <td class="text-end">KES 250</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Total</td>
                                                <td class="text-end fw-bold">KES 36,750</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Paid</td>
                                                <td class="text-end  fw-bold">KES 0.00</td>
                                            </tr>

                                            <tr>
                                                <td colspan="2" class="border-0 text-end">
                                                    <strong>Balance</strong>
                                                </td>
                                                <td class="border-0 text-end">
                                                    <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                            <tr data-id="1">
                                                <td>
                                                    <label for="" class="">Payment Method</label>
                                                    <select class="form-control selectpicker w-100 payment-method"
                                                        data-style="btn-secondary w-100" data-live-search="true"
                                                        title="Select payment Method">
                                                        <option value="Mpesa">MPESA</option>
                                                        <option value="Cash">CASH</option>
                                                    </select>
                                                </td>
                                                <td class="px-3 ">
                                                    <div class="phone-num d-none">
                                                        <label for="">Phone No.</label>
                                                        <input type="text " class="form-control w-100 d-flex "
                                                            placeholder="Phone No." spellcheck="false"
                                                            data-ms-editor="true">
                                                    </div>

                                                </td>
                                                <td class="px-3">
                                                    <label for="">Amount To Be Paid</label>
                                                    <input type="text " class="form-control w-100 d-flex"
                                                        placeholder="KES" spellcheck="false" data-ms-editor="true">

                                                </td>
                                                <td class="text-right float-right">
                                                    <div class="d-flex flex-column">
                                                        <label class="opacity-0">Something</label>
                                                        <a href="#"
                                                            class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                                                    </div>
                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a href="javascript:window.print()"
                                    class="btn btn-success waves-effect waves-light me-1"><i
                                        class="mdi mdi-printer font-16px"></i></a>
                                <a href="javascript: void(0);"
                                    class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
                            ©County Rev
    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
            County Government
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>

    </body>
</template>

<script>
    import router from "@/router";
    import {biller, parking, execute, getRight, authUrl} from "@/api";
    import NavigationBar from "@/components/Navigation";

    export default {
        name: "ParkingFleetNewSacco",
        components: {NavigationBar},
        data(){
            return{
                zone:'',
                zones: [],
                alert:{
                    success: false,
                    info: false,
                    failed:false
                },
                incomeType:{
                    incomeTypeId: null,
                    incomeTypeDescription:null,
                    incomeTypePrefix: null,
                    fundDescription: null,
                    status: null
                },
                feesAndCharges:[{
                    feeId: null,
                    feeDescription: null,
                    unitOfMeasure: null,
                    unitFeeAmount: null,
                    accountNo: null,
                    incomeTypeId: null,
                    feeType: null,
                    accountDesc:null ,
                    costCenterNo:null,
                    zone: null,
                    typeDescription: null
                }],
                incomeTypes:[{
                    incomeTypeId: null,
                    incomeTypeDescription:null,
                    incomeTypePrefix: null,
                    fundDescription: null,
                    status: null}],
                message: null,
                download:'Download Report',
                loading: false,
                dateFrom:'',
                dateTo:'',
                sacco:{
                    saccoName: null,
                    physicalAddress: null,
                    fullNames: null,
                    role: null,
                    phoneNumber: null,
                    subCounty:{
                        subCountyID: null,
                        subCountyName: null
                    },
                    ward:{
                        wardID: null,
                        wardName: null
                    },
                    zone:null,
                    vehicles:[{
                        numberPlate: null,
                        category: null,
                        owner: null,
                        phoneNumber: null,
                        quantity: null

                    }],

                },
                subCounties:[{
                    subCountyID: "",
                    subCountyName: ""
                }],
                wards:[{
                    wardID: "",
                    wardName: ""
                }],
                subCounty:{},
                ward:{}

            }
        },
        computed:{
            totalReceiptAmount() {
                return this.formatPrice(this.receiptDetails.reduce((sum, billDetail) => sum + parseInt(billDetail.receiptAmount), 0));
            }
        },
        watch:{
            subCounty: function () {
                //Trade demographics
                this.sacco.subCounty = this.subCounty
                this.getWards()
            }, ward: function () {
                //Trade demographics
                this.sacco.ward = this.ward
                this.getZones(this.ward.wardID)
            },
            zone: function () {
                this.sacco.zone = this.zone
            }
        },
        mounted() {
            this.getSubCounties()
            this.getIncomeTypes()
        },
        methods: {
            getRight(type){
                return getRight(type)
            },
            addVehicle(){
                this.sacco.vehicles.push({})
            },
            removeVehicle(position){

                this.sacco.vehicles.splice(position,1)
            },

            selectedIncomeType(event){
                const value = event.target.value
                const result = JSON.parse(value);
                this.getFeesAndChargesBilling(result.incomeTypeId)
            },
            getIncomeTypes(){
                const data = new FormData();
                data.append("function", "getIncomeTypes");
                data.append("incomeTypePrefix", "PKN");
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.incomeTypes =res.data.data.incomeTypes
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getFeesAndChargesBilling(incomeTypeId){
                const data = new FormData();
                data.append("function", "getFeesAndCharges");
                data.append("incomeTypeId",incomeTypeId);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.feesAndCharges = res.data.data.feesAndCharges
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },

            addSacco(){
                this.message ="Please wait..."
                this.alert.failed = false
                this.alert.info = true
                const data = new FormData();
                data.append("function", "addSacco");
                data.append("sacco", JSON.stringify(this.sacco));
                execute(data,parking)
                    .then((res) =>{
                        if (res.data.success) {
                            this.message =res.data.message
                            this.alert.success = true
                            this.gotTo('parking-fleet')
                        }else{
                            this.alert.failed = true
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
          totalAmount(amount, months) {
            // Convert amount and months to numbers before multiplying
            amount = Number(amount);
            months = Number(months);
            // Return the result of multiplication
            return amount * months;
          },

            //#########
            getSubCounties(){
                const data = new FormData();
                data.append("function", "getSubCounty");
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.subCounties =res.data.data.subCounties
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getWards(){
                this.wards =[]
                const data = new FormData();
                data.append("function", "getWards");
                data.append("subCountyID", this.subCounty.subCountyID);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.wards =res.data.data.wards
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getZones(wardID){

                this.zones.splice(0)
                const data = new FormData();
                data.append("function", "getZones");
                data.append("wardID", wardID);
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.zones = res.data.data.zones
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getCurrentMonth(){
                const d = new Date();
                const monthName = this.monthNames[d.getMonth()];
                console.log(monthName);
                return monthName;
            },
            getMonthName(){
                const monthNames = ['January','February','March','April','May','June','July','August','September','October','November','December'];
                const d = new Date();
                const monthName = monthNames[d.getMonth()];
                console.log(monthName);
                return monthName;
            },
            getCurrentYear(){
                const date = new Date();
                const currentYear = date.getFullYear();
                console.log(currentYear); // Output: 2023
                return currentYear;

            },
            formatPrice(value) {
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'KES',
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            },
            gotTo(route){
                router.push(route)
            },
        }
    }
</script>

<style scoped>

</style>