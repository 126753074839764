<template>

    <head>

        <meta charset="utf-8" />
        <title>Desk Birdy | Business Permits</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta content="Rental management system by Nouveta LTD" name="description" />
        <meta content="Kelvin Njuguna" name="author" />
        <!-- App favicon -->
        <link rel="shortcut icon" href="assets/images/favicon.svg">
        <!-- droping file -->
        <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

        <!-- DataTables -->
        <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
        <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
              type="text/css" />

        <!-- Bootstrap Css -->
        <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
        <!-- Bootstrap select styling -->
        <link rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
        <!-- Icons Css -->
        <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
        <!-- App Css-->

        <!-- owl.carousel css -->
        <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

        <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

        <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
        <!-- custom Css-->
        <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

    </head>

    <body data-sidebar="dark">


    <!-- Begin page -->
    <div id="layout-wrapper">

        <NavigationBar/>
        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">System Users Live Monitoring</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Live Monitoring</a></li>
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Inactive</a></li>

                                    </ol>
                                </div>




                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                    <!-- tool bar -->

                    <div class="row">
                        <div class="col-12 active">
                            <div class="card active">
                                <div class="card-body mini-nav bg-primary pt-2 pb-3 active ">
                                    <nav class="navbar navbar-expand-md navbar navbar-dark bg-primary py-2 active">
                                        <button
                                                class="navbar-toggler btn btn-sm px-3 font-size-16 header-item waves-effect h-auto text-primary"
                                                type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                                                aria-controls="navbarNavAltMarkup" aria-expanded="false"
                                                aria-label="Toggle navigation">
                                            <span class="mdi mdi-menu"></span>
                                        </button>
                                        <div class="collapse navbar-collapse justify-content-between active"
                                             id="navbarNavAltMarkup">

                                            <div class="navbar-nav">
                                                <a  @click="gotTo('live_monitoring')"  class="nav-item nav-link" href="javascript: void(0);">All Users ({{numberFormat(performanceSummary.allUsers)}})</a>
                                                <a  @click="gotTo('active_users')" class="nav-item nav-link" href="javascript: void(0);">Active Users ({{numberFormat(performanceSummary.active)}})</a>
                                                <a @click="gotTo('offline_users')" class="nav-item nav-link active" href="javascript: void(0);">Inactive ({{numberFormat(performanceSummary.offline)}})</a>
                                                <a @click="gotTo('dormant_users')" class="nav-item nav-link" href="javascript: void(0);">Dormant Users ({{numberFormat(performanceSummary.dormant)}})</a>
                                                <a @click="gotTo('never_logged_in')" class="nav-item nav-link" href="javascript: void(0);">Never Logged In ({{numberFormat(performanceSummary.neverLoggedIn)}})</a>

                                            </div>

                                            <form class="form-inline mx-3 my-lg-0 d-flex text-capitalize">

                                                <select class="form-select" v-model="performanceVerdict">
                                                    <option value="">All target Classifications</option>
                                                    <option value="Underperforming">Underperforming (Below 80%)</option>
                                                    <option value="Below Target">Below Target (80%-90%)</option>
                                                    <option value="Performing">Performing (91%-100%)</option>
                                                    <option value="Above Target">Above Target (Above 100%)</option>
                                                    <option value="Zero Collections">Zero Collectors</option>
                                                </select>

                                                <select class="form-select mx-2" v-model="selectedCategory">
                                                    <option value="" selected>All User Categories</option> <!-- Move this option to the top -->
                                                    <option v-for="(item, index) in categories" :key="`${index}`">
                                                        <a class="dropdown-item" href="#">{{ item.category }}</a>
                                                    </option>
                                                </select>

                                                <select class="form-select" id="country"  v-model="subCountyID">
                                                    <option value="" selected>All Sub Counties</option>
                                                    <option v-for="(item, index) in subCounties" :key="`${index}`" :value="item.subCountyID"><a class="dropdown-item" href="#">{{item.subCountyName}}</a></option>
                                                </select>


                                            </form>


                                        </div>
                                    </nav>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- end of toolbar -->

                    <!-- revenue team highlights -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="d-flex">
                                                <div class="flex-grow-1 align-self-center">
                                                    <div class="text-muted">
                                                        <h5 class="mb-1">All Revenue Collectors</h5>
                                                        <p class="mb-0">Revenue performance for the period {{formatDate(dateFrom)}} to {{formatDate(dateTo)}}</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-8 align-self-center text-uppercase">
                                            <div class="text-lg-center mt-4 mt-lg-0">
                                                <div class="row">
                                                    <div class="col-2">
                                                        <div>
                                                            <p class="text-muted text-truncate mb-2">Zero Collectors</p>
                                                            <h5 class="mb-0">{{numberFormat(performanceSummary.zeroCollections)}}</h5>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div>
                                                            <p class="text-muted text-truncate mb-2">Underperforming</p>
                                                            <h5 class="mb-0">{{numberFormat(performanceSummary.underperforming)}}</h5>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div>
                                                            <p class="text-muted text-truncate mb-2">Performing</p>
                                                            <h5 class="mb-0">{{numberFormat(performanceSummary.performing)}}</h5>

                                                        </div>
                                                    </div>

                                                    <div class="col-2">
                                                        <div>
                                                            <p class="text-muted text-truncate mb-2">Above Target</p>
                                                            <h5 class="mb-0">{{numberFormat(performanceSummary.aboveTarget)}}</h5>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <!-- end row -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end of highlights -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                    <div class="flex-grow-1">
                                        <div class="search-box mb-0 me-3">
                                            <div class="position-relative">
                                                <input v-model="search" type="text" class="form-control bg-light border-light rounded" placeholder="Search..." fdprocessedid="husj3l" spellcheck="false" data-ms-editor="true">
                                                <i class="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex">
                                        <div class="d-flex align-items-center me-3 pe-3">
                                            <span class="pr-3 fw-semibold">From:</span>
                                            <input v-model="dateFrom" class="form-control" placeholder="Start date" type="date">
                                            <span class="px-3 fw-semibold">TO:</span>
                                            <input v-model="dateTo" class="form-control" placeholder="Start date" type="date">
                                        </div>
                                        <button @click="downloadUsersMonitoring"  type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                            <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">{{download}}</span>
                                        </button>
                                    </div>

                                </div>
                                <div v-if="message!=null" class="alert alert-primary" role="alert">
                                    {{message}}
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive ">

                                        <table class="table align-middle table-hover  contacts-table table-striped "
                                               id="">
                                            <thead class="table-light">
                                            <tr class="table-dark">
                                                <th scope="col">#</th>
                                                <th scope="col">User</th>
                                                <th scope="col">Phone No.</th>
                                                <th>Allocated Zone</th>
                                                <th>Status</th>
                                                <th>Idle Time</th>
                                                <th scope="col">Log ins</th>
                                                <th scope="col">Transactions</th>
                                                <th scope="col">Inspections</th>

                                                <th scope="col" class="text-right">Inspections Value</th>
                                                <th class="text-right">Target</th>
                                                <th>Target Rating</th>
                                                <th scope="col" class="text-right">Collections value</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(item, index) in users" :value="item" :key="index">
                                                <td class="text-capitalize text-nowrap">{{index+1}}.</td>
                                                <td @click="gotTo2('user-collections',item.idNo,item.names)" style="cursor: pointer;" class="text-capitalize text-nowrap">
                                                    <p class="mb-0">{{item.names}}</p>
                                                    <small>{{item.category}}</small>
                                                </td>
                                                <td class="text-capitalize text-nowrap">
                                                    <a href="tel:0704 548 859">0704 548 859</a>
                                                </td>
                                                <td>{{item.subCountyName}}, {{item.wardName}}, {{item.zone}}</td>
                                                <td>
                                                    <span v-if="item.userStatus === 'Active'" class="text-uppercase badge badge-soft-success">{{ item.userStatus }}</span>
                                                    <span v-else-if="item.userStatus === 'Inactive'" class="text-uppercase badge badge-soft-warning">{{ item.userStatus }}</span>
                                                    <span v-else-if="item.userStatus === 'Dormant'" class="text-uppercase badge badge-soft-danger">{{ item.userStatus }}</span>
                                                    <span v-else class="text-uppercase badge badge-soft-dark">{{ item.userStatus }}</span>

                                                </td>
                                                <td>{{item.idleMinutes}} Min</td>
                                                <td>{{item.logCount}}</td>
                                                <td>{{item.transactionCount}}</td>
                                                <td>{{item.inspection}}</td>

                                                <td class="text-right text-uppercase fw-semibold">KES {{numberFormat(item.inspectionValue)}}</td>
                                                <td class="text-right text-uppercase fw-semibold">KES {{numberFormat(item.target)}}</td>

                                                <td v-if="getVariance(item.performance)>100" class="table-success fw-bold" data-toggle="tooltip" data-placement="top" title="" data-bs-original-title="Above Target (collectuons above 100%)">
                                                    {{getVariance(item.performance)}}%
                                                    <span class="badge rounded-pill bg-success float-end text-uppercase">Above Target</span>
                                                </td>

                                                <td v-if="getVariance(item.performance)>=90 & getVariance(item.performance)<=100" class="table-info fw-bold" data-toggle="tooltip" data-placement="top" title="" data-bs-original-title="Performing (Collections that are 90%-100% of the target)">
                                                    {{getVariance(item.performance)}}%

                                                    <span class="badge rounded-pill bg-info float-end text-uppercase">Performing</span>
                                                </td>

                                                <td v-if="getVariance(item.performance)>=80 & getVariance(item.performance)<90" class="table-warning fw-bold" data-toggle="tooltip" data-placement="top" title="" data-bs-original-title="Below Target (Collections that are 80%-90% of the target)">
                                                    {{getVariance(item.performance)}}%

                                                    <span class="badge rounded-pill bg-warning float-end text-uppercase">Below Target</span>
                                                </td>

                                                <td v-if="getVariance(item.performance)<80" class="table-danger fw-bold" data-toggle="tooltip" data-placement="top" title="" data-bs-original-title="Underperforming (Collections that are 80% and below of the target)">
                                                    {{getVariance(item.performance)}}%

                                                    <span class="badge rounded-pill bg-danger float-end text-uppercase">Underperforming</span>
                                                </td>

                                                <td class="text-right text-uppercase fw-semibold">kes {{numberFormat(item.collectionValue)}}</td>
                                            </tr>

                                            </tbody>
                                            <tfoot class="table-dark">
                                            <tr>
                                                <th  colspan="5">{{users.length}} Users</th>
                                                <th>{{convertTime(idle_minutes)}}</th>
                                                <td>{{numberFormat(login_count)}}</td>
                                                <td>{{numberFormat(transaction_count)}}</td>
                                                <td>{{numberFormat(inspection)}}</td>
                                                <td class="text-right">{{numberFormat(inspectionValue)}}</td>

                                                <th class="text-right">KES {{numberFormat(dailyTargets)}}</th>
                                                <th class="text-right"></th>
                                                <th class="text-right">KES {{numberFormat(collection)}}</th>
                                            </tr>

                                            </tfoot>
                                        </table>

                                        <div v-if="loading" class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center">
                                            <div class="p-5 py-0 pt-3">
                                                <div class="p-5">
                                                    <div class="spinner-border text-info m-1" role="status">
                                                        <span class="sr-only text-uppercase">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 class="text-uppercase">Loading Data</h4>
                                            <div class="col-sm-12 col-md-8 col-lg-5">
                                                <p>We are fetching the data for you. It will be ready soon. Please wait and have fun. Thank you for your interest.</p>

                                            </div>
                                        </div>
                                        <div v-if="users.length<1 & loading===false" class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center">
                                            <div class="p-5 py-0 pt-3">
                                                <img :src="'./assets/images/no-results.png'" class="img mb-4"  alt="No search results"/>
                                            </div>
                                            <h4>No Results To Show</h4>
                                            <p>Sorry, we could not find any results that match your search or filter criteria. Please try using different keywords or filters.</p>
                                        </div>


                                    </div>
                                </div>

                                <div class="card-footer bg-white">
                                    <nav aria-label="Page navigation example " class="d-flex align-items-center justify-content-between">

                                        <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                                            <select class="form-select w-auto font-13px" v-model="pageSize">
                                                <option value="5">5 Rows</option>
                                                <option value="10">10 Rows</option>
                                                <option value="25">25 Rows</option>
                                                <option value="50">50 Rows</option>
                                                <option value="100">100 Rows</option>
                                                <option value="150">150 Rows</option>
                                                <option value="200">200 Rows</option>
                                                <option value="500">500 Rows</option>
                                                <option value="20000000">All Rows</option>
                                            </select>
                                        </div>

                                        <ul class="pagination">
                                            <!-- add a class of disabled and an attribute of disabled when the current page is the first page -->
                                            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                                <a class="page-link" tabindex="-1" @click="prevPage" :disabled="currentPage === 1">Previous</a>
                                            </li>
                                            <!-- replace the v-for directive with the pagination function call -->
                                            <li v-for="page in pagination(currentPage, totalPages, 3)" :key="page" class="page-item" :class="{ active: currentPage === page }">
                                                <!-- check if the page is an ellipsis and display it as plain text -->
                                                <span v-if="page === '...'" class="page-link">{{ page }}</span>
                                                <!-- otherwise, display it as a clickable link -->
                                                <a v-else class="page-link" @click="goToPage(page)">{{ page }}</a>
                                            </li>
                                            <!-- add a class of disabled and an attribute of disabled when the current page is the last page -->
                                            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                                <a class="page-link"  @click="nextPage" :disabled="currentPage === totalPages">Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-full modal-fullscreen">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Application Details</h5>
                            <span
                                    class="badge rounded-pill bg-danger text-uppercase font-12px text-uppercase mx-3">Suspended Application</span>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="hori-timeline mt-4">
                                        <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel"
                                             id="timeline-carousel">
                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                                                        <h5 class="mb-4">Application</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">Kelvin Omondi</a></p>
                                                        <p class="text-muted text-capitalize mb-1"><a href="PrintDocs/Receipts/receipts-HOMabay county.html"><i class="bx bx-receipt"></i> View Receipt</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list ">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary">25 Sep 2023 At 12:43 PM</div>
                                                        <h5 class="mb-4">Details Verification</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a href="user-details.html">Flex Amondi Abiro</a></p>
                                                        <p class="text-muted text-capitalize mb-1"><i class="bx bx-map"></i> At two rivers road</p>

                                                    </div>
                                                </div>
                                            </div>



                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">15 Sep 2023 03:56 PM</div>
                                                        <h5 class="mb-4">Inspection</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">Alex Oduor</a></p>
                                                        <p class="text-muted text-capitalize mb-1"><i class="bx bx-map"></i> At two rivers road</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list active">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">Pay Pending Amount</div>
                                                        <h5 class="mb-4">KES 5,000 Pending</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">Bill No. BLL378499HJK</a></p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>



                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-briefcase-alt-2 font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Business Details </p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <thead class="table-light">
                                                    <th colspan="3" class="text-right text-uppercase">Verification Status <br> <a href="user-details.html">(By Flex Amondi)</a></th>
                                                    </thead>
                                                    <tbody>
                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Name</p>
                                                        </td>
                                                        <td class="">Wireless Electronics</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Certificate of Incoporation No. </p>
                                                        </td>
                                                        <td class="">1234557768</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>


                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">KRA
                                                                Pin
                                                                No.</p>

                                                        </td>
                                                        <td class="">12345</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>


                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">VAT
                                                                No.
                                                            </p>

                                                        </td>
                                                        <td class="">12345</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>


                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Activity</p>

                                                        </td>
                                                        <td class="">Accomodation and Catering</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-danger fw-semibold">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Sub category</p>

                                                        </td>
                                                        <td class="">Small Lodging House</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-x text-danger font-28"></i>
                                                        </td>

                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <thead class="table-light">
                                                    <th colspan="3" class="">Corrections Needed/Done <br> <a href="user-details.html">(By Flex Amondi)</a></th>
                                                    </thead>
                                                    <tbody>


                                                    <tr class=" fw-semibold text-capitalize">
                                                        <td class="text-primary">
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Sub category</p>

                                                        </td>
                                                        <td class=""><span class="text-primary">change to:</span> large high standard lodging</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-x text-danger font-28"></i>
                                                        </td>

                                                    </tr>
                                                    <tr class=" fw-semibold text-capitalize">
                                                        <td class="text-primary">
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Additional Amount</p>

                                                        </td>
                                                        <td class=""><span class="text-primary"></span> <span class="text-primary">KES 12,000</span> (BILL NO. BLL12344jh)</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-x text-danger font-28"></i>
                                                        </td>

                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-warning border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bxs-user-pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Owner Details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <thead class="table-light">
                                                    <th colspan="3" class="text-right text-uppercase">Verification Status <br> <a href="user-details.html">(By Flex Amondi)</a></th>
                                                    </thead>
                                                    <tbody>
                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Full Names</p>
                                                        </td>
                                                        <td class="">Mr Alex Wanjala Akinyi</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                ID/PPT No.</p>
                                                        </td>
                                                        <td class="">1234557768</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Fax</p>

                                                        </td>
                                                        <td class="">12345</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Mobile No.</p>

                                                        </td>
                                                        <td class=""><a href="tel:0704549859">0704549859</a></td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Alternative Mobile No.</p>

                                                        </td>
                                                        <td class=""><a href="tel:0704549859">0704549859</a></td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>



                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Postal
                                                                Address</p>

                                                        </td>
                                                        <td class="">12-1004 Nairobi</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>
                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-phone-call pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Location and Contact information</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <thead class="table-light">
                                                    <th colspan="3" class="text-right text-uppercase">Verification Status <br> <a href="user-details.html">(By Flex Amondi)</a></th>
                                                    </thead>
                                                    <tbody>
                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business Mobile No.</p>
                                                        </td>
                                                        <td class=""><a href="tel:0704549859">0704549859</a></td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Alternative Phone No</p>
                                                        </td>
                                                        <td class=""><a href="tel:0704549859">0704549859</a></td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>



                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business Email address</p>
                                                        </td>
                                                        <td class=""><a
                                                                href="mailto:email@email.com">email@email.com</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold"> Sub
                                                                county</p>

                                                        </td>
                                                        <td class="">Homabay Town Sub county</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Ward
                                                            </p>

                                                        </td>
                                                        <td class=""><a href="tel:0704549859">Rodi Township Ward</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Physical address/Street</p>

                                                        </td>
                                                        <td class=""><a href="tel:0704549859">Along Moi Avenue
                                                            opposite cooperative Bank</a></td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Building Name</p>

                                                        </td>
                                                        <td class="">ALexis complex</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Stall/Room No.</p>

                                                        </td>
                                                        <td class="">122</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-8">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-dark border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-map font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Map View</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div>
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46449.71551863634!2d36.67523836781685!3d-1.3353441122372378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f0cb9ff59a4a3%3A0x4073419e11826405!2sOrbital%20Africa%20%7C%20Land%20%26%20Topo%20Survey%2C%20GPS%2C%20GPR%2C%20GIS%2C%20Mapping%2C%20Drones%2C%20Training%20%26%20Geospatial%20Solutions!5e0!3m2!1sen!2ske!4v1691859398886!5m2!1sen!2ske" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bxs-edit-alt pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Revenue Officer Notes (Flex Amondi)</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="alert alert-warning border-1 border-warning" role="alert">
                                                Everything looks OK
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">

                            <a href="PrintDocs/Trade-Permit/permit.html" target="_blank" type="button"
                               class="btn btn-primary waves-effect waves-light">
                                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Certificate
                            </a>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Invoice Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12">
                                <address>
                                    <strong>Billed To:</strong><br>
                                    Kelvin Njuguna<br>
                                    email@mail.com, 0704 549 859<br>
                                    Hse No. 410, 90 Degrees By Tsavo
                                    <br><br>
                                    1 Mar 2022, 10:20 AM
                                </address>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Invoice Details ( <span
                                            class="text-primary fw-medium">No. 1237</span> )</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead>
                                        <tr>
                                            <th style="width: 70px;">No.</th>
                                            <th>Item</th>
                                            <th class="text-end">Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>Rent Deposit</td>
                                            <td class="text-end">KES 24,500</td>
                                        </tr>

                                        <tr>
                                            <td>02</td>
                                            <td>Monthly Rent</td>
                                            <td class="text-end">KES 12,000</td>
                                        </tr>

                                        <tr>
                                            <td>03</td>
                                            <td>TAX (18%)</td>
                                            <td class="text-end">KES 250</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" class="text-end">Total</td>
                                            <td class="text-end fw-bold">KES 36,750</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" class="text-end">Paid</td>
                                            <td class="text-end  fw-bold">KES 0.00</td>
                                        </tr>

                                        <tr>
                                            <td colspan="2" class="border-0 text-end">
                                                <strong>Balance</strong>
                                            </td>
                                            <td class="border-0 text-end">
                                                <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                        <tr data-id="1">
                                            <td>
                                                <label for="" class="">Payment Method</label>
                                                <select class="form-control selectpicker w-100 payment-method"
                                                        data-style="btn-secondary w-100" data-live-search="true"
                                                        title="Select payment Method">
                                                    <option value="Mpesa">MPESA</option>
                                                    <option value="Cash">CASH</option>
                                                </select>
                                            </td>
                                            <td class="px-3 ">
                                                <div class="phone-num d-none">
                                                    <label for="">Phone No.</label>
                                                    <input type="text " class="form-control w-100 d-flex "
                                                           placeholder="Phone No." spellcheck="false"
                                                           data-ms-editor="true">
                                                </div>

                                            </td>
                                            <td class="px-3">
                                                <label for="">Amount To Be Paid</label>
                                                <input type="text " class="form-control w-100 d-flex"
                                                       placeholder="KES" spellcheck="false" data-ms-editor="true">

                                            </td>
                                            <td class="text-right float-right">
                                                <div class="d-flex flex-column">
                                                    <label class="opacity-0">Something</label>
                                                    <a href="#"
                                                       class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                                                </div>
                                            </td>


                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a href="javascript:window.print()"
                                   class="btn btn-success waves-effect waves-light me-1"><i
                                        class="mdi mdi-printer font-16px"></i></a>
                                <a href="javascript: void(0);"
                                   class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">

                        </div>
                        <div class="col-sm-6 ">
                            <div class="text-sm-end d-sm-block ">

                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>

    </body>

</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import {authUrl, biller, execute, executeDownload} from "@/api";
    import moment from "moment";
    import ToolBarLiveMonitoring from "@/components/livemonitoring/ToolBarLiveMonitoring";
    export default {
        name: "LiveMonitoring",
        components: {ToolBarLiveMonitoring, NavigationBar},
        data(){
            return{
                performanceVerdict:'',
                search:'',
                download:' Download Report',
                loading: false,
                // set the initial current page as 1
                currentPage: 1,
                // set the total number of pages as 10 (you can change this according to your data source)
                totalPages: 0,
                // set the number of items per page as 5 (you can change this according to your preference)
                pageSize: 10,

                dateTo:'',
                dateFrom:'',
                subCountyID: '',
                performanceSummary: {
                    underperforming: 0,
                    belowTarget: 0,
                    performing: 0,
                    aboveTarget: 0,
                    zeroCollections: 0,
                    active: 0,
                    offline: 0,
                    dormant: 0,
                    neverLoggedIn: 0,
                    allUsers: 0
                },
                users:[{
                    names: null,
                    phoneNumber: null,
                    idNo: null,
                    category: null,
                    zone: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardID: null,
                    wardName: null,
                    address: null,
                    status: null,
                    target: null,
                    dailyTargets: null,
                    login: null,
                    logTime: null,
                    userStatus: null,
                    idleMinutes: null,
                    logCount: null,
                    transactionCount: null,
                    inspection: null,
                    performance:null,
                    inspectionValue: null,
                    collectionValue: null,
                    performanceVerdict: null
                }],
                message:null,
                idle_minutes:0,
                transaction_count:0,
                inspection:0,
                inspectionValue:0,
                login_count:0,
                dailyTargets:0,
                collection:0,
                subCounties:[],
                selectedCategory:'',
                categories:[{
                    id: "",
                    category: ""
                }],

            }
        },
        watch: {
            //pagination code
            // define a watcher for the pageSize property
            pageSize(newPageSize) {
                this.getUsersMonitoring("")
                // get the total number of items from your data source (you can change this according to your logic)
                const totalItems = this.summary.count;
                // calculate the new total pages by dividing the total items by the new page size and rounding up
                const newTotalPages = Math.ceil(totalItems / newPageSize);
                // update the totalPages data property with the new value
                this.totalPages = newTotalPages;
                // reset the current page to 1
                this.currentPage = 1;

            }

        },
        computed: {
            //pagination code
            // calculate the start index of the items for the current page
            startIndex() {
                return (this.currentPage - 1) * this.pageSize;
            },
            // calculate the end index of the items for the current page
            endIndex() {
                return this.currentPage * this.pageSize;
            }
        },
        mounted() {

            this.dateFrom = moment().format('YYYY-MM-DD')
            this.dateTo = moment().format('YYYY-MM-DD')

            this.$watch('dateFrom', function (newValue) {
                if (this.dateFrom === '' || this.dateTo === '') {

                } else {
                    this.getUsersMonitoring()
                }

            })
            this.$watch('dateTo', function (newValue) {
                if (this.dateFrom === '' || this.dateTo === '') {

                } else {
                    this.getUsersMonitoring()
                }
            })

            this.$watch('selectedCategory', function (newValue) {
                this.getUsersMonitoring()

            })
            this.$watch('subCountyID', function (newValue) {
                this.getUsersMonitoring()

            })
            this.$watch('search', function (newValue) {
                this.getUsersMonitoring()

            })

            this.$watch('performanceVerdict', function (newValue) {
                this.getUsersMonitoring()

            })

            this.getCategories()
            this.getSubCounties()
            this.getUsersMonitoring()
        },
        methods:{
            //pagination code
            pagination(currentPage, totalPages, maxVisibleButtons) {
                //initialize an empty array for the pages
                let pages = [];
                //calculate the start and end indices of the visible buttons
                let start = currentPage - Math.floor(maxVisibleButtons / 2);
                let end = currentPage + Math.floor(maxVisibleButtons / 2);
                //adjust the start and end indices if they are out of bounds
                if (start < 1) {
                    start = 1;
                    end = start + maxVisibleButtons - 1;
                    if (end > totalPages) {
                        end = totalPages;
                    }
                }
                if (end > totalPages) {
                    end = totalPages;
                    start = end - maxVisibleButtons + 1;
                    if (start < 1) {
                        start = 1;
                    }
                }
                //loop through the start and end indices and push the pages to the array
                for (let i = start; i <= end; i++) {
                    pages.push(i);
                }
                //add an ellipsis at the beginning if there are more pages before the start index
                if (start > 1) {
                    pages.unshift("...");
                }
                //add an ellipsis at the end if there are more pages after the end index
                if (end < totalPages) {
                    pages.push("...");
                }
// return the array of pages
                return pages;
            },
            // define a method to go to the previous page
            prevPage() {
                // check if the current page is not the first page
                if (this.currentPage > 1) {
                    // decrement the current page by 1
                    this.currentPage--;
                    this.getUsersMonitoring("")
                }
            },
            // define a method to go to the next page
            nextPage() {
                // check if the current page is not the last page
                if (this.currentPage < this.totalPages) {
                    // increment the current page by 1
                    this.currentPage++;
                    this.getUsersMonitoring("")
                }
            },
            // define a method to go to a specific page
            goToPage(page) {
                // check if the page is valid and not equal to the current page
                if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
                    // set the current page to the given page
                    this.currentPage = page;
                    this.getUsersMonitoring("")
                }
            },

            //number format
            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },
            formatPrice(value) {
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'KES',
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            },
            convertTime(minutes) {
                const timeInHours = Math.floor(minutes / 60);
                const remainingMinutes = minutes % 60;

                const timeInDays = timeInHours / 24;
                const timeInWeeks = timeInDays / 7;
                const timeInMonths = timeInDays / 30; // Approximation, adjust as needed
                const timeInYears = timeInDays / 365; // Approximation, adjust as needed

                if (minutes < 60) {
                    return `${minutes} ${minutes !== 1 ? 'min' : 'minute'}`;
                } else if (timeInYears >= 1) {
                    return `${Math.floor(timeInYears)} year${timeInYears !== 1 ? 's' : ''}`;
                } else if (timeInMonths >= 1) {
                    return `${Math.floor(timeInMonths)} month${timeInMonths !== 1 ? 's' : ''}`;
                } else if (timeInWeeks >= 1) {
                    return `${Math.floor(timeInWeeks)} week${timeInWeeks !== 1 ? 's' : ''}`;
                } else if (timeInDays >= 1) {
                    return `${Math.floor(timeInDays)} day${timeInDays !== 1 ? 's' : ''}`;
                } else {
                    const hourString = timeInHours !== 1 ? 'hrs' : 'hr';
                    const minuteString = remainingMinutes !== 1 ? 'min' : 'minute';
                    return `${timeInHours} ${hourString} ${remainingMinutes} ${minuteString}`;
                }
            },
            getUsersMonitoring(){

                const startDate = new Date(this.dateFrom);
                const endDate = new Date(this.dateTo);
                // Calculate the difference in milliseconds
                const timeDifference = endDate.getTime() - startDate.getTime();

                // Calculate the difference in days
                const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
                if(dayDifference>1){
                    this.message ="Longer date range will take more time. If you patient you can wait or download it instead"
                }

                this.loading = true
                this.users.splice(0)
                const data = new FormData();
                data.append("function", "getUsersMonitoring");
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                data.append("dateFrom", this.dateFrom);
                data.append("dateTo", this.dateTo);
                data.append("category", this.selectedCategory);
                data.append("subCountyID", this.subCountyID);
                data.append("keyword", this.search);
                data.append("performanceVerdict", this.performanceVerdict);
                data.append("userStatus", "inactive");
                execute(data,authUrl)
                    .then((res) =>{
                        this.message = null
                        this.loading = false
                        if (res.data.success) {
                            this.users = res.data.data.users
                            this.performanceSummary = res.data.data.performanceSummary
                            this.idle_minutes = this.users.reduce((acc, cur) => {
                                let idle_minutes = Number(cur.idle_minutes);
                                if (!isNaN(idle_minutes)) {
                                    return acc + idle_minutes;
                                } else {
                                    return acc;
                                }
                            }, 0);
                            this.login_count = this.users.reduce((acc, cur) => {
                                let login_count = Number(cur.login_count);
                                if (!isNaN(login_count)) {
                                    return acc + login_count;
                                } else {
                                    return acc;
                                }
                            }, 0);
                            this.transaction_count = this.users.reduce((acc, cur) => {
                                let transaction_count = Number(cur.transaction_count);
                                if (!isNaN(transaction_count)) {
                                    return acc + transaction_count;
                                } else {
                                    return acc;
                                }
                            }, 0);
                            this.inspection = this.users.reduce((acc, cur) => {
                                let inspection = Number(cur.inspection);
                                if (!isNaN(inspection)) {
                                    return acc + inspection;
                                } else {
                                    return acc;
                                }
                            }, 0);
                            this.inspectionValue = this.users.reduce((acc, cur) => {
                                let inspectionValue = Number(cur.inspectionValue);
                                if (!isNaN(inspectionValue)) {
                                    return acc + inspectionValue;
                                } else {
                                    return acc;
                                }
                            }, 0);
                            this.dailyTargets = this.users.reduce((acc, cur) => {
                                let dailyTargets = Number(cur.target);
                                if (!isNaN(dailyTargets)) {
                                    return acc + dailyTargets;
                                } else {
                                    return acc;
                                }
                            }, 0);
                            this.collection = this.users.reduce((acc, cur) => {
                                let collection = Number(cur.collection);
                                if (!isNaN(collection)) {
                                    return acc + collection;
                                } else {
                                    return acc;
                                }
                            }, 0);

                            //todo pagination
                            this.totalItems = res.data.data.totalItems
                            this.totalPages =  Math.ceil(this.totalItems / this.pageSize);

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });

            },
            downloadUsersMonitoring(){
                this.download ="Please wait.."
                const data = new FormData();
                data.append("function", "downloadUsersMonitoring");
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                data.append("dateFrom", this.dateFrom);
                data.append("dateTo", this.dateTo);
                data.append("category", this.selectedCategory);
                data.append("subCountyID", this.subCountyID);
                data.append("keyword", this.search);
                data.append("performanceVerdict", this.performanceVerdict);
                data.append("userStatus", "inactive");
                executeDownload(data,authUrl).then(response => {
                    this.download ="Download Report"
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(response.data);
                    link.download = this.dateFrom+' to '+ this.dateTo+"Report.csv";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            },

            getVariance(performance) {
                // Convert performance to a number and round it off to the nearest whole number
                return Math.round(Number(performance));
            },
            gotTo(route){
                this.$router.push(route);
            },
            gotTo2(route,idNo,names){
                //idNoUser
                sessionStorage.setItem('idNoUser',idNo)
                sessionStorage.setItem('logsName',names)
                this.$router.push(route);
            },
            getSubCounties(){
                const data = new FormData();
                data.append("function", "getSubCounties");
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.subCounties = res.data.data.subCounties
                        }else{
                            this.message =res.data.message
                            this.alert.success = false
                            this.alert.failed = true
                            this.alert.info = false
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                        this.alert.success = false
                        this.alert.failed = true
                        this.alert.info = false
                    });
            },
            getCategories(){
                const data = new FormData();
                data.append("function", "getCategories");
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.categories = res.data.data.categories
                        }else{
                            this.message =res.data.message
                            this.alert.success = false
                            this.alert.failed = true
                            this.alert.info = false
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.alert.success = false
                        this.alert.failed = true
                        this.alert.info = false
                        this.message =e.message
                    });
            },
            formatDate(date) {
                let d = new Date(date);
                let day = d.getDate();
                let month = d.toLocaleString('en-US', { month: 'short' });
                let year = d.getFullYear();

                let suffix = '';
                if (day % 10 === 1 && day !== 11) {
                    suffix = 'st';
                } else if (day % 10 === 2 && day !== 12) {
                    suffix = 'nd';
                } else if (day % 10 === 3 && day !== 13) {
                    suffix = 'rd';
                } else {
                    suffix = 'th';
                }
                return `${day}${suffix} ${month} ${year}`;
            },
            getVarianceCategory(variance) {
                if (variance > 100) {
                    return 'Above Target';
                } else if (variance > 90) {
                    return 'Performing';
                } else if (variance > 80) {
                    return 'Below Target';
                } else {
                    return 'Underperforming';
                }
            }


        }
    }
</script>

<style scoped>

</style>