<template>

  <head>

    <meta charset="utf-8" />
    <title>NTSA | Keep Our Roads safe</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Keep your finances and membership up to date" name="description" />
    <meta content="Kelvin Njuguna Ndungu" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <link href="assets/libs/bootstrap-datepicker/css/bootstrap-datepicker.min.css" rel="stylesheet" type="text/css">
    <link href="assets/libs/spectrum-colorpicker2/spectrum.min.css" rel="stylesheet" type="text/css">
    <link href="assets/libs/bootstrap-timepicker/css/bootstrap-timepicker.min.css" rel="stylesheet" type="text/css">
    <link href="assets/libs/bootstrap-touchspin/jquery.bootstrap-touchspin.min.css" rel="stylesheet" type="text/css" />
    <link rel="stylesheet" href="assets/libs/%40chenfengyuan/datepicker/datepicker.min.css">

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />


  </head>

  <body data-sidebar="dark">

  <!-- Begin page -->
  <div id="layout-wrapper">

    <NavigationBar/>
    <div class="content-minimize">

      <div class="page-content">
        <div class="container-fluid">

          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">County Biller Auto Reconciliation</h4>

                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="javascript: void(0);">Dashboard</a></li>
                    <li class="breadcrumb-item active">Auto Reconciliation</li>
                  </ol>
                </div>

              </div>
            </div>
          </div>
          <!-- end page title -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center bg-white border-bottom">
                  <div>
                    <h4 class="card-title m-0 text-uppercase d-none">
                      Missing Transactions
                    </h4>
                  </div>
                  <div>
                    <!--                                        <button class="btn btn-success me-3" data-bs-toggle="modal" data-bs-target="#singleTrans"><i class="mdi mdi-briefcase-search font-size-16 align-middle me-2"></i> Single Transaction</button>-->
                    <button class="btn-dark btn" data-bs-toggle="modal" data-bs-target="#uploadFile" > <i class="mdi-file-document-multiple-outline mdi font-size-16 align-middle me-2"></i>  Upload File</button>
                  </div>
                </div>

                <div class="card-header d-flex justify-content-between align-items-center bg-white border-bottom">
                  <div>
                    <h4 class="card-title m-0 text-uppercase">
                      Reconciliations History
                    </h4>
                  </div>
                  <!--                                    <div class="d-flex">
                                                          <div class="d-flex align-items-center me-3 pe-3">
                                                              <span class="pr-3 fw-semibold">From:</span>
                                                              <input class="form-control" placeholder="Start date" type="date">
                                                              <span class="px-3 fw-semibold">TO:</span>
                                                              <input class="form-control" placeholder="Start date" type="date">
                                                          </div>
                                                          <button data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                                              <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                                                          </button>

                                                      </div>-->
                </div>

                <div class="card-body">

                  <div class="table-responsive">
                    <table class="table align-middle table-striped table-nowrap table-hover dt-responsive contacts-table" id=" ">
                      <thead class="table-dark">
                      <tr>
                        <th scope="col">ShorCode</th>
                        <th scope="col">From</th>
                        <th>To</th>
                        <th>Uploaded Records</th>
                        <th>Reconciled</th>
                        <th>
                         File
                        </th>
                        <th class="text-right">Uploaded On</th>
                        <th></th>
                      </tr>
                      </thead>

                      <tbody>
                      <tr  :key="index" :value="item" v-for="(item, index) in uploads">
                        <td>
                          {{item.shortCode}}
                        </td>
                        <th>
                          {{item.fromDate}}
                        </th>
                        <th>
                          {{item.toDate}}
                        </th>
                        <td>
                          <span class="badge rounded-pill bg-warning text-uppercase">  {{item.missingTransactions}}</span>
                        </td>
                        <td>
                          <span class="badge rounded-pill bg-success text-uppercase">  {{item.missingTransactions}}</span>
                        </td>
                        <td>
                          <span class="badge rounded-pill bg-info text-uppercase"> {{getFileName(item.file)}}</span>
                        </td>
                        <td class="text-right">{{item.dateCreated}}</td>

                      </tr>
                      </tbody>
                      <tfoot class="table-dark">
                      <tr>
                        <td class="" colspan="3">{{uploads.length}}</td>
                        <td class="text-uppercase"></td>
                        <td></td>
                        <td></td>
                        <td class="text-uppercase text-right"></td>
                        <td></td>
                      </tr>
                      </tfoot>
                    </table>

                    <div v-if="loading" class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center">
                      <div class="p-5 py-0 pt-3">
                        <div class="p-5">
                          <div class="spinner-border text-info m-1" role="status">
                            <span class="sr-only text-uppercase">Loading...</span>
                          </div>
                        </div>
                      </div>
                      <h4 class="text-uppercase">Loading Data</h4>
                      <div class="col-sm-12 col-md-8 col-lg-5">
                        <p>We are fetching the data for you. It will be ready soon. Please wait and have fun. Thank you for your interest.</p>

                      </div>
                    </div>
                    <div v-if="uploads.length<1 & loading === false" class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center">
                      <div class="p-5 py-0 pt-3">
                        <img :src="'./assets/images/no-results.png'" class="img mb-4"  alt="No search results"/>
                      </div>
                      <h4>No Results To Show</h4>
                      <p>Sorry, we could not find any results that match your search or filter criteria. Please try using different keywords or filters.</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- end col -->
          </div>

        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->


      <!-- bootstrap modal -->
      <div class="modal fade" id="uploadFile" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Upload Transactions File</h5>

              <button @click="closeModal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>

            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12">
                  <div>
                    <label class="form-label">Select File Source</label>
                    <select class="form-control  form-select" title="Select Revenue Stream" data-live-search="true">
                      <option value=" ">MPESA Transactions</option>
                      <!--                                        <option value=" ">KCB BANK Transactions</option>-->
                    </select>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mt-4">

                    <div class="fallback,dropzone">
                      <div>
                        <input
                            name="file"
                            type="file"
                            @change="handleFileChange($event)"
                        />
                        <div v-if="selectedFile">
                          <h3>Selected File:</h3>
                          <p>{{ selectedFile.name }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="dz-message needsclick">
                      <div class="mb-3">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                      </div>

                      <h4>Drop files here or click to upload.</h4>
                    </div>

                  </div>
                </div>

                <!-- show alerts here once transactions are uploaded -->

                <div v-if="message!=null" class="col-12">

                  <!--                                <div v-if="alert.failed" class="mt-2">
                                                      <div class="alert alert-danger" role="alert">
                                                          Upload Failed <a href="javascript: void(0);" class="alert-link">{{message}}</a>. Upload correct file format to continue.
                                                          </div>
                                                  </div>-->

                  <div  v-if="alert.success" class="mt-2">
                    <div class="alert alert-success" role="alert">
                      Reconciliations Completed <a href="javascript: void(0);" class="alert-link">{{message}}</a> <br>

                      <!--                                        <a href="recon-details.html" class="mt-3">View The Transactions</a>-->
                    </div>
                  </div>

                  <!--                                <div class="mt-2">
                                                      <div class="alert alert-secondary" role="alert">
                                                          Upload Completed <a href="javascript: void(0);" class="alert-link">The transactions were correctly reconciled initially</a>
                                                      </div>
                                                  </div>-->

                </div>

                <div class="col-12">
                  <button @click="uploadExcelToQue()" class="btn btn-primary w-100 mt-2">Continue</button>
                </div>

              </div>
            </div>

            <div v-if="loading" class="modal-body">
              <div class="modal-loader">
                <div class="d-flex justify-content-center align-items-center p-5">
                  <div class="spinner-border text-primary m-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- end of changing revenue stream modal -->
      <div class="modal fade" id="singleTrans" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Transaction Search</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12">
                  <div>
                    <label class="form-label">Select File Source</label>
                    <select class="form-control  form-select"  data-live-search="true" title="Select a tenant">
                      <option value=" ">MPESA Transactions</option>
                      <option value=" ">KCB BANK Transactions</option>
                    </select>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mt-3">
                    <label class="form-label">Enter Refrece code (MPESA Transaction Code)</label>
                    <input type="text" placeholder="Enter Transaction Code" class="form-control">
                  </div>
                </div>

                <!-- show alerts here once transactions are uploaded -->

                <div class="col-12">
                  <div class="mt-2">
                    <div class="alert alert-danger" role="alert">
                      <a href="javascript: void(0);" class="alert-link">Transaction Not Found.</a> Try again.
                    </div>
                  </div>

                  <div class="mt-2">
                    <div class="alert alert-success" role="alert">
                      <a href="javascript: void(0);" class="alert-link">Transaction succesfully Reconciled </a>
                    </div>
                  </div>

                  <div class="px-3 border-1">
                    <table class="table-sm table">
                      <tr>
                        <th>Transaction Code</th>
                        <td>RCTYIOS</td>
                      </tr>

                      <tr>
                        <th>Paid By</th>
                        <td>Kelvin Kinot</td>
                      </tr>

                      <tr>
                        <th>Phone No. </th>
                        <td>0704****59</td>
                      </tr>

                      <tr>
                        <th>Bill No.</th>
                        <td>BLL90808</td>
                      </tr>

                      <tr>
                        <th>Transaction Date</th>
                        <td>12 Dec 2023 11:38 PM</td>
                      </tr>

                      <tr>
                        <th>Sub County</th>
                        <td>Homa Bay town</td>
                      </tr>

                      <tr>
                        <th>Zone</th>
                        <td>Homa Bay Town Bus Park</td>
                      </tr>

                      <tr>
                        <th>Payment For</th>
                        <td>KBD123Y Daily Parking</td>
                      </tr>

                      <tr>
                        <th>Initiated By</th>
                        <td>Tom Otieno</td>
                      </tr>
                    </table>
                  </div>

                  <div class="mt-2">
                    <div class="alert alert-secondary" role="alert">
                      Failed <a href="javascript: void(0);" class="alert-link">Transactio had been reconciled initially</a>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <button class="btn btn-primary w-100 mt-2">Continue</button>
                </div>

              </div>
            </div>

            <div class="modal-body">
              <div class="modal-loader">
                <div class="d-flex justify-content-center align-items-center p-5">
                  <div class="spinner-border text-primary m-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- end of transaction modal -->

      single transaction <div id="my-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <p>Content</p>
          </div>
        </div>
      </div>
    </div>


      <footer class="footer ">
        <div class="container-fluid ">
          <div class="row ">
            <div class="col-sm-6 ">

            </div>
            <div class="col-sm-6 ">
              <div class="text-sm-end d-sm-block ">
                Best Birdy Billing system.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
    <!-- end main content-->
  </div>
  <!-- END layout-wrapper -->

  <!-- Right bar overlay-->
  <div class="rightbar-overlay "></div>

  </body>

</template>
<script>
/* eslint-disable */

import NavigationBar from "@/components/Navigation";
import {paysol, execute, execute2} from "@/api";
import moment from "moment/moment";
export default {
  name: "printReceipt",
  components: {NavigationBar},
  data() {
    return {
      message:null,
      loading: false,
      selectedFile: null,
      alert:{
        success: false,
        info: false,
        failed:false
      },
      uploads: [
        {
          id: null,
          reconCode: null,
          shortCode: null,
          fromDate: null,
          toDate: null,
          totalRecords:null,
          missingTransactions: null,
          file: "/",
          dateCreated: null,
        }
      ]
    };
  },
  mounted() {

    this.intervalId = setInterval(() => {
      this.getUploadedTransactions();
    }, 10000); // 10000 milliseconds = 10 seconds

  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed to avoid memory leaks
    clearInterval(this.intervalId);
  },
  methods: {
    showDate() {
      const dateObj = new Date();
      const currentDate =
          dateObj.getDate() +
          "/" +
          dateObj.getMonth() +
          "/" +
          dateObj.getFullYear();
      return currentDate;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    handleFileChange: function (event) {
      console.log("File selected:", event.target.files[0]);
      this.selectedFile = event.target.files[0];
    },
    uploadExcelToQue() {
      console.log("Upload button clicked");
      console.log("Selected File in uploadExcelToQue:", this.selectedFile);
      this.loading = true
      // Make sure 'file' is a valid File object
      const data = new FormData();
      data.append("function", "uploadExcelToQue");
      data.append("fileToUpload", this.selectedFile);

      // Assuming 'pay' is defined
      execute2(data,paysol)
          .then((res) => {
            this.loading = false
            if (res.data.success) {

              this.alert.success = true
              this.message =res.data. message

            } else {
              // Handle failure case
              this.alert.failed = true
              this.message =res.data. message
            }
          })
          .catch((e) => {
            // Handle network or other errors
            // alert(e.message);
            // this.alert.failed = true
          });
    },

    closeModal() {
      // Add any additional logic you need before closing the modal
      // For example, you may want to reset some data or perform an action
      // Then, close the modal
      $('#uploadFile').modal('hide'); // Assuming you're using Bootstrap modal
    },

    getUploadedTransactions() {

      const data = new FormData();
      data.append("function", "getUploads");
      // Assuming 'pay' is defined
      execute2(data,paysol)
          .then((res) => {
            if (res.data.success) {
              this.uploads = res.data.data.uploads
            } else {
            }
          })
          .catch((e) => {

          });
    },

    getFileName(filePath){

      const parts = filePath.split('/');

// Get the last part (the filename)
      const filename = parts[parts.length - 1];

      return filename;

    },

    formattedTime(time) {
      return moment(time).format('hh:mm A')
    }


  },
};
</script>

<style scoped>

</style>