<template>




<!-- index.html   13:53:02 GMT -->

<head>

    <meta charset="utf-8" />
    <title>Hotline Transporters Sacco | All invoices</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
        type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

</head>

<body data-sidebar="dark">


<NavigationBar/>


 <div id="content" class="content-minimize">

    <div class="page-content">

        <div class="container-fluid">
            <!-- start page title -->
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">revenue Target for colectors</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                <li class="breadcrumb-item"><a href="#">Set Ups</a></li>
                                <li class="breadcrumb-item"><a href="#">Revenue Targets</a></li>
                                <li class="breadcrumb-item active">Collectors revenue targets</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <!-- tool bar -->
            <ToolBarTargets/>
            <!-- tool bar -->

            <!-- target cards -->
            <div class="row">
                <div class="col-xl-12">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="avatar-xs me-3">
                                                    <span
                                                            class="avatar-title rounded-circle bg-info bg-soft text-info font-size-18">
                                                        <i class="mdi mdi-account-group"></i>
                                                    </span>
                                        </div>
                                        <h5 class="font-size-14 mb-0">Collectors</h5>
                                    </div>
                                    <div class="text-muted mt-4">
                                        <h4>{{numberFormat(users.length)}}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="avatar-xs me-3">
                                                    <span
                                                            class="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                                        <i class="mdi mdi-map-marker-multiple"></i>
                                                    </span>
                                        </div>
                                        <h5 class="font-size-14 mb-0">Collection Zones</h5>
                                    </div>
                                    <div class="text-muted mt-4">
                                        <h4>#</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="avatar-xs me-3">
                                                    <span
                                                            class="avatar-title rounded-circle bg-success bg-soft text-success font-size-18">
                                                        <i class="mdi mdi-cash-multiple"></i>
                                                    </span>
                                        </div>
                                        <h5 class="font-size-14 mb-0">Value of Daily Targets</h5>
                                    </div>
                                    <div class="text-muted mt-4">
                                        <h4>KES #</h4>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <!-- end row -->
                </div>
            </div>
            <!-- end of target cards -->

            <div class="row">

                <div class="col-12">
                    <div class="card">
                        <div
                                class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                            <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100"
                                 role="toolbar">
                                <div class="flex-grow-1">
                                    <div class="search-box mb-0 me-3"> {{search}}
                                        <div class="position-relative">
                                            <input v-model="search" type="text"
                                                   class="form-control bg-light border-light rounded"
                                                   placeholder="Search..." fdprocessedid="husj3l">
                                            <i class="bx bx-search-alt search-icon"></i>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex">

                                    <button data-bs-toggle="modal" data-bs-target=".upload-modal"
                                            type="button"
                                            class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                        <i class="mdi mdi-microsoft-excel font-size-16"></i> <span
                                            class="pl-1 d-md-inline">Upload Excel File</span>
                                    </button>

                                    <button data-bs-toggle="modal" data-bs-target=".download-modal"
                                            type="button"
                                            class="btn btn-success dropdown-toggle option-selector me-3 text-capitalize ">
                                        <i class="dripicons-download font-size-16"></i> <span
                                            class="pl-1 d-md-inline">Download</span>
                                    </button>


                                </div>


                            </div>

                        </div>
                        <div class="card-body">
                            <div class="table-responsive overflow-visible">

                                <table class="table align-middle table-hover contacts-table" id="">
                                    <thead class="table-light">
                                    <tr class="table-dark">
                                        <th>#</th>
                                        <th scope="col">Name</th>
                                        <th>ID No.</th>
                                        <th>Phone</th>
                                        <th>Sub County</th>
                                        <th>Zone(s)</th>
                                        <th>Today's Collection</th>
                                        <th class="text-right">Daily Target</th>
                                        <th class="text-right">Monthly Target</th>
                                        <th class="text-right" scope="col">FY Target</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr :key="index" :value="item" v-for="(item, index) in users">
                                        <th>{{index+1}}.</th>
                                        <td>
                                            <a href="use-logs.html" title="click to view user logs and activities">
                                                <div class="avatar-xs mr-15px">
                                                                <span class="avatar-title rounded-circle">
                                                                    #
                                                                </span>
                                                </div>
                                            </a>
                                            <div>
                                                <a href="use-logs.html" title="click to view user logs and activities">
                                                    <h5 class="font-size-14 mb-1">
                                                        <a href="use-logs.html" title="click to view user logs and activities"></a>
                                                        <a href="javascript: void(0);" class="text-dark text-capitalize">{{item.names}}</a>
                                                    </h5>
                                                    <p class="text-muted mb-0">Revenue Collector</p>
                                                </a>
                                            </div>
                                        </td>
                                        <td class="">
                                            {{item.idNo}}
                                        </td>
                                        <td>
                                            <a href="tel:'{{item.phoneNumber}}'">{{item.phoneNumber}}</a>
                                        </td>
                                        <td>
                                            {{item.subCountyName}}
                                        </td>
                                        <td>
                                           {{item.zone}}
                                        </td>
                                        <td class="text-right text-uppercase">
                                            KES {{numberFormat(item.todayCollection)}}
                                        </td>
                                        <td class="text-right text-uppercase">
                                            KES {{numberFormat(item.dailyTargets)}}
                                        </td>
                                        <td class="text-right text-uppercase">
                                            KES {{numberFormat(item.monthlyTargets)}}
                                        </td>
                                        <td class="text-right text-danger">  KES {{numberFormat(item.financialYearTargets)}}  </td>
                                        <td>
                                            <button @click="selectedUser(item)" data-bs-toggle="modal" data-bs-target=".target-modal" class="btn btn-primary btn-sm"><i
                                                    class="mdi mdi-file-document-edit-outline"></i> Edit
                                            </button>
                                        </td>
                                    </tr>

                                    </tbody>

                                </table>

                            </div>

                        </div>

                        <div class="card-footer bg-white">
                            <nav aria-label="Page navigation example "
                                 class="d-flex align-items-center justify-content-between">

                                <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                                    <select class="form-select w-auto font-13px ">
                                        <option value="10">10 Rows</option>
                                        <option value="25">25 Rows</option>
                                        <option value="50">50 Rows</option>
                                        <option value="100">100 Rows</option>
                                        <option value="150">150 Rows</option>
                                        <option value="200">200 Rows</option>
                                    </select>
                                </div>

                                <ul class="pagination">
                                    <li class="page-item disabled">
                                        <a class="page-link" href="#" tabindex="-1">Previous</a>
                                    </li>
                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item active">
                                        <a class="page-link" href="#">2 <span
                                                class="sr-only">(current)</span></a>
                                    </li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item">
                                        <a class="page-link" href="#">Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

            </div>


        </div>

    </div>

    <!-- setting target -->
    <div class="modal fade target-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">

        <div class="modal-dialog modal-md modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Setting targets for {{user.names}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="col-12">
                            <address>
                                <strong class="text-capitalize">Name</strong><br>
                                {{user.names}}
                            </address>
                        </div>


                        <!-- calculated by dividing FY target by 365 -->

                        <div class="col-12">
                            <label><strong>Daily Target Amount</strong></label>
                        </div>
                        <div class="col-12 mb-3">
                            <input v-model="user.dailyTargets" class="form-control" type="text" placeholder="Enter Amount">
                        </div>

                        <!-- calculated by dividing FY targets by 12 -->

                        <div class="col-12">
                            <label><strong>Monthly Target</strong></label>
                        </div>
                        <div class="col-12 mb-3">
                            <input v-model="user.monthlyTargets" class="form-control" type="text" placeholder="Enter Amount">
                        </div>

                        <!-- this is what has been provided -->

                        <div class="col-12">
                            <label><strong>Financial Year Target</strong></label>
                        </div>
                        <div class="col-12 mb-3">
                            <input v-model="user.financialYearTargets" class="form-control" type="text" placeholder="Enter Amount">
                        </div>



                        <div class="col-12">
                            <button @click="updateUsersTargets" type="button" data-bs-dismiss="modal"
                                    class="btn btn-primary dropdown-toggle option-selector text-capitalize w-100 mt-5">
                                <i class="mdi mdi-content-save-all  font-size-16"></i> <span
                                    class="pl-1 d-md-inline">Save Changes</span>
                            </button>
                        </div>

                    </div>

                </div>

            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-- end of target setting -->
</div>




</body>




</template>

<script>
    /* eslint-disable */
    import SubCountyReportsToolBar from "@/components/reports/collectionbysubcounties/SubCountyReportsToolBar";
    import NavigationBar from "@/components/Navigation";
    import ToolBarTargets from "@/components/systemsettings/ToolBarTargets";
    import {authUrl, execute} from "@/api";
    export default {
        name: "TargetsCollectors",
        components: {ToolBarTargets, NavigationBar},
        data() {
            return {
                search:'',
                users:[
                    {
                        names: null,
                        idNo: null,
                        phoneNumber: null,
                        subCountyName: null,
                        zone: null,
                        todayCollection: null,
                        dailyTargets: null,
                        monthlyTargets: null,
                        financialYearTargets: null
                    }
                ],
                user:
                    {
                        names: null,
                        idNo: null,
                        phoneNumber: null,
                        subCountyName: null,
                        zone: null,
                        dailyTargets: null,
                        monthlyTargets: null,
                        financialYearTargets: null
                    }
                ,
                currentPage:'',
            };
        },
        watch: {

            search(keyword) {
                this.getUsersTargets(keyword)
            }

        },
        mounted() {

            this.getUsersTargets("")
        },
        methods:{
            selectedUser(item) {
                this.user = item
            },
            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },
            getUsersTargets(keyword) {
                this.users.splice(0)
                const data = new FormData();
                data.append("function", "getUsersTargets");
                data.append("keyword", keyword);
                execute(data, authUrl)
                    .then((res) => {
                        if (res.data.success) {
                            this.users = res.data.data.users
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message = e.message
                    });
            },
            updateUsersTargets() {
                const data = new FormData();
                data.append("function", "updateUserTargets");
                data.append("user", JSON.stringify(this.user));
                execute(data, authUrl)
                    .then((res) => {
                        if (res.data.success) {
                            this.users.splice(0)
                            this.getUsersTargets()
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message = e.message
                    });
            },
        }
    }
</script>

<style scoped>

</style>