<template>

<head>

    <meta charset="utf-8" />
    <title>Desk Birdy | Parking Quick Stats</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
        type="text/css" />

         <!-- // Add the new slick-theme.css if you want the default styling -->
     <link rel="stylesheet" href="assets/libs/slick/slick.css"/>
     <link rel="stylesheet" href="assets/libs/slick/slick-theme.css"/>

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->

    <!-- owl.carousel css -->
    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

</head>

<body data-sidebar="dark">

    <!-- <body data-layout="horizontal" data-topbar="dark"> -->
    <!-- Loader -->

    <!-- Begin page -->

    <!-- Begin page -->
    <div id="layout-wrapper">

        <NavigationBar/>

        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row ">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Daily Parking</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Parking</a></li>
                                        <li class="breadcrumb-item"><a href="#">Daily Parking</a></li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                    <!-- tool bar -->
                    <ToolBar/>
                    <!-- end of toolbar -->
                    <div class="row">
                        <div class="col-lg-12 px-sm-30px">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-sm-12 align-self-center text-capitalize">
                                            <div class="text-lg-left mt-4 mt-lg-0">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-2 col-lg-2 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span
                                                                    class="avatar-title rounded-circle bg-warning font-size-20">
                                                                    <i class="mdi mdi-car-multiple text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">Logged Vehicles</p>
                                                            <h5 class="mb-0">{{numberFormat(stats.logged)}}</h5>

                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-2 col-lg-2 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span
                                                                    class="avatar-title rounded-circle bg-info font-size-20">
                                                                    <i
                                                                        class="mdi-cash-check mdi text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2 text-capitalize">
                                                                Parking Fee Payers</p>
                                                            <h5 class="mb-0">{{numberFormat(stats.payers)}}</h5>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-2 col-lg-2 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span class="avatar-title rounded-circle font-size-20">
                                                                    <i class="mdi mdi-check-all  text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">Compliant Vehicles</p>
                                                            <h5 class="mb-0">{{numberFormat(stats.compliant)}}</h5>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-2 col-lg-2 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span class="avatar-title rounded-circle font-size-20">
                                                                    <i class="mdi mdi-car-off text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">Uncompliant Vehicles</p>
                                                            <h5 class="mb-0">{{numberFormat(stats.uncompliant)}}</h5>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-md-2 col-lg-2 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span
                                                                    class="avatar-title rounded-circle bg-danger font-size-20">
                                                                    <i class="mdi mdi-car-off text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">Clamped Vehicles
                                                            </p>
                                                            <h5 class="mb-0">{{numberFormat(stats.clamped)}}</h5>

                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-md-2 col-lg-2 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span
                                                                    class="avatar-title rounded-circle bg-danger font-size-20">
                                                                    <i class="mdi mdi-car-off text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">To Be Unclamped
                                                            </p>
                                                            <h5 class="mb-0">{{numberFormat(stats.tobeunclamped)}}</h5>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <!-- end row -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12 px-sm-30px">
                            <div class="card">
                                <div class="card-header bg-white  w-100 border-bottom">
                                    <h4 class="card-title text-capitalize">
                                        Daily Parking Report for The period {{formatDate(dateFrom)}} to {{formatDate(dateTo)}}
                                    </h4>
                                </div>

                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                      <div class="flex-grow-1">
                                        <div class="search-box mb-0 me-3">
                                            <div class="position-relative">
                                                <input v-model="search" type="text" class="form-control bg-light border-light rounded" placeholder="Search..." fdprocessedid="husj3l" spellcheck="false" data-ms-editor="true">
                                                <i class="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>
                                      </div>
                                    </div>

                                </div>
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">


                                        <div class="d-flex">

                                            <select class="form-select w-auto font-13px mx-3" v-model="subCounty" @change="getParkingEntries(subCounty.subCountyName)">

                                                <option  selected="" value="">All Sub Counties</option>
                                                <option :key="index" :value="item" v-for="(item, index) in subCounties">
                                                    {{ item.subCountyName }}
                                                </option>

                                            </select>


                                            <select class="form-select w-auto font-13px mx-3" v-model="ward" @change="getZones(ward.wardID, ward.wardName)">
                                                <option  selected="" value="">All Wards</option>
                                                <option :key="index" :value="item"
                                                        v-for="(item, index) in wards">
                                                    {{ item.wardName }}
                                                </option>

                                            </select>



                                            <select class="form-select w-auto font-13px mx-3" v-model="zone" @change="getUsers(zone)" >

                                                <option selected="" value="">All Zones</option>
                                                <option v-for="(item, index) in zones" :key="`${index}`"><a class="dropdown-item" href="#">{{item.zone}}</a></option>

                                            </select>


                                            <select class="form-select w-auto font-13px mx-3" v-model="idNo" @change="getParkingEntries(idNo)">
                                                <option selected=""  >All Attendants</option>
                                                <option v-for="(item, index) in users" :key="`${index}`" :value="item.idNo"><a class="dropdown-item" href="#">{{item.names}}</a></option>

                                            </select>

                                            <div class="d-flex align-items-center me-3 pe-3">
                                                <span class="pr-3 fw-semibold">From:</span>
                                                <input v-model="dateFrom" class="form-control" placeholder="Start date" type="date">
                                                <span class="px-3 fw-semibold">TO:</span>
                                                <input v-model="dateTo" class="form-control" placeholder="Start date" type="date">
                                            </div>
                                            <button @click="downloadParkingEntries()" data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                                <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">{{download}}</span>
                                            </button>

                                        </div>


                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                                        <button type="button" data-bs-toggle="modal" data-bs-target=".download-modal" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                                    </div>
                                </div>

                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table align-middle table-striped table-nowrap table-hover dt-responsive contacts-table" id="">
                                            <thead class="table-dark">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Vehicle</th>
                                                    <th>Category</th>
                                                    <th>Duration</th>
                                                  <!--  <th>Sub County</th>-->
                                                    <th scope="col">Zone</th>
                                                    <th>Paid By</th>

                                                  <!--  <th>Attendant</th>-->
                                                    <th scope="col" class="">Date Paid</th>
                                                 <!--   <th scope="col" class="">Recent Log</th>-->
                                                    <th>Payment Mode</th>
                                                    <th>Status</th>
                                                    <th class="text-right">Parking Fee Paid</th>
                                                    <th>Start</th>
                                                    <th>End</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="text-capitalize">
                                                <tr :key="index" :value="item" v-for="(item, index) in vehicles">
                                                    <th>{{numberFormat(index+1)}}.</th>
                                                    <th>{{item.numberPlate}}</th>
                                                    <td class="text-capitalize">{{item.category}}</td>
                                                    <td class="text-capitalize">{{item.duration}}</td>
                                                    <td>
                                                        {{item.zone}}
                                                    </td>
                                                    <td>{{item.paidBy}}</td>
                                                  <!--  <td>Alex Wanjala (0704549859)</td>-->
                                                 <!--  <td><a href="use-logs.html">Kelvin Njuguna</a></td>-->
                                                    <td>
                                                        {{item.dateCreated}}
                                                    </td>
                                                   <!-- <td>
                                                        12 Jan 10:40AM (No. Plate Scanning)
                                                    </td>-->
                                                    <th>
                                                        {{item.paymentMode}} ({{item.transactionCode}})
                                                    </th>
                                                    <td>
                                                        <span class="badge rounded-pill bg-success text-uppercase" key="t-new">{{item.status}}</span>
                                                    </td>
                                                    <td class="text-right text-uppercase">KES {{numberFormat(item.receiptAmount)}}</td>
                                                    <th>{{item.startDate}}</th>
                                                    <th>{{item.endDate}}</th>

                                                </tr>

                                            </tbody>
                                            <tfoot class="table-dark">
                                                <tr>
                                                    <th colspan="2">{{numberFormat(vehicles.length)}} Transactions</th>
                                                    <th colspan="7"></th>
                                                 <!--   <th colspan="2" >
                                                        <span class="badge rounded-pill bg-danger text-uppercase" key="t-new">Compliant</span> 12

                                                        <span class="badge rounded-pill bg-success text-uppercase" key="t-new">Uncompliant</span> 22
                                                    </th>-->
                                                    <th class="text-right">KES {{numberFormat(totalAmount)}}</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </tfoot>


                                        </table>


                                        <div v-if="loading" class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center">
                                            <div class="p-5 py-0 pt-3">
                                                <div class="p-5">
                                                    <div class="spinner-border text-info m-1" role="status">
                                                        <span class="sr-only text-uppercase">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 class="text-uppercase">Loading Data</h4>
                                            <div class="col-sm-12 col-md-8 col-lg-5">
                                                <p>We are fetching the data for you. It will be ready soon. Please wait and have fun. Thank you for your interest.</p>

                                            </div>
                                        </div>

                                        <div v-if="vehicles.length<1 & loading === false" class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center">
                                            <div class="p-5 py-0 pt-3">
                                                <img :src="'./assets/images/no-results.png'" class="img mb-4"  alt="No search results"/>
                                            </div>
                                            <h4>No Results To Show</h4>
                                            <p>Sorry, we could not find any results that match your search or filter criteria. Please try using different keywords or filters.</p>
                                        </div>


                                    </div>
                                </div>
                                <div class="card-footer bg-white">
                                    <nav aria-label="Page navigation example " class="d-flex align-items-center justify-content-between">

                                        <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                                            <select class="form-select w-auto font-13px" v-model="pageSize">
                                                <option value="10">10 Rows</option>
                                                <option value="25">25 Rows</option>
                                                <option value="50">50 Rows</option>
                                                <option value="100">100 Rows</option>
                                                <option value="150">150 Rows</option>
                                                <option value="200">200 Rows</option>
                                                <option value="5000">5000 Rows</option>
                                                <option value="10000">10000 Rows</option>
                                                <option value="100000000000">All Rows</option>
                                            </select>
                                        </div>

                                        <ul class="pagination">
                                            <!-- add a class of disabled and an attribute of disabled when the current page is the first page -->
                                            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                                <a class="page-link" tabindex="-1" @click="prevPage" :disabled="currentPage === 1">Previous</a>
                                            </li>
                                            <!-- replace the v-for directive with the pagination function call -->
                                            <li v-for="page in pagination(currentPage, totalPages, 3)" :key="page" class="page-item" :class="{ active: currentPage === page }">
                                                <!-- check if the page is an ellipsis and display it as plain text -->
                                                <span v-if="page === '...'" class="page-link">{{ page }}</span>
                                                <!-- otherwise, display it as a clickable link -->
                                                <a v-else class="page-link" @click="goToPage(page)">{{ page }}</a>
                                            </li>
                                            <!-- add a class of disabled and an attribute of disabled when the current page is the last page -->
                                            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                                <a class="page-link"  @click="nextPage" :disabled="currentPage === totalPages">Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-full modal-fullscreen">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Business Details</h5>
                            <span
                                class="badge rounded-pill bg-success text-uppercase font-12px text-uppercase mx-3">Active
                                Certificate</span>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="hori-timeline mt-4">
                                        <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel"
                                            id="timeline-carousel">
                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                                                        <h5 class="mb-4">Application</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                            class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">Kelvin Omondi</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">15 Sep 2023 12:59 AM</div>
                                                        <h5 class="mb-4">Details Verification</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                            class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By Kelvin Ouma</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">20 Sep 2023 08:23 AM</div>
                                                        <h5 class="mb-4">Premices Inspection</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                            class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By Alex Kinoti</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">23 sep 2023 08:23 AM</div>
                                                        <h5 class="mb-4">Approval Of Application</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                            class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By John King</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">23 sep 2023 08:25 AM</div>
                                                        <h5 class="mb-4">issueance Of Certifcate</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                            class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By John King</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list active">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">By 31 Dec 2023 12:00 AM</div>
                                                        <h5 class="mb-4">Renewal</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                            class="bx bx-down-arrow-circle h1 text-info down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">-</a></p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-success border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-certification font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Certificate details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                    class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Serial
                                                                    No.</p>
                                                            </td>
                                                            <td class="">12345</td>

                                                        </tr>

                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Last Paid for</p>
                                                            </td>
                                                            <td class="">12 Jan 2023</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Expiry
                                                                    Date</p>

                                                            </td>
                                                            <td class="">31 Dec 2023</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Days
                                                                    to expiry</p>

                                                            </td>
                                                            <td class="">204 Days</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Printing status</p>

                                                            </td>
                                                            <td class="">Printed</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Amount
                                                                    Paid</p>

                                                            </td>
                                                            <td class="text-uppercase text-black fw-semibold">KES 25,000
                                                            </td>

                                                        </tr>




                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-briefcase-alt-2 font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Business Details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                    class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Business
                                                                    Name</p>
                                                            </td>
                                                            <td class="">Wireless Electronics</td>

                                                        </tr>

                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Certificate of Incoporation No. </p>
                                                            </td>
                                                            <td class="">1234557768</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">KRA
                                                                    Pin
                                                                    No.</p>

                                                            </td>
                                                            <td class="">12345</td>

                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">VAT
                                                                    No.
                                                                </p>

                                                            </td>
                                                            <td class="">12345</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Business
                                                                    Activity</p>

                                                            </td>
                                                            <td class="">Accomodation and Catering</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Business
                                                                    Sub category</p>

                                                            </td>
                                                            <td class="">Small Lodging House</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-warning border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bxs-user-pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Owner Details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                    class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Owner's Full Names</p>
                                                            </td>
                                                            <td class="">Mr Alex Wanjala Akinyi</td>

                                                        </tr>

                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    ID/PPT No.</p>
                                                            </td>
                                                            <td class="">1234557768</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Owner's Fax</p>

                                                            </td>
                                                            <td class="">12345</td>

                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Owner's Mobile No.</p>

                                                            </td>
                                                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Alternative Mobile No.</p>

                                                            </td>
                                                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                        </tr>



                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Postal
                                                                    Address</p>

                                                            </td>
                                                            <td class="">12-1004 Nairobi</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-phone-call pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Location and Contact information</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                    class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Business Mobile No.</p>
                                                            </td>
                                                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                        </tr>

                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Alternative Phone No</p>
                                                            </td>
                                                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                        </tr>



                                                        <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Business Email address</p>
                                                            </td>
                                                            <td class=""><a
                                                                    href="mailto:email@email.com">email@email.com</a>
                                                            </td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold"> Sub
                                                                    county</p>

                                                            </td>
                                                            <td class="">Homabay Town Sub county</td>

                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Ward
                                                                </p>

                                                            </td>
                                                            <td class=""><a href="tel:0704549859">Rodi Township Ward</a>
                                                            </td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Physical address/Street</p>

                                                            </td>
                                                            <td class=""><a href="tel:0704549859">Along Moi Avenue
                                                                    opposite cooperative Bank</a></td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Building Name</p>

                                                            </td>
                                                            <td class="">ALexis complex</td>

                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                    Stall/Room No.</p>

                                                            </td>
                                                            <td class="">122</td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-8">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-dark border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-map font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Map View</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div>
                                                <iframe
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46449.71551863634!2d36.67523836781685!3d-1.3353441122372378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f0cb9ff59a4a3%3A0x4073419e11826405!2sOrbital%20Africa%20%7C%20Land%20%26%20Topo%20Survey%2C%20GPS%2C%20GPR%2C%20GIS%2C%20Mapping%2C%20Drones%2C%20Training%20%26%20Geospatial%20Solutions!5e0!3m2!1sen!2ske!4v1691859398886!5m2!1sen!2ske"
                                                    width="100%" height="450" style="border:0;" allowfullscreen=""
                                                    loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">

                            <a href="PrintDocs/Trade-Permit/permit.html" target="_blank" type="button"
                                class="btn btn-primary waves-effect waves-light">
                                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Certificate
                            </a>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div class="modal fade add-car-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-xl modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Usiku Sacco</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div >
                                <div class="col-12">
                                    <address>
                                        <strong>About Sacco:</strong><br>
                                        Conatct Person: Kelvin Njuguna<br>
                                        email@mail.com, 0704 549 859<br>
                                        Hse No. 410, 90 Degrees By Tsavo
                                        <br><br>
                                        Ward, Sub County
                                    </address>
                                </div>
                               <div class="new-vehicles-container-modal">
                                <div class="col-12">
                                    <div class="py-2 mt-3">
                                        <h3 class="font-size-15 fw-bold">Vehicles to Be newly registered</h3>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="">
                                        <table class="table table-editable-1 align-middle table-edits">
                                            <thead class="table-light">
                                                <tr class="text-uppercase table-dark">
                                                    <th>#</th>
                                                    <th>Vehicle</th>
                                                    <th class="">Category</th>
                                                    <th class="">Owner</th>
                                                    <th>Phone No.</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr data-id="1">
                                                    <td style="width: 80px">1.</td>
                                                    <td class="">
                                                        <input type="text " class="form-control " placeholder="Enter Plate No." spellcheck="false" data-ms-editor="true">
                                                    </td>
                                                    <td>
                                                        <select class="form-control selectpicker form-select" data-live-search="true" title="Select Vehicle Category" data-style="form-select btn border">
                                                            <option value="Sedan">Sedan</option>
                                                            <option value="SUV">SUV</option>
                                                            <option value="Truck">Truck</option>
                                                            <option value="Motorcycle">Motorcycle</option>
                                                            <option value="Van">Van</option>
                                                            <!-- Add more vehicle categories here as needed -->
                                                        </select>

                                                    </td>
                                                    <td class="">
                                                        <input type="text " class="form-control " placeholder="Owner's Name" spellcheck="false" data-ms-editor="true">
                                                    </td>
                                                    <td class="">
                                                        <input type="text " class="form-control " placeholder="Owner's Phone No." spellcheck="false" data-ms-editor="true">
                                                    </td>

                                                    <td></td>

                                                </tr>

                                                <tr class="cloneCharges d-none">
                                                    <td style="width: 80px " class="categoryIndex ">#</td>

                                                    <td class="">
                                                        <input type="text " class="form-control " placeholder="Enter Plate No." spellcheck="false" data-ms-editor="true">
                                                    </td>
                                                    <td>
                                                        <select class="form-control" data-live-search="true" title="Select Vehicle Category" data-style="form-select btn border">
                                                            <option value="Sedan">Sedan</option>
                                                            <option value="SUV">SUV</option>
                                                            <option value="Truck">Truck</option>
                                                            <option value="Motorcycle">Motorcycle</option>
                                                            <option value="Van">Van</option>
                                                            <!-- Add more vehicle categories here as needed -->
                                                        </select>

                                                    </td>
                                                    <td class="">
                                                        <input type="text " class="form-control " placeholder="Owner's Name" spellcheck="false" data-ms-editor="true">
                                                    </td>
                                                    <td class="">
                                                        <input type="text " class="form-control " placeholder="Owner's Phone No." spellcheck="false" data-ms-editor="true">
                                                    </td>


                                                    <td class="text-right cell-change d-flex align-items-center justify-content-end">
                                                        <a class="btn btn-light btn-rounded waves-effect btn-circle btn-transparent cancel-new-category-2 " title="Cancel "><i class="bx bx-x "></i></a>
                                                    </td>
                                                </tr>


                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colspan="7" class="bg-light add-field-1 cursor-pointer">
                                                        <span class="d-flex align-items-center "><i class="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i><span class="pl-5 ">Add A Vehicle</span></span>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>


                                </div>
                               </div>

                                <div class="d-none  loader-panel">
                                    <div class="p-5 py-0 pt-3 d-flex justify-content-center align-items-center text-center flex-column">
                                        <div class="p-5">
                                            <div class="spinner-border text-info m-1" role="status">
                                                <span class="sr-only text-uppercase">Loading...</span>
                                            </div>
                                        </div>
                                        <h4 class="text-black fw-bold">Payment Being Processed</h4>
                                        <div class="mb-5">Ask Client to be checking their Phone for a payment request thats currently being Processed</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-non">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                            <tr data-id="1">

                                                <td class="px-3 ">
                                                    <div class="phone-num d-non">
                                                        <label>Paying Phone No.</label>
                                                        <input type="text " class="form-control w-100 d-flex "
                                                            placeholder="Paying Phone Number" spellcheck="false"
                                                            data-ms-editor="true">
                                                    </div>

                                                </td>
                                                <td class="px-3 ">
                                                    <div class="phone-num d-non">
                                                        <label>Vehicles</label>
                                                        <input type="text " class="form-control w-100 d-flex "
                                                            placeholder="Paying Phone Number" spellcheck="false"
                                                            data-ms-editor="true" disabled value="4 Vehicles">
                                                    </div>

                                                </td>
                                                <td class="px-3">
                                                    <label>Computed Registration Amount</label>
                                                    <input type="text " class="form-control w-100 d-flex"
                                                        placeholder="KES" spellcheck="false" data-ms-editor="true" disabled value="8000">

                                                </td>



                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">

                                <a href="javascript: void(0);"
                                    class="btn btn-primary w-md waves-effect waves-light reg-payment">Send Payment Request</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">

    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
             County Government
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>

</body>
</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import ToolBar from "@/components/parking/ToolBar";
    import {formatDate,authUrl, biller, execute, executeDownload, parking} from "@/api";
    export default {
        name: "DailyParking",
        components: {ToolBar, NavigationBar},
        data(){
            return{
                idNo:'',
                subCountyName:'',
                wardName:'',
                dateFrom:'',
                dateTo:'',
                download:'Download Report',
                numberPlate:'',
                search:'',
                loading: false,
                // set the initial current page as 1
                currentPage: 1,
                // set the total number of pages as 10 (you can change this according to your data source)
                totalPages: 0,
                // set the number of items per page as 5 (you can change this according to your preference)
                pageSize: 10,
                summary: {
                    count: 0,
                    total: 0
                },
                saccoName:'',
                complianceStatus:'',
                totalAmount:0,
                vehicles: [
                    {
                        id: "43975",
                        parkingCode: "BILL010305404669",
                        numberPlate: "KCR812Y",
                        duration: "PER DAY",
                        category: "Saloon Car",
                        zone: " TOWN PARKING",
                        billTotal: "50",
                        receiptAmount: "50",
                        billBalance: "0",
                        startDate: "2024-01-03 05:41:01",
                        endDate: "2024-01-03 00:00:00",
                        status: "PAID",
                        transactionCode: "SA33X4822T",
                        paymentMode: "MPESA",
                        street: null,
                        paidBy: "DAVID",
                        saccoID: "",
                        saccoName: "",
                        feeID: "83834",
                        dateCreated: "2024-01-03 02:35:28",
                        subCountyName: null,
                        subCountyID: null,
                        wardName: null,
                        wardID: null
                    }],
                todayHighlights: [
                    {
                        title: "LOGGED VEHICLES",
                        amount: "41,695"
                    }
                ],

                subCounties:[{
                    subCountyID: "",
                    subCountyName: ""
                }],
                wards:[{
                    wardID: "",
                    wardName: ""
                }],
                subCounty:{
                    subCountyID: null,
                    subCountyName: null
                },
                ward:{
                    wardID: null,
                    wardName: null
                },
                zones: [],
                zone:'',
                users: [{
                id: "1211",
                names: "Mururu Kennedy Nkunja",
                gender: "Male",
                email: "",
                phoneNumber: "254716802598",
                idNo: "29046457",
                password: "$2y$10$2hh5poS1xKRmdNEAmja1gu3.lguP12eenD\/RoIKrmflopk.OPoFkW",
                category: "COLLECTOR",
                dateCreated: "2023-12-22 12:07:23",
                zone: "KIUTINE CESS POINT",
                subCountyID: "12052",
                subCountyName: "IGEMBE CENTRAL",
                wardID: "120520260",
                wardName: "KANGETA",
                lat: "-0.0399306",
                lng: "37.6605822",
                address: "XM66+267, Embu - Meru Hwy, Mutunduri, Kenya",
                lastSeen: "2024-01-03 20:27:35",
                status: "Active",
                des: "Mururu Kennedy Nkunja logged in at Tue 2 Jan a\t 08:57 PM",
                target: "0",
                permission: "READ",
                dailyTargets: null,
                monthlyTargets: null,
                financialYearTargets: null,
                createdBy: null,
                deviceId: "23b1e2d8c3a43e10",
                logTime: "2024-01-02 20:57:44",
                login: "true"
            }],

                stats: {
                    logged: "696",
                    payers: "0",
                    compliant: "696",
                    uncompliant: "0",
                    clamped: "4",
                    tobeunclamped: "4"
                }

            }
        },
        mounted() {

            this.dateFrom = this.getCurrentDate()
            this.dateTo =  this.getCurrentDate()

            $('.todays-entries').slick({
                arrows:true,
                prevArrow:'<button type="button" class="slick-prev"></button>',
                nextArrow:'<button type="button" class="slick-next"></button>',
                centerMode:true,
                slidesToShow:7,
                speed: 7000,
                autoplay: true,
                autoplaySpeed: 0,
                cssEase: 'linear',
                slidesToScroll: 1,
                pauseOnFocus: true,
                variableWidth: true
            });

            this.saccoName = sessionStorage.getItem("saccoName")

            this.$watch('search', function (newValue) {
                this.getParkingEntries()
            })
            this.$watch('dateFrom', function (newValue) {
                this.getParkingEntries()
            })
            this.$watch('dateTo', function (newValue) {
                this.getParkingEntries()
            })
            this.getSubCounties()
            this.getParkingEntries()

            this.getUsers()
        },
        watch: {
            //pagination code
            // define a watcher for the pageSize property
            pageSize(newPageSize) {
                this.getParkingEntries("")
                // get the total number of items from your data source (you can change this according to your logic)
                const totalItems = this.summary.count;
                // calculate the new total pages by dividing the total items by the new page size and rounding up
                const newTotalPages = Math.ceil(totalItems / newPageSize);
                // update the totalPages data property with the new value
                this.totalPages = newTotalPages;
                // reset the current page to 1
                this.currentPage = 1;

            },
            subCounty: function () {
                this.subCountyID =  this.subCounty.subCountyID
                this.subCountyName =  this.subCounty.subCountyName
                this.getWards()
            } ,
            ward: function () {
                this.wardID = this.ward.wardID
                this.wardName = this.ward.wardName
            },
        },
        computed: {
            //pagination code
            // calculate the start index of the items for the current page
            startIndex() {
                return (this.currentPage - 1) * this.pageSize;
            },
            // calculate the end index of the items for the current page
            endIndex() {
                return this.currentPage * this.pageSize;
            },
        },
        methods:{
            //pagination code
            pagination(currentPage, totalPages, maxVisibleButtons) {
                //initialize an empty array for the pages
                let pages = [];
                //calculate the start and end indices of the visible buttons
                let start = currentPage - Math.floor(maxVisibleButtons / 2);
                let end = currentPage + Math.floor(maxVisibleButtons / 2);
                //adjust the start and end indices if they are out of bounds
                if (start < 1) {
                    start = 1;
                    end = start + maxVisibleButtons - 1;
                    if (end > totalPages) {
                        end = totalPages;
                    }
                }
                if (end > totalPages) {
                    end = totalPages;
                    start = end - maxVisibleButtons + 1;
                    if (start < 1) {
                        start = 1;
                    }
                }
                //loop through the start and end indices and push the pages to the array
                for (let i = start; i <= end; i++) {
                    pages.push(i);
                }
                //add an ellipsis at the beginning if there are more pages before the start index
                if (start > 1) {
                    pages.unshift("...");
                }
                //add an ellipsis at the end if there are more pages after the end index
                if (end < totalPages) {
                    pages.push("...");
                }
// return the array of pages
                return pages;
            },
            // define a method to go to the previous page
            prevPage() {
                // check if the current page is not the first page
                if (this.currentPage > 1) {
                    // decrement the current page by 1
                    this.currentPage--;
                    this.getParkingEntries("")
                }
            },
            // define a method to go to the next page
            nextPage() {
                // check if the current page is not the last page
                if (this.currentPage < this.totalPages) {
                    // increment the current page by 1
                    this.currentPage++;
                    this.getParkingEntries("")
                }
            },
            // define a method to go to a specific page
            goToPage(page) {
                // check if the page is valid and not equal to the current page
                if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
                    // set the current page to the given page
                    this.currentPage = page;
                    this.getParkingEntries("")
                }
            },
            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },
            getParkingEntries(search){
                this.vehicles.splice(0)
                this.loading = true
                search = search === undefined ? '' : search;

                const data = new FormData();
                data.append("function", "getParkingEntries");
                data.append("keyword", search);
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                data.append("dateFrom", this.dateFrom);
                data.append("dateTo", this.dateTo);
                data.append("duration","DA")
                execute(data,parking)
                    .then((res) =>{
                        this.loading = false
                        if (res.data.success) {
                            this.vehicles = res.data.data.vehicles
                            this.summary = res.data.data.summary
                            this.stats = res.data.data.stats

                            this.totalPages =  Math.ceil(this.summary.count / this.pageSize);

                            this.totalAmount = this.vehicles.reduce((acc, cur) => {
                                let amount = Number(cur.receiptAmount);
                                if (!isNaN(amount)) {
                                    return acc + amount;
                                } else {
                                    return acc;
                                }
                            }, 0);

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            downloadParkingEntries(){
                this.download ="Please wait.."
                const data = new FormData();
                data.append("function", "downloadParkingEntries");
                data.append("keyword", this.search);
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                data.append("dateFrom", this.dateFrom);
                data.append("dateTo", this.dateTo);
                data.append("duration","DAY")
                executeDownload(data,parking).then(response => {
                    this.download ="Download Report"

                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(response.data);
                    link.download = "Report.csv";

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            },
            getSubCounties(){
                const data = new FormData();
                data.append("function", "getSubCounty");
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.subCounties =res.data.data.subCounties
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getWards(){
                this.wards =[]
                this.ward = ''
                const data = new FormData();
                data.append("function", "getWards");
                data.append("subCountyID", this.subCounty.subCountyID);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.wards =res.data.data.wards
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getZones(wardID,wardName){

                wardName = wardName === undefined ? '' : wardName;
                this.getParkingEntries(wardName)

                this.zones.splice(0)
                const data = new FormData();
                data.append("function", "getZones");
                data.append("wardID", wardID);
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.zones = res.data.data.zones
                        }else{
                            this.alert.success = false
                            this.alert.failed = true
                            this.alert.info = false
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                        this.alert.success = false
                        this.alert.failed = true
                        this.alert.info = false
                    });
            },
            getUsers(zone) {
                zone = zone === undefined ? '' : zone;
                this.getParkingEntries(zone)

                this.users.splice(0)
                const data = new FormData();
                data.append("function", "getUsers")
                data.append("search", zone)
                execute(data, authUrl)
                    .then((res) => {
                        if (res.data.success) {

                            this.users = res.data.data.users
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message = e.message
                    });
            },
            formatDate(date) {
                let d = new Date(date);
                let day = d.getDate();
                let month = d.toLocaleString('en-US', { month: 'short' });
                let year = d.getFullYear();

                let suffix = '';
                if (day % 10 === 1 && day !== 11) {
                    suffix = 'st';
                } else if (day % 10 === 2 && day !== 12) {
                    suffix = 'nd';
                } else if (day % 10 === 3 && day !== 13) {
                    suffix = 'rd';
                } else {
                    suffix = 'th';
                }
                return `${day}${suffix} ${month} ${year}`;
            },
            getCurrentDate() {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
                const day = String(today.getDate()).padStart(2, "0");

               return `${year}-${month}-${day}`;
            }
        }
    }
</script>

<style scoped>

</style>