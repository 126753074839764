<template>

<head>

    <meta charset="utf-8" />
    <title>Hotline Transporters Sacco | All invoices</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

</head>

<body data-sidebar="dark">

    <!-- <body data-layout="horizontal" data-topbar="dark"> -->
    <!-- Loader -->
    <!-- Begin page -->
    <div id="layout-wrapper">

        <NavigationBar/>



        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
         <div id="content" class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Revenue Collection by Streams for the period {{dateFrom}} to {{dateTo}}</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a>Dashboards</a></li>
                                        <li class="breadcrumb-item"><a>Reports</a></li>
                                        <li class="breadcrumb-item active">Revenue collection by Revenue Stream</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!-- tool bar -->
                    <ToolBarStreams/>
                    <!-- end of toolbar -->
                    <!-- end page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                      <div class="flex-grow-1">
                                        <div class="search-box mb-0 me-3">
                                            <div class="position-relative">
                                              <!--  <input type="text" class="form-control bg-light border-light rounded" placeholder="Search..." fdprocessedid="husj3l">
                                                <i class="bx bx-search-alt search-icon"></i>-->
                                            </div>
                                        </div>
                                      </div>

                                        <div class="d-flex">
                                            <div class="d-flex align-items-center me-3 pe-3">
                                                <span class="pr-3 fw-semibold">From:</span>
                                                <input v-model="dateFrom" class="form-control" placeholder="Start date" type="date">
                                                <span class="px-3 fw-semibold">TO:</span>
                                                <input v-model="dateTo" class="form-control" placeholder="Start date" type="date">
                                            </div>
                                            <button  data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                                <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                                            </button>

                                        </div>

                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                                        <button type="button"  data-bs-toggle="modal" data-bs-target=".download-modal" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive  ">

                                        <table class="table align-middle table-hover contacts-table table-striped" id="">
                                            <thead class="table-light">
                                            <tr class="table-dark text-uppercase">
                                                <th>#</th>
                                                <th scope="col">Revenue Stream</th>
                                                <th class="text-right" v-for="ward in wards" :key="ward">{{ ward }}</th>
                                                <th scope="col">Total</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            <tr :key="index" :value="item" v-for="(item, index) in streams">
                                                <td class="text-capitalize text-nowrap"><strong>{{ index + 1 }}</strong></td>
                                                <td class="text-capitalize text-nowrap">
                                                    <a href="javascript: void(0);">{{ item.stream.stream }}</a>
                                                </td>
                                                <td v-for="ward in wards" :key="ward" class="text-capitalize text-nowrap text-right">
                                                    KES {{numberFormat(getWardAmount(item.wardStreams, ward)) }}
                                                </td>
                                                <th class="text-capitalize text-nowrap text-right">{{ numberFormat(getTotalAmount(item.wardStreams))}}</th>
                                            </tr>
                                            </tbody>

                                            <tfoot class="table-dark">
                                            <tr>
                                                <th colspan="2">{{streams.length}} Streams</th>
                                                <td v-for="ward in wards" :key="ward" class="text-capitalize text-nowrap text-right">
                                                    {{ numberFormat(getTotalAmountByWard(ward))}}
                                                </td>
                                                <th class="text-capitalize text-nowrap text-right">{{ numberFormat(getTotalAmountForAllStreams())}}</th>
                                            </tr>
                                            </tfoot>
                                        </table>


                                        <div v-if="loading" class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center">
                                            <div class="p-5 py-0 pt-3">
                                                <div class="p-5">
                                                    <div class="spinner-border text-info m-1" role="status">
                                                        <span class="sr-only text-uppercase">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 class="text-uppercase">Loading Data</h4>
                                            <div class="col-sm-12 col-md-8 col-lg-5">
                                                <p>We are fetching the data for you. It will be ready soon. Please wait and have fun. Thank you for your interest.</p>

                                            </div>
                                        </div>

                                        <div v-if="streams.length<1 & loading === false" class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center">
                                            <div class="p-5 py-0 pt-3">
                                                <img :src="'./assets/images/no-results.png'" class="img mb-4"  alt="No search results"/>
                                            </div>
                                            <h4>No Results To Show</h4>
                                            <p>Sorry, we could not find any results that match your search or filter criteria. Please try using different keywords or filters.</p>
                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div class="modal fade download-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Download Report</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <address>
                                        <strong class="text-capitalize">Report Title</strong><br>
                                        Revenue Collections By Subcounties
                                    </address>
                                </div>

                                <div class="col-12">
                                    <label><strong>Report Date Range</strong></label>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-xl-6 col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Date From</label>
                                                <input v-model="dateFrom" class="form-control" placeholder="Start date" type="date">
                                            </div>
                                        </div>

                                        <div class="col-xl-6 col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Date To</label>
                                                <input v-model="dateTo" class="form-control" placeholder="Start date" type="date">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <label><strong>Select File Type</strong></label>
                                    <select class="form-select">
                                        <option>Select Document Type</option>
                                        <option>PDF file</option>
                                        <option>Excel File</option>
                                    </select>
                                </div>

                                <div @click="downloadReportsStreamsWard()" class="col-12">
                                    <button  type="button" class="btn btn-primary dropdown-toggle option-selector text-capitalize w-100 mt-5">
                                        <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">{{download}}</span>
                                    </button>
                                </div>

                            </div>

                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Invoice Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12">
                                <address>
                                    <strong>Billed To:</strong><br>
                                    Kelvin Njuguna<br>
                                    email@mail.com, 0704 549 859<br>
                                    Hse No. 410, 90 Degrees By Tsavo
                                    <br><br>
                                    1 Mar 2022, 10:20 AM
                                </address>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Invoice Details ( <span class="text-primary fw-medium">No. 1237</span> )</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead>
                                            <tr>
                                                <th style="width: 70px;">No.</th>
                                                <th>Item</th>
                                                <th class="text-end">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>Rent Deposit</td>
                                                <td class="text-end">KES 24,500</td>
                                            </tr>

                                            <tr>
                                                <td>02</td>
                                                <td>Monthly Rent</td>
                                                <td class="text-end">KES 12,000</td>
                                            </tr>

                                            <tr>
                                                <td>03</td>
                                                <td>TAX (18%)</td>
                                                <td class="text-end">KES 250</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Total</td>
                                                <td class="text-end fw-bold">KES 36,750</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Paid</td>
                                                <td class="text-end  fw-bold">KES 0.00</td>
                                            </tr>

                                            <tr>
                                                <td colspan="2" class="border-0 text-end">
                                                    <strong>Balance</strong></td>
                                                <td class="border-0 text-end">
                                                    <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                            <tr data-id="1">
                                                <td>
                                                    <label for="" class="">Payment Method</label>
                                                    <select class="form-control selectpicker w-100 payment-method" data-style="btn-secondary w-100" data-live-search="true" title="Select payment Method">
                                                        <option value="Mpesa">MPESA</option>
                                                        <option value="Cash">CASH</option>
                                                     </select>
                                                </td>
                                                <td class="px-3 ">
                                                    <div class="phone-num d-none">
                                                        <label for="">Phone No.</label>
                                                        <input type="text " class="form-control w-100 d-flex " placeholder="Phone No." spellcheck="false" data-ms-editor="true">
                                                    </div>

                                                </td>
                                                <td class="px-3">
                                                    <label for="">Amount To Be Paid</label>
                                                    <input type="text " class="form-control w-100 d-flex" placeholder="KES" spellcheck="false" data-ms-editor="true">

                                                </td>
                                                <td class="text-right float-right">
                                                    <div class="d-flex flex-column">
                                                        <label class="opacity-0">Something</label>
                                                        <a href="#" class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                                                    </div>
                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a href="javascript:window.print()" class="btn btn-success waves-effect waves-light me-1"><i class="mdi mdi-printer font-16px"></i></a>
                                <a href="javascript: void(0);" class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">

    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">

        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>

    </body>


</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import ToolBarTargets from "@/components/systemsettings/ToolBarTargets";
    import ToolBarStreams from "@/components/reports/streams/ToolBar";
    import moment from "moment";
    import {biller, execute, executeDownload} from "@/api";
    export default {
        name: "ReportsStreamsSubcounties",
        components: {ToolBarStreams, ToolBarTargets, NavigationBar},
        data() {
            return{
                loading: false,
                download:'Download Report',
                documentType:'',
                totalTransactions:0,
                totalAmount:0,
                totalAgents:0,

                dateTo:'',
                dateFrom:'',

                message:'',
                alert:{
                    success: false,
                    info: false,
                    failed:false
                },
                contact: {
                    email: ''
                },
                password:'',
                wards: ["WARD 1", "WARD 2"],
                streams: [
                    {
                        stream: {
                            incomeTypePrefix: "ADVT",
                            stream: "ADVERTS",
                            transactions: "36",
                            amount: "848105",
                            agents: "5"
                        },
                        wardStreams: [
                            {
                                wardName: "MERU TOWN",
                                amount: "6200"
                            }
                        ]
                    }]


            }
        },
        mounted() {

            this.dateFrom = moment().startOf('month').format('YYYY-MM-DD')
            this.dateTo = moment().format('YYYY-MM-DD')

            this.$watch('dateFrom', function (newValue) {
                if (this.dateFrom === '' || this.dateTo === '') {

                } else {
                    this.getReportsStreamsWard()
                }

            })
            this.$watch('dateTo', function (newValue) {
                if (this.dateFrom === '' || this.dateTo === '') {

                } else {
                    this.getReportsStreamsWard()
                }
            })

            this.getReportsStreamsWard()


        },


        methods: {
            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },
            getWardAmount(wardStreams, ward) {
                if (wardStreams && wardStreams.length > 0) {
                    const wardData = wardStreams.find(item => item.wardName === ward);
                    return wardData ? `${wardData.amount}` : '0';
                } else {
                    return '0';
                }
            },
            getTotalAmount(wardStreams) {
                if (wardStreams && wardStreams.length > 0) {
                    return `${wardStreams.reduce((total, item) => total + parseFloat(item.amount), 0)}`;
                } else {
                    return 0;
                }
            },
            getTotalAmountByWard(ward) {
                const totalAmount = this.streams.reduce((total, item) => {
                    if (item.wardStreams && item.wardStreams.length > 0) {
                        const wardData = item.wardStreams.find(wardItem => wardItem.wardName === ward);
                        return total + (wardData ? parseFloat(wardData.amount) : 0);
                    } else {
                        return total;
                    }
                }, 0);
                return `${totalAmount}`;
            },
            getTotalAmountForAllStreams() {
                const totalAmount = this.streams.reduce((total, item) => {
                    if (item.wardStreams && item.wardStreams.length > 0) {
                        const wardAmount = this.getTotalAmount(item.wardStreams);
                        const parsedAmount = parseFloat(wardAmount);

                        if (!isNaN(parsedAmount)) {
                            console.log(`Valid amount for ${item.stream.stream}: ${wardAmount}`);
                            return total + parsedAmount;
                        } else {
                            console.error(`Invalid amount for ${item.stream.stream}: ${wardAmount}`);
                            return total;
                        }
                    } else {
                        return total;
                    }
                }, 0);

                return `${totalAmount}`;
            },
            downloadReportsStreamsWard(){
                this.download ="Please wait.."
                const data = new FormData();
                data.append("function", "downloadReportsStreamsWard");
                data.append("dateFrom", this.dateFrom);
                data.append("dateTo", this.dateTo);
                executeDownload(data,biller).then(response => {
                    this.download ="Download Report"
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(response.data);
                    link.download = this.dateFrom+' to '+ this.dateTo+" ReportsStreamsSubCounty.csv";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            },
            getReportsStreamsWard(){
                this.loading = true
                this.streams.splice(0)
                const data = new FormData();
                data.append("function", "getReportsStreamsWard");
                data.append("dateFrom", this.dateFrom);
                data.append("dateTo", this.dateTo);
                execute(data,biller)
                    .then((res) =>{
                        this.alert.info = false
                        if (res.data.success) {
                            this.loading = false
                            this.streams = res.data.data.streams

                            this.wards.splice(0)
                            this.wards = res.data.data.wards.map(ward => ward.wardName);

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
        }
    }
</script>

<style scoped>

</style>